import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import FiltersIcon from '@/components/icons/filters'
import PlusAddIcon from '@/components/icons/plus-icon'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { CloseOutlined, HomeOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Drawer, Flex, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { createCredit, getListBanks } from '../api'
import TableBanks from '../components/Table'

function Banks(): JSX.Element {
  const [open, setOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerOpenForm, setDrawerOpenForm] = useState(false)
  const { control, getValues, watch, reset } = useForm()
  const showMessage = useMessage()
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const { data: bankList } = useQuery({
    queryKey: ['getListBanks'],
    queryFn: getListBanks,
  })

  const { mutate: mutateCreateCredit, isLoading: isLoadingCreatingCredit } =
    useMutation({
      mutationFn: createCredit,
      onSuccess: (res) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries(['getListCredits'])
        setDrawerOpenForm(false)
      },
      onError: async () => {
        await showMessage(
          'error',
          'Ошибка при создание кредита. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <HomeOutlined />
                <span className="font-normal text-sm text-[#A9A9A9]">
                  Главная
                </span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span className="font-normal text-sm text-[#1E1E1E] dark:text-[#FFFFFF]">
                  Банки
                </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px] dark:bg-[#1A1D1F] dark:border-[#272B30]"
      >
        <Flex className="items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 dark:!text-[#FFF]">
            Банки
          </Typography.Title>

          <Flex gap={16}>
            {/* <Button className="border-[#E5E7EB] rounded-[8px] h-[48px] dark:!bg-[#272B30] dark:border-[#272B30]">
              <SearchOutlined />
            </Button> */}

            <Button
              className="border-[#635BFF] rounded-[8px] h-[48px] flex items-center gap-[10px] text-[#635BFF] dark:!bg-[#272B30]"
              onClick={() => {
                setDrawerOpenForm(true)
              }}
            >
              <PlusAddIcon
                style={{
                  color: '#635BFF',
                }}
              />
              Добавить банк
            </Button>

            <Button
              className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:!bg-[#272B30] dark:border-[#272B30]"
              onClick={() => {
                setDrawerOpen(true)
              }}
            >
              <FiltersIcon
                style={{
                  color: '#1E1E1E',
                  height: '14px',
                  width: '14px',
                }}
              />
              Фильтр
              <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                1
              </Typography.Text>
            </Button>

            {/* <Popover
              content={
                <Flex vertical className="min-w-[170px]">
                  <Button
                    type="link"
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Обработано
                  </Button>
                  <Button
                    type="link"
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Экспорт данных
                  </Button>

                  <Button
                    type="link"
                    className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                  >
                    Архивировать
                  </Button>
                </Flex>
              }
              placement="bottomRight"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button className="border-[#E5E7EB] rounded-[8px] h-[48px] dark:!bg-[#272B30] dark:border-[#272B30]">
                <MoreOutlined />
              </Button>
            </Popover> */}
          </Flex>
        </Flex>

        <Flex className="w-full">
          <TableBanks bankList={bankList} watch={watch} />
        </Flex>
      </Flex>

      <Drawer
        onClose={() => {
          setDrawerOpen(false)
        }}
        open={drawerOpen}
        headerStyle={{
          display: 'none',
        }}
      >
        <Flex vertical className="w-full h-full justify-between">
          <Flex vertical className="w-full">
            <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
              <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
                Фильтр
              </Typography.Title>

              <Button
                type="link"
                onClick={() => {
                  setDrawerOpen(false)
                }}
                className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
              >
                <CloseOutlined />
              </Button>
            </Flex>

            <Flex vertical gap={24} className="w-full p-[24px]">
              <FRow label="Выберите банк">
                <HFSelect
                  control={control}
                  name="filter.bank"
                  options={bankList?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </FRow>

              <FRow label="Ставка кредита">
                <HFInput control={control} name="filter.percent" suffix="%" />
              </FRow>

              <FRow label="Сумма кредита">
                <HFInput
                  control={control}
                  name="filter.to_amount"
                  suffix="UZS"
                  prefix="До"
                />
              </FRow>

              <FRow label="Ежемесяный платёж">
                <HFInput
                  control={control}
                  name="filter.per_month"
                  suffix="UZS/мес"
                />
              </FRow>
            </Flex>
          </Flex>

          <Flex gap={22} className="w-full p-[24px]">
            <Button
              onClick={() => {
                setDrawerOpen(false)
                reset({
                  filter: {
                    bank: undefined,
                    percent: undefined,
                    to_amount: undefined,
                    per_month: undefined,
                  },
                })
              }}
              className="w-full bg-[#FFFFFF] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
            >
              Очистить
            </Button>
            <Button
              onClick={() => {
                setDrawerOpen(false)
              }}
              className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
            >
              Сохранить
            </Button>
          </Flex>
        </Flex>
      </Drawer>

      <Drawer
        onClose={() => {
          setDrawerOpenForm(false)
        }}
        open={drawerOpenForm}
        headerStyle={{
          display: 'none',
        }}
      >
        <Flex vertical className="w-full h-full justify-between">
          <Flex vertical className="w-full">
            <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
              <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
                Добавить банк
              </Typography.Title>

              <Button
                type="link"
                onClick={() => {
                  setDrawerOpenForm(false)
                }}
                className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
              >
                <CloseOutlined />
              </Button>
            </Flex>

            <Flex vertical gap={24} className="w-full p-[24px]">
              <FRow label="Выберите банк">
                <HFSelect
                  control={control}
                  name="add.bank"
                  options={bankList?.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </FRow>

              <FRow label="Название кредита">
                <HFInput control={control} name="add.name" />
              </FRow>

              <FRow label="Срок">
                <HFInput control={control} name="add.deadline" />
              </FRow>

              <FRow label="Ставка кредита">
                <HFInput control={control} name="add.percent" suffix="%" />
              </FRow>

              <FRow label="Сумма кредита">
                <HFInput
                  control={control}
                  name="add.to_amount"
                  suffix="UZS"
                  prefix="До"
                />
              </FRow>

              <FRow label="Ежемесяный платёж">
                <HFInput
                  control={control}
                  name="add.per_month"
                  suffix="UZS/мес"
                />
              </FRow>
            </Flex>
          </Flex>

          <Flex gap={22} className="w-full p-[24px]">
            <Button
              onClick={() => {
                setDrawerOpenForm(false)
              }}
              className="w-full bg-[#FFFFFF] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
            >
              Отменить
            </Button>
            <Button
              onClick={() => {
                mutateCreateCredit({
                  ...getValues('add'),
                  bank: 1,
                })
              }}
              loading={isLoadingCreatingCredit}
              className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
            >
              Добавить
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </Flex>
  )
}

export default Banks

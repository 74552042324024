/* eslint-disable @typescript-eslint/no-floating-promises */
import BlurImage from '@/components/BlurImage'
import DeleteModal from '@/components/delete-modal'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Image, Popover, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { deleteContact, updateContact } from '../api'
import DefaultAvatar from '../assets/avatar.svg'
import InstagramIcon from '../assets/instagram.svg'
import TelegramIcon from '../assets/telegram.svg'
import ContactFormDrawer from './ContactFormDrawer'

function TableRow({ item }: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [deleteApproveModal, setDeleteApproveModal] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const showMessage = useMessage()
  const { control, getValues, setValue, reset, handleSubmit } = useForm()
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }
  const [selectedRow, setSelectedRow] = useState<any>(null)

  const { mutate: mutateUpdateContact, isLoading: isLoadingUpdating } =
    useMutation({
      mutationFn: updateContact,
      onSuccess: () => {
        setDrawerOpen(false)
        showMessage('success', 'Контакт успешно обновлен.')
        queryClient.invalidateQueries(['getListContacts'])
      },
      onError: () => {
        showMessage(
          'error',
          'Ошибка при обновлении контакта. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  const { mutate: mutateDeleteContact, isLoading: isLoadingDeleting } =
    useMutation({
      mutationFn: deleteContact,
      onSuccess: () => {
        setDrawerOpen(false)
        showMessage('success', 'Контакт успешно удален.')
        queryClient.invalidateQueries(['getListContacts'])
      },
      onError: () => {
        showMessage('error', 'Ошибка при удалении контакта.')
      },
    })

  return (
    <Flex
      key={item.id}
      className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]"
    >
      {/* <Flex>
        <Checkbox />
      </Flex> */}

      <Flex className="items-center gap-[12px]">
        <BlurImage
          src={item?.image ?? DefaultAvatar}
          preview={false}
          width={46}
          height={46}
          className="rounded-full overflow-hidden"
        />
        <Typography.Text className="text-[#1E1E1E] font-normal text-normal dark:text-[#FFF]">
          {item?.first_name} {item?.last_name}
        </Typography.Text>
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-normal dark:text-[#FFF]">
        {item?.phone}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-normal dark:text-[#FFF]">
        {item?.email}
      </Typography.Text>

      <Flex vertical>
        <Flex gap={8}>
          <Image src={TelegramIcon} preview={false} width={20} height={20} />
          <Typography.Text>{item?.telegram}</Typography.Text>
        </Flex>

        <Flex gap={8}>
          <Image src={InstagramIcon} preview={false} width={20} height={20} />
          <Typography.Text>{item?.instagram}</Typography.Text>
        </Flex>
      </Flex>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              onClick={() => {
                setDrawerOpen(true)
                setOpen(false)
                setSelectedRow(item)
              }}
              loading={isLoadingUpdating}
              type="link"
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Изменить
            </Button>
            <Button
              type="link"
              onClick={() => {
                setDeleteApproveModal(true)
                handleOpenChange(false)
              }}
              loading={isLoadingDeleting}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Flex className="w-full justify-end">
          <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:border-[#272B30] dark:!bg-[#272B30]">
            <MoreOutlined />
          </Button>
        </Flex>
      </Popover>

      <ContactFormDrawer
        getValues={getValues}
        control={control}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        mutateCreateContact={mutateUpdateContact}
        isLoadingCreating={isLoadingUpdating}
        type={selectedRow?.id}
        setValue={setValue}
        reset={reset}
        handleSubmit={handleSubmit}
      />

      <DeleteModal
        deleteApproveModal={deleteApproveModal}
        setDeleteApproveModal={setDeleteApproveModal}
        onOk={() => {
          mutateDeleteContact(item.id)
          setOpen(false)
        }}
      />
    </Flex>
  )
}

export default TableRow

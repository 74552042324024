/* eslint-disable @typescript-eslint/no-floating-promises */
import PlusAddIcon from '@/components/icons/plus-icon'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { HomeOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Breadcrumb, Button, Flex, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import getListContacts, { createContact } from '../api'
import ContactFormDrawer from '../components/ContactFormDrawer'
import TableContacts from '../components/Table'

function Contacts(): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const showMessage = useMessage()
  const { control, getValues, reset, setValue, handleSubmit } = useForm()

  const { data, isLoading } = useQuery({
    queryKey: ['getListContacts'],
    queryFn: getListContacts,
  })

  const { mutate: mutateCreateContact, isLoading: isLoadingCreating } =
    useMutation({
      mutationFn: createContact,
      onSuccess: () => {
        setDrawerOpen(false)
        showMessage('success', 'Контакт успешно создан.')
        queryClient.invalidateQueries(['getListContacts'])
        reset()
      },
      onError: () => {
        showMessage(
          'error',
          'Ошибка при создание контакта. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <HomeOutlined />
                <span className="font-normal text-sm text-[#A9A9A9]">
                  Главная
                </span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span className="font-normal text-sm text-[#1E1E1E] dark:text-[#FFFFFF]">
                  Контакты
                </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px] dark:bg-[#1A1D1F] dark:border-[#272B30]"
      >
        <Flex className="items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 dark:!text-[#FFF]">
            Контакты
          </Typography.Title>

          <Flex gap={16}>
            <Button
              className="border-[#635BFF] dark:text-[#FFF] rounded-[8px] h-[48px] flex items-center gap-[10px] text-[#635BFF] dark:!bg-[#272B30]"
              onClick={() => {
                setDrawerOpen(true)
              }}
            >
              <PlusAddIcon />
              Добавить контакты
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full">
          <TableContacts data={data} isLoading={isLoading} setDrawerOpen={setDrawerOpen}/>
        </Flex>
      </Flex>

      <ContactFormDrawer
        getValues={getValues}
        control={control}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        mutateCreateContact={mutateCreateContact}
        isLoadingCreating={isLoadingCreating}
        type="create"
        setValue={setValue}
        reset={reset}
        handleSubmit={handleSubmit}
      />
    </Flex>
  )
}

export default Contacts

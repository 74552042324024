/* eslint-disable @typescript-eslint/no-floating-promises */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { CloseOutlined, MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Image,
  Popover,
  Typography,
} from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { deleteCredit, updateCredit } from '../api'

function TableRow({ item, bankList }: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [drawerOpenForm, setDrawerOpenForm] = useState(false)
  const showMessage = useMessage()
  const { control, getValues } = useForm({
    defaultValues: {
      add: {
        bank: 1,
        name: item?.name,
        deadline: item?.deadline,
        percent: item?.percent,
        to_amount: item?.to_amount,
        per_month: item?.per_month,
        id: item?.id,
      },
    },
  })
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const { mutate: mutateDeleteCredit, isLoading: isLoadingDeleting } =
    useMutation({
      mutationFn: deleteCredit,
      onSuccess: () => {
        showMessage('success', 'Кредит успешно удален.')
        queryClient.invalidateQueries(['getListCredits'])
      },
      onError: () => {
        showMessage('error', 'Ошибка при удалении контакта.')
      },
    })

  const { mutate: mutateUpdateCredit, isLoading: isLoadingCreatingCredit } =
    useMutation({
      mutationFn: updateCredit,
      onSuccess: () => {
        showMessage('success', 'Кредит успешно обновлен.')
        queryClient.invalidateQueries(['getListCredits'])
        setDrawerOpenForm(false)
      },
      onError: () => {
        showMessage('error', 'Ошибка при обновлении контакта.')
      },
    })

  return (
    <Flex className="w-full items-center grid grid-cols-[0.5fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
      <Flex>
        <Checkbox />
      </Flex>

      <Flex gap={12} className="items-center">
        <Flex className="w-[48px] h-[48px] rounded-full border-[#E5E7EB] border border-solid overflow-hidden items-center justify-center">
          <Image preview={false} src={item?.bank?.logo} height={40} />
        </Flex>

        <Flex vertical gap={8}>
          <Typography.Text className="text-base font-medium text-[#1E1E1E] dark:text-[#FFF]">
            {item?.name}
          </Typography.Text>
          <Typography.Text className="text-sm font-normal text-[#A9A9A9] dark:text-[#FFF]">
            {item?.bank?.name}
          </Typography.Text>
        </Flex>
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#FFF]">
        {item?.percent}%
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#FFF]">
        До {item?.to_amount} UZS
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#FFF]">
        {item?.deadline} лет
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#FFF]">
        {item?.per_month} UZS/мес.
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setDrawerOpenForm(true)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Редактировать
            </Button>
            <Button
              type="link"
              onClick={() => {
                mutateDeleteCredit(item.id)
              }}
              loading={isLoadingDeleting}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:!bg-[#272B30] dark:border-[#272B30]">
          <MoreOutlined />
        </Button>
      </Popover>

      <Drawer
        onClose={() => {
          setDrawerOpenForm(false)
        }}
        open={drawerOpenForm}
        headerStyle={{
          display: 'none',
        }}
      >
        <Flex vertical className="w-full justify-between h-full">
          <Flex vertical className='w-full'>
            <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
              <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0">
                Добавить банк
              </Typography.Title>

              <Button
                type="link"
                onClick={() => {
                  setDrawerOpenForm(false)
                }}
                className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
              >
                <CloseOutlined />
              </Button>
            </Flex>

            <Flex vertical gap={24} className="w-full p-[24px]">
              <FRow label="Выберите банк">
                <HFSelect
                  control={control}
                  name="add.bank"
                  options={bankList?.map((bank: any) => ({
                    label: bank.name,
                    value: bank.id,
                  }))}
                />
              </FRow>

              <FRow label="Название кредита">
                <HFInput control={control} name="add.name" />
              </FRow>

              <FRow label="Срок">
                <HFInput control={control} name="add.deadline" />
              </FRow>

              <FRow label="Ставка кредита">
                <HFInput control={control} name="add.percent" suffix="%" />
              </FRow>

              <FRow label="Сумма кредита">
                <HFInput
                  control={control}
                  name="add.to_amount"
                  suffix="UZS"
                  prefix="До"
                />
              </FRow>

              <FRow label="Ежемесяный платёж">
                <HFInput
                  control={control}
                  name="add.per_month"
                  suffix="UZS/мес"
                />
              </FRow>
            </Flex>
          </Flex>

          <Flex gap={22} className="w-full p-[24px]">
            <Button
              onClick={() => {
                setDrawerOpenForm(false)
              }}
              className="w-full bg-[#FFFFFF] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
            >
              Отменить
            </Button>
            <Button
              onClick={() => {
                mutateUpdateCredit({
                  ...getValues('add'),
                  bank: 1,
                })
              }}
              loading={isLoadingCreatingCredit}
              className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
            >
              Добавить
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </Flex>
  )
}

export default TableRow

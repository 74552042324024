import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2041 19.6345L15.2041 19.6344L12.5442 16.6103C11.2274 15.1131 11.0714 14.4552 11.1581 14.3134L12.1564 13.5094L16.2027 10.3444C16.529 10.0892 16.5866 9.61786 16.3314 9.29159C16.0762 8.96533 15.6048 8.90772 15.2785 9.16292L11.228 12.3311L10.0099 13.3253C9.72553 13.6305 9.54418 14.1524 9.74197 14.8946C9.92654 15.5872 10.4443 16.5026 11.5466 17.7593L11.1395 18.5622C10.8765 18.9277 10.6324 19.2671 10.4077 19.4997C10.1844 19.7308 9.77877 20.0705 9.21374 19.9581C8.65614 19.8472 8.40592 19.3862 8.28203 19.0904C8.15621 18.7899 8.04964 18.3824 7.93402 17.9404L7.43252 16.0239C7.15396 14.9594 7.05777 14.6534 6.87651 14.4282C6.85363 14.3998 6.8298 14.3723 6.80509 14.3459C6.61372 14.141 6.34345 14.0149 5.3535 13.6181L5.29927 13.5963C4.32368 13.2053 3.52006 12.8832 2.95798 12.5667C2.41344 12.2601 1.8254 11.8144 1.75775 11.0623C1.74724 10.9454 1.74742 10.8278 1.75829 10.7109C1.82825 9.95901 2.41775 9.51516 2.96325 9.21037C3.52632 8.89576 4.33094 8.57633 5.30773 8.18854L17.2918 3.43052C18.5177 2.94379 19.52 2.54579 20.3076 2.36434C21.1106 2.17935 21.9401 2.15976 22.5901 2.76101C23.2273 3.35039 23.3036 4.18068 23.2242 5.01747C23.1454 5.8477 22.8864 6.92742 22.5669 8.2593L20.1472 18.3463L20.1472 18.3464C19.9436 19.1954 19.7717 19.9121 19.5655 20.4321C19.3573 20.9575 19.0233 21.5216 18.3484 21.7001C17.6654 21.8807 17.1009 21.544 16.6719 21.1809C16.2505 20.8243 15.7699 20.2779 15.2041 19.6345Z"
      fill="#635BFF"
    />
  </svg>
)

export default function TelegramIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

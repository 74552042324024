import request from "@/utils/axios";
import type { AxiosResponse } from "axios";
import type { AuthResponse } from "../types";

export async function login(data: {
  username: string;
  password: string;
}): Promise<AuthResponse> {
  const res: AuthResponse = await request({
    url: "/accounts/me/",
    method: "post",
    data,
  });

  return res;
}

export async function updatePassword(data: {
  password1: string;
  password2: string;
}): Promise<AxiosResponse<AuthResponse>> {
  const res = await request({
    url: "/accounts/update-password",
    method: "post",
    data,
  });

  return res;
}

export async function refreshToken(data: {
  refresh: string;
}): Promise<AxiosResponse<AuthResponse>> {
  const res = await request({
    url: "/account/me/refresh/",
    method: "post",
    data,
  });

  return res;
}

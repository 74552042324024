import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11440_13540)">
      <path
        d="M12.686 20.8466C12.5442 21.7759 13.178 22.421 13.9541 22.7425C16.9292 23.975 21.0695 23.975 24.0446 22.7425C24.8207 22.421 25.4545 21.7759 25.3127 20.8466C25.2256 20.2754 24.7948 19.7998 24.4756 19.3354C24.0575 18.7197 24.016 18.048 24.0159 17.3335C24.0159 14.5721 21.7699 12.3335 18.9993 12.3335C16.2288 12.3335 13.9828 14.5721 13.9828 17.3335C13.9827 18.048 13.9412 18.7197 13.5231 19.3354C13.2039 19.7998 12.7731 20.2754 12.686 20.8466Z"
        stroke="#A9A9A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.332 23.667C16.6377 24.8171 17.7157 25.667 18.9987 25.667C20.2817 25.667 21.3597 24.8171 21.6654 23.667"
        stroke="#A9A9A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11440_13540">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(11 11)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default function NotificationCircledIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

/* eslint-disable no-return-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
import BlurImage from '@/components/BlurImage'
import DeleteModal from '@/components/delete-modal'
import { updateRCPage } from '@/features/residentialComplex/api'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Modal, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import GalleryImage from '../../../assets/gallery.svg'

export default function MainImageModal({
  setMainImageModal,
  mainImageModal,
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const [deleteApproveModal, setDeleteApproveModal] = useState(false)
  const { watch, setValue, handleSubmit, getValues } = useForm({
    defaultValues: {
      image: data?.image,
    },
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const inputClick = (): void => {
    ;(document.querySelector('input[id="main"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[id="main"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]

        if (file != null) {
          setValue(`image`, file)
        }
      })
  }

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: async () =>
      await updateRCPage(
        {
          image: getValues('image'),
        },
        data?.id,
      ),
    onSuccess: () => {
      setMainImageModal(false)
      showMessage('success', 'Главное фото успешно обновлено')
      refetch()
    },
  })

  const updateTitle = (): void => {
    mutateUpdate()
  }

  return (
    <Modal
      open={mainImageModal}
      onOk={() => setMainImageModal(false)}
      onCancel={() => setMainImageModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc"
      width="70%"
    >
      <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Главное фото
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setMainImageModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex className="p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid">
        <input hidden id="main" type="file" accept="image/*" />
        <Flex className="border-[#E5E7EB] border-1 border-dashed rounded-[12px] overflow-hidden dark:border-[#2F3336] w-full">
          {watch(`image`) ? (
            <Flex className="relative items-center justify-center min-h-[200px] h-[200px] w-full">
              <BlurImage
                width="100%"
                height="100%"
                className="object-cover"
                src={
                  isValidURL(watch(`image`))
                    ? watch(`image`)
                    : URL.createObjectURL(watch(`image`))
                }
                preview
              />

              <Flex
                className="absolute left-0 top-0 items-center w-full h-full justify-center"
                gap={12}
              >
                <Button
                  onClick={inputClick}
                  className="!bg-[#000000a8] !text-[#FCFCFD] !font-medium !text-base p-[12px_16px] h-auto rounded-full"
                >
                  Изменить картинку
                </Button>
                <Button
                  onClick={() => {
                    setDeleteApproveModal(true)
                  }}
                  className="!bg-[#000000a8] !text-[#FCFCFD] !font-medium !text-base p-[12px_16px] h-auto rounded-full"
                >
                  Удалить
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex
              vertical
              onClick={inputClick}
              className="items-center justify-center min-h-[200px] w-full"
            >
              <BlurImage width={70} src={GalleryImage} preview={false} />
              <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px] dark:!text-[#FFF]">
                Выберите или перетащите минимум 1 фото
              </Typography.Text>
              <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                Максимальный размер: 5 МБ.
              </Typography.Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex className="p-[16px] items-center justify-end" gap={16}>
        <Button
          onClick={() => {
            setMainImageModal(false)
          }}
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Отменить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(updateTitle)()
          }}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>

      <DeleteModal
        deleteApproveModal={deleteApproveModal}
        setDeleteApproveModal={setDeleteApproveModal}
        onOk={() => {
          setValue(`image`, null)
          setDeleteApproveModal(false)
        }}
      />
    </Modal>
  )
}

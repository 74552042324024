/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-floating-promises */
import BlurImage from '@/components/BlurImage'
import DislikeIcon from '@/components/icons/dislike-icon'
import LikeIcon from '@/components/icons/like-icon'
import StarFilledIcon from '@/components/icons/star-icon'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Popover, Typography } from 'antd'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { changeStatusReview } from '../api'
import defaultAvatar from '../assets/Group.jpg'

function RatingTableRow({ item, refetch }: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [isShowedCommentMore, setIsShowedCommentMore] = useState(false)
  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }
  const showMessage = useMessage()

  const { mutate: mutateUpdateReview, isLoading: loadingUpdate } = useMutation({
    mutationFn: () => {
      return changeStatusReview(
        item?.id,
        item?.status === 'active' ? 'inactive' : 'active',
      )
    },
    onSuccess: async () => {
      await refetch()
    },
    onError: () => {
      showMessage('error', 'Ошибка при обновлении отзыва.')
    },
  })

  const { mutate: mutateDeleteReview, isLoading: loadingDelete } = useMutation({
    mutationFn: () => {
      return changeStatusReview(item?.id, 'deleted')
    },
    onSuccess: async () => {
      await refetch()
    },
    onError: () => {
      showMessage('error', 'Ошибка при удаления отзыва.')
    },
  })

  const statusGenerator = useMemo(() => {
    if (item?.status === 'active') {
      return 'Активный'
    }
    if (item?.status === 'deleted') {
      return 'Удален'
    }
    return 'Неактивный'
  }, [item])

  return (
    <Flex
      key={item?.id}
      className="w-full items-center grid grid-cols-[1.5fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]"
    >
      <Flex gap={12} className="items-center">
        <Flex className="min-w-[46px]">
          {item?.user?.avatar ? (
            <BlurImage
              src={item?.user?.avatar}
              preview={false}
              width={46}
              height={46}
              className="rounded-full overflow-hidden"
            />
          ) : (
            <BlurImage
              src={defaultAvatar}
              preview={false}
              width={46}
              height={46}
              className="rounded-full overflow-hidden"
            />
          )}
        </Flex>

        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
          {item?.user?.first_name + ' ' + item?.user?.last_name}
        </Typography.Text>
      </Flex>

      <Flex vertical gap={16}>
        <Flex gap={2}>
          <StarFilledIcon
            width="20"
            height="20"
            style={{
              color: item?.rating > 0 ? '#FA8F54' : '#A9A9A9',
            }}
          />
          <StarFilledIcon
            width="20"
            height="20"
            style={{
              color: item?.rating > 1 ? '#FA8F54' : '#A9A9A9',
            }}
          />
          <StarFilledIcon
            width="20"
            height="20"
            style={{
              color: item?.rating > 2 ? '#FA8F54' : '#A9A9A9',
            }}
          />
          <StarFilledIcon
            width="20"
            height="20"
            style={{
              color: item?.rating > 3 ? '#FA8F54' : '#A9A9A9',
            }}
          />
          <StarFilledIcon
            width="20"
            height="20"
            style={{
              color: item?.rating > 4 ? '#FA8F54' : '#A9A9A9',
            }}
          />
        </Flex>

        <Flex gap={16}>
          <Flex className="items-center" gap={8}>
            <LikeIcon />
            <Typography.Text className="text-[#A9A9A9] font-medium text-sm">
              {item?.likes}
            </Typography.Text>
          </Flex>

          <Flex className="items-center" gap={8}>
            <DislikeIcon />
            <Typography.Text className="text-[#A9A9A9] font-medium text-sm">
              {item?.dislikes}
            </Typography.Text>
          </Flex>
        </Flex>
      </Flex>

      {/* <Typography.Text className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#F1F2F4] h-max w-max flex items-center gap-[2px]">
        {item?.theme}
      </Typography.Text> */}

      <Flex vertical gap={12} className='rounded-none'>
        <Typography.Text
          style={{
            display: '-webkit-box',
            width: '100%',
            height: isShowedCommentMore ? 'auto' : '32px',
            overflow: 'hidden',
            padding: '0',
          }}
          className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] h-max w-max flex items-center gap-[2px]"
        >
          {item?.comment}
        </Typography.Text>

        {item?.comment.length > 40 && (
          <Button
            type="link"
            className="text-[#1E1E1E] w-max font-normal text-xs rounded-[4px] h-auto flex items-center justify-start border border-solid border-[#E5E7EB] dark:border-[#272B30] p-[4px]"
            onClick={() => {
              setIsShowedCommentMore(!isShowedCommentMore)
            }}
          >
            {isShowedCommentMore ? 'Скрыть' : 'Показать полностью'}
          </Button>
        )}
      </Flex>

      {/* <Flex vertical gap={8}>
        <Typography.Title className="!text-[#1E1E1E] !font-medium !text-xl !m-0 dark:!text-[#FFF]">
          Плюсы
        </Typography.Title>

        <Typography.Text className="!text-[#1E1E1E] !font-normal !text-sm dark:!text-[#FFF]">
          {item?.plus}
        </Typography.Text>
      </Flex>

      <Flex vertical gap={8}>
        <Typography.Title className="!text-[#1E1E1E] !font-medium !text-xl !m-0 dark:!text-[#FFF]">
          Минусы
        </Typography.Title>

        <Typography.Text className="!text-[#1E1E1E] !font-normal !text-sm dark:!text-[#FFF]">
          {item?.minus}
        </Typography.Text>
      </Flex> */}

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:!text-[#FFF]">
        {item?.created_at && format(new Date(item?.created_at), 'dd.MM.yyyy')}
      </Typography.Text>

      <Typography.Text
        style={{
          color: item?.status === 'active' ? '#58C27D' : '#CE5A67',
          backgroundColor: item?.status === 'active' ? '#DEF3E5' : '#F3DCDF',
        }}
        className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px] dark:bg-[#233332] dark:text-[#58C27D]"
      >
        • {statusGenerator}
      </Typography.Text>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              loading={loadingUpdate}
              onClick={() => {
                mutateUpdateReview()
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              {item?.status === 'active' ? 'Скрыть' : 'Активировать'}
            </Button>
            <Button
              type="link"
              loading={loadingDelete}
              onClick={() => {
                mutateDeleteReview()
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:!bg-[#272B30] dark:border-[#272B30]">
          <MoreOutlined />
        </Button>
      </Popover>
    </Flex>
  )
}

export default RatingTableRow

/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import ArrowRight2 from '@/components/icons/arrow-right2'
import FiltersIcon from '@/components/icons/filters'
import JKIcon from '@/components/icons/jk-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import filterObject from '@/helpers/filterObject'
import useMessage from '@/hooks/useShowMessage'
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { TabsProps } from 'antd'
import { Breadcrumb, Button, Flex, Popover, Tabs, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { getListApartments, multipleDeleteFlats } from '../api'
import FlatsAddDrawer from '../components/FlatsAddDrawer'
import FlatsFilterDrawer from '../components/FlatsFilterDrawer'
import FlatsSettingsPriceModal from '../components/FlatsSettingsPriceModal'
import FlatsTablePage from '../components/FlatsTablePage'

function FlatsPage(): JSX.Element {
  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const showMessage = useMessage()
  const [searchParams, setSearchParams] = useSearchParams('addLayout')
  const [open, setOpen] = useState(false)
  const [settingsPriceModal, setSettingsPriceModal] = useState(false)

  useEffect(() => {
    if (searchParams.get('addLayout') === 'true') {
      setAddDrawerOpen(true)
    }
  }, [])

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const { control, watch, unregister, reset, setValue } = useForm({
    defaultValues: {
      filters: {
        type: '1',
        apartment_type: '',
        total_area: '',
        price_from: '',
        price_to: '',
        block: '',
      },
    },
  })

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['apartments', watch('filters'), page, watch('filters.type')],
    queryFn: (): any =>
      getListApartments({
        ...filterObject(watch('filters')),
        page,
      }),
  })

  const filterCount = useMemo(() => {
    let count = 0
    if (watch('filters.apartment_type')) {
      count++
    }
    if (watch('filters.total_area')) {
      count++
    }
    if (watch('filters.price_from')) {
      count++
    }
    if (watch('filters.price_to')) {
      count++
    }
    if (watch('filters.block')) {
      count++
    }
    return count
  }, [
    watch('filters.apartment_type'),
    watch('filters.total_area'),
    watch('filters.price_from'),
    watch('filters.price_to'),
    watch('filters.block'),
  ])

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Жилое',
      children: (
        <FlatsTablePage
          setFilterDrawerOpen={setFilterDrawerOpen}
          setAddDrawerOpen={setAddDrawerOpen}
          data={data}
          page={page}
          setPage={setPage}
          filterCount={filterCount}
          setValue={setValue}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          watch={watch}
          isLoading={isLoading}
          refetch={refetch}
        />
      ),
    },
    {
      key: '2',
      label: 'Коммерческое',
      children: (
        <FlatsTablePage
          setFilterDrawerOpen={setFilterDrawerOpen}
          setAddDrawerOpen={setAddDrawerOpen}
          data={data}
          page={page}
          setPage={setPage}
          filterCount={filterCount}
          setValue={setValue}
          selectedRowIds={selectedRowIds}
          setSelectedRowIds={setSelectedRowIds}
          watch={watch}
          isLoading={isLoading}
          refetch={refetch}
        />
      ),
    },
  ]

  const { mutate: mutateDeleteFlats, isLoading: isLoadingDeletingFlats } =
    useMutation({
      mutationFn: multipleDeleteFlats,
      onSuccess: () => {
        showMessage('success', 'Планировки успешно удалены.')
        refetch()
        setSelectedRowIds([])
      },
      onError: () => {
        showMessage('error', 'Ошибка при удалении планировки.')
      },
    })

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315] mb-[70px]"
    >
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <JKIcon />
                <span className="font-normal text-sm text-[#A9A9A9]">
                  Жилой комплекс
                </span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span className="font-normal text-sm text-[#1E1E1E] dark:text-[#FFFFFF]">
                  Планировки
                </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex vertical>
        <Flex
          vertical
          className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px] h-max dark:bg-[#1A1D1F] dark:border-[#3A3D41]"
        >
          <Flex className="items-center justify-between">
            <Typography.Title className="m-0 !font-medium !text-2xl flex items-center gap-[12px] dark:!text-[#FFFFFF]">
              Планировки ({data?.count ?? 0})
            </Typography.Title>

            <Flex gap={16}>
              <Button
                className="border-[#635BFF] dark:text-[#FFF] rounded-[8px] h-[48px] flex items-center gap-[10px] text-[#635BFF] dark:!bg-[#272B30]"
                onClick={() => {
                  setAddDrawerOpen(true)
                }}
              >
                <PlusAddIcon />
                Добавить помещение
              </Button>

              <Button
                className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:hover:!bg-[#1A1D1F] dark:text-[#FFFFFF] dark:border-[#272B30]"
                onClick={() => {
                  setFilterDrawerOpen(true)
                }}
              >
                <FiltersIcon
                  style={{
                    color: isDarkMode ? '#FFF' : '#1E1E1E',
                    height: '14px',
                    width: '14px',
                  }}
                />
                Фильтр
                {filterCount > 0 && (
                  <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                    {filterCount}
                  </Typography.Text>
                )}
              </Button>
              <Popover
                title={
                  selectedRowIds.length === 0
                    ? 'Выберите хотя бы одного планировку'
                    : ''
                }
              >
                <Button
                  disabled={selectedRowIds.length === 0}
                  loading={isLoadingDeletingFlats}
                  onClick={() => {
                    mutateDeleteFlats(selectedRowIds)
                  }}
                  className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:border-[#272B30]"
                >
                  <DeleteOutlined style={{ color: '#A9A9A9' }} />
                </Button>
              </Popover>

              <Popover
                content={
                  <Flex vertical className="min-w-[170px]">
                    <Button
                      type="link"
                      onClick={() => {
                        setSettingsPriceModal(true)
                        setOpen(false)
                      }}
                      className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-between"
                    >
                      Настройка цен
                      <ArrowRight2
                        style={{
                          color: '#1E1E1E',
                        }}
                      />
                    </Button>
                  </Flex>
                }
                placement="bottomRight"
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
              >
                <Button
                  disabled={data?.results?.length === 0}
                  className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:border-[#272B30]"
                >
                  <MoreOutlined />
                </Button>
              </Popover>
            </Flex>
          </Flex>

          <FlatsSettingsPriceModal
            settingsPriceModal={settingsPriceModal}
            setSettingsPriceModal={setSettingsPriceModal}
            data={data}
            refetch={refetch}
          />

          <Tabs
            items={items}
            defaultActiveKey="1"
            onChange={(e) => {
              setValue('filters.type', e)
            }}
          />
        </Flex>
      </Flex>

      <FlatsFilterDrawer
        setFilterDrawerOpen={setFilterDrawerOpen}
        filterDrawerOpen={filterDrawerOpen}
        control={control}
        reset={reset}
        unregister={unregister}
      />

      {addDrawerOpen && (
        <FlatsAddDrawer
          setAddDrawerOpen={setAddDrawerOpen}
          addDrawerOpen={addDrawerOpen}
          refetch={refetch}
          initialType={watch('filters.type')}
        />
      )}
    </Flex>
  )
}

export default FlatsPage

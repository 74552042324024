import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12388_105010)">
      <path
        d="M23.9561 4.44298C23.5791 4.61021 23.1915 4.75134 22.7954 4.86593C23.2643 4.3356 23.6218 3.7116 23.8401 3.02876C23.889 2.87571 23.8383 2.70817 23.7125 2.60807C23.5869 2.50789 23.4123 2.49589 23.2739 2.57787C22.4328 3.07676 21.5253 3.43529 20.5737 3.64494C19.6152 2.70833 18.3133 2.17529 16.9674 2.17529C14.1265 2.17529 11.8152 4.48649 11.8152 7.32732C11.8152 7.55106 11.8294 7.77356 11.8574 7.99297C8.33215 7.68345 5.05475 5.95073 2.80417 3.19003C2.72396 3.09163 2.6004 3.0386 2.4739 3.04874C2.34732 3.05865 2.23359 3.13003 2.16964 3.23973C1.71318 4.02298 1.47186 4.9188 1.47186 5.83026C1.47186 7.07168 1.91509 8.24954 2.69803 9.1699C2.45996 9.08745 2.22895 8.9844 2.00846 8.862C1.89008 8.79612 1.74562 8.79713 1.62802 8.86456C1.51034 8.93199 1.43656 9.05602 1.43346 9.19158C1.43292 9.21442 1.43292 9.23725 1.43292 9.2604C1.43292 11.1134 2.43024 12.7817 3.95501 13.691C3.82402 13.678 3.6931 13.659 3.56304 13.6341C3.42895 13.6085 3.29106 13.6555 3.20064 13.7578C3.11006 13.86 3.08009 14.0024 3.12182 14.1325C3.68621 15.8945 5.13929 17.1906 6.89594 17.5858C5.43898 18.4983 3.77284 18.9763 2.02378 18.9763C1.65883 18.9763 1.29178 18.9549 0.932558 18.9124C0.754107 18.8911 0.583474 18.9965 0.5227 19.1662C0.461926 19.336 0.526339 19.5253 0.678158 19.6226C2.9251 21.0633 5.52322 21.8248 8.19147 21.8248C13.4369 21.8248 16.7184 19.3512 18.5474 17.2762C20.8282 14.6887 22.1362 11.2639 22.1362 7.88002C22.1362 7.73865 22.1341 7.59589 22.1297 7.45359C23.0296 6.77563 23.8043 5.95514 24.4348 5.01209C24.5305 4.86887 24.5201 4.67958 24.4092 4.54773C24.2984 4.41581 24.1138 4.37315 23.9561 4.44298Z"
        fill="#179CF0"
      />
    </g>
    <defs>
      <clipPath id="clip0_12388_105010">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default function TwitterIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

import request from '@/utils/axios'

export default async function getDashboardMain(params: any): Promise<any> {
  const res: any = await request({
    url: '/dashboard/main',
    method: 'get',
    params: {
      from_date: params?.[0] ?? null,
      to_date: params?.[1] ?? null,
    },
  })

  return res
}

import Icon from "@ant-design/icons/lib/components/Icon";

import type { ReactElement } from "react";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const defaultIcon = (): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
  >
    <path
      d="M1.66602 9L4.95891 5.70711C5.29224 5.37377 5.45891 5.20711 5.45891 5C5.45891 4.79289 5.29224 4.62623 4.95891 4.29289L1.66602 1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function ArrowRightIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />;
}

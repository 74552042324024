import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33398 4L5.94267 6.61131C7.64172 7.57401 8.35959 7.57401 10.0586 6.61131L14.6673 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M1.34449 8.98391C1.38808 11.0276 1.40987 12.0494 2.16396 12.8064C2.91804 13.5634 3.96754 13.5897 6.06654 13.6425C7.36019 13.675 8.64111 13.675 9.93477 13.6425C12.0338 13.5897 13.0833 13.5634 13.8374 12.8064C14.5914 12.0494 14.6132 11.0276 14.6568 8.98391C14.6708 8.32679 14.6708 7.67355 14.6568 7.01643C14.6132 4.97274 14.5914 3.95089 13.8374 3.19394C13.0833 2.43698 12.0338 2.41061 9.93477 2.35788C8.64111 2.32537 7.36019 2.32537 6.06653 2.35787C3.96754 2.41061 2.91804 2.43697 2.16395 3.19393C1.40987 3.95088 1.38808 4.97273 1.34449 7.01642C1.33048 7.67354 1.33048 8.32679 1.34449 8.98391Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
)

export default function MailIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

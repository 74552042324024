/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import TableEmpty from '@/components/table-empty'
import { Flex, Skeleton, Typography } from 'antd'
import TableRow from './TableRow'
import NodataIcon from '../assets/nodata.svg'

function TableContacts({ data, isLoading, setDrawerOpen }: any): JSX.Element {
  return (
    <Flex vertical className="w-full mt-[16px]">
      {data?.length > 0 && (
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Имя
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Телефон номер
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Электронная почта
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Соц. сети
          </Typography.Text>

          <div />
        </Flex>
      )}

      {isLoading ? (
        <Skeleton active />
      ) : data?.length > 0 ? (
        data?.map((item: any, index: number) => (
          <TableRow key={item.id} item={item} />
        ))
      ) : (
        <TableEmpty
          title="Вы еще не добавили контактную информацию"
          description="Добавьте свою контактную информацию"
          icon={NodataIcon}
          addButtonEvent={() => {
            setDrawerOpen(true)
          }}
        />
      )}
    </Flex>
  )
}

export default TableContacts

import { Button, Flex, Popover } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

function MenuButtonWithChildren({ element }: any): JSX.Element {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isDarkMode, setIsDarkMode] = useState(false)
  function getLastString(inputString: string): string {
    const parts = inputString.split('/')
    const lastString = parts[parts.length - 1]
    return lastString
  }

  function isActive(childrens: any): boolean {
    return childrens.some(
      (child: any) => getLastString(pathname) === getLastString(child.link),
    )
  }

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [localStorage.getItem('theme')])

  const content = (
    <Flex className="flex-col w-full min-w-[160px] dark:bg-[#1A1D1F] p-[8px] dark:text-[#FFF]" gap={6}>
      {element.children.map((child: any) => (
        <Button
          key={child.id}
          className={`${
            getLastString(pathname) === getLastString(child.link)
              ? '!bg-[#F1F2F4] dark:!bg-[#272B30] dark:!text-[#FFF]'
              : ''
          } menu-item-margin p-[9px] flex justify-start rounded-[12px] items-center h-auto text-base font-normal shadow-none text-[#1E1E1E] cursor-pointer w-full hover:!bg-[#F1F2F4] hover:text-[#635BFF] border-0 text-left dark:bg-inherit dark:text-[#FFF] dark:hover:!bg-[#272B30]`}
          onClick={() => {
            navigate(child.link)
          }}
        >
          {child.name}
        </Button>
      ))}
    </Flex>
  )

  return (
    <Popover
      placement="bottom"
      arrow={false}
      content={content}
      overlayInnerStyle={{
        padding: 0,
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <Button
        className={`${
          isActive(element.children) ? '!text-[#635BFF] !bg-[#F1F2F4] dark:!bg-[#272B30] dark:!text-[#FFF]' : ''
        } menu-item-margin p-[9px] flex rounded-[12px] items-center h-auto text-base font-normal shadow-none text-[#1E1E1E] cursor-pointer w-full hover:!bg-[#F1F2F4] hover:text-[#635BFF] border-0 text-left dark:bg-inherit dark:text-[#FFF] dark:hover:!bg-[#272B30]`}
      >
        {element.icon}
        {element.name}
      </Button>
    </Popover>
  )
}

export default MenuButtonWithChildren

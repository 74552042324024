import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7787 12.3034H12.9604L12.7301 11.6807L11.3173 7.86176L10.9288 6.8116L10.4061 7.80186L8.0303 12.3034H7.94762L5.57218 7.84431L5.04486 6.85442L4.66108 7.9083L3.27035 11.7273L3.06053 12.3034H2.1982L2.29459 12.0105L4.70906 4.67203L7.54956 10.6129L7.99773 11.5503L8.45064 10.6152L11.3281 4.67475L13.6836 12.0071L13.7787 12.3034Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
)

export default function MetroIcon2(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

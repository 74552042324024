/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-return-await */
import { updateRCPage } from '@/features/residentialComplex/api'
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Modal, Typography } from 'antd'
import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import AdvantagesRow from '../../RCPageForm/AdvantagesRow'

function AdvantageModal({
  setAdvantageModal,
  advantageModal,
  refetch,
  data,
  showMessage,
}: any): JSX.Element {
  const { control, watch, setValue, getValues } = useForm()

  const {
    fields: advantagesFields,
    append: advantagesFieldsAppend,
    remove: removeAdvantagesFields,
  } = useFieldArray({
    control,
    name: 'advantages',
  })

  useEffect(() => {
    if (advantagesFields.length === 0) {
      advantagesFieldsAppend({})
    }
  }, [advantagesFields])

  useEffect(() => {
    setValue('advantages', data?.advantages)
  }, [data])

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: async () => await updateRCPage(getValues(), data?.id),
    onSuccess: () => {
      setAdvantageModal(false)
      showMessage('success', 'Преимущества успешно изменены')
      refetch()
    },
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url)
    const blob = await response.blob()

    const urlParts = url.split('/')
    const filename = urlParts[urlParts.length - 1]

    const mimeType =
      response.headers.get('content-type') ?? 'application/octet-stream'

    const file = new File([blob], filename, { type: mimeType })
    return file
  }

  const updateTitle = async (): Promise<void> => {
    let newArray = watch('advantages')

    newArray = await Promise.all(
      newArray.map(async (item: any) => {
        if (isValidURL(item.image)) {
          return {
            ...item,
            image: await urlToFile(item.image),
          }
        }
        return item
      }),
    )

    setValue('advantages', newArray)

    const formData = new FormData()
    const values = getValues()

    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key])) {
        values[key].forEach((item: any, index: number) => {
          if (item instanceof File) {
            formData.append(`${key}[${index}]`, item)
          } else {
            formData.append(`${key}[${index}]`, JSON.stringify(item))
          }
        })
      } else {
        formData.append(key, values[key])
      }
    })

    mutateUpdate(formData)
  }

  return (
    <Modal
      open={advantageModal}
      onOk={() => setAdvantageModal(false)}
      onCancel={() => setAdvantageModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc"
      width="80%"
    >
      <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Преимущества ЖК
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setAdvantageModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex
        vertical
        gap={24}
        className="p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid"
      >
        {advantagesFields?.map((field, index) => (
          <Flex key={field?.id} className="w-full" gap={20}>
            <Flex className="w-full">
              <AdvantagesRow
                index={index}
                control={control}
                watch={watch}
                setValue={setValue}
              />
            </Flex>

            <Flex className="w-max pt-[41px]">
              <Button
                type="link"
                disabled={advantagesFields.length === 1}
                className="w-[30px] h-[30px] flex items-center justify-center text-[#A9A9A9]"
                onClick={() => {
                  removeAdvantagesFields(index)
                }}
              >
                <DeleteOutlined />
              </Button>
            </Flex>
          </Flex>
        ))}

        <Flex className="w-full items-center justify-center mt-[24px]">
          <Button
            onClick={() => {
              advantagesFieldsAppend({})
            }}
            className="p-[14px_24px] w-max h-max border-[#635BFF] text-[#635BFF] rounded-[8px] text-base font-medium dark:!bg-[#2F3336]"
          >
            + Добавить новое преимущество
          </Button>
        </Flex>
      </Flex>

      <Flex className="p-[16px] items-center justify-end" gap={16}>
        <Button
          onClick={() => {
            setAdvantageModal(false)
          }}
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Отменить
        </Button>

        <Button
          onClick={updateTitle}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>
    </Modal>
  )
}

export default AdvantageModal

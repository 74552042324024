/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import TapIcon from '@/components/icons/tap-icon'
import UserGroupIcon from '@/components/icons/user-group-icon'
import ViewIcon from '@/components/icons/view'
import { getRCPage } from '@/features/residentialComplex/api'
import { useQuery } from '@tanstack/react-query'
import { Flex, Typography } from 'antd'
import { useMemo, useState } from 'react'
import getDashboardMain from '../api'
import AnalyticsChart from '../components/AnalyticsChart'
import AnalyticsRate from '../components/AnalyticsRate'
import AnalyticsStatistics from '../components/AnalyticsStatistics'
import './index.css'

function Analytics(): JSX.Element {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)

  const { data, isLoading } = useQuery({
    queryKey: ['dashboardMain', selectedDate],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    queryFn: () => getDashboardMain(selectedDate),
  })

  const { data: building } = useQuery({
    queryKey: ['rc-page-get-list'],
    queryFn: getRCPage,
    config: {
      refetchOnWindowFocus: false,
    },
  })

  const statistics = useMemo(() => {
    const dataStatistics = [
      {
        id: 1,
        title: 'Просмотры',
        value: data?.counts?.views_count,
        lastMonthValue: 11201,
        percent: 10.7,
        icon: <ViewIcon />,
      },
      {
        id: 2,
        title: 'Кликов кнопки «Показать номер телефона»',
        value: data?.counts?.phone_views_count,
        lastMonthValue: 417201,
        percent: 51.3,
        icon: (
          <TapIcon
            style={{
              color: '#A9A9A9',
              fontSize: '22px',
              width: '22px',
              height: '22px',
            }}
          />
        ),
      },
      {
        id: 3,
        title: 'Потенциальных клиентов',
        value: data?.counts?.clients,
        lastMonthValue: 1000,
        percent: 3.3,
        icon: (
          <UserGroupIcon
            style={{
              color: '#A9A9A9',
              fontSize: '22px',
              width: '22px',
              height: '22px',
            }}
          />
        ),
      },
    ]
    return dataStatistics
  }, [data])

  const overAllRating = useMemo(() => {
    const a =
      data?.reviews?.reduce(
        (sum: number, obj: any) => sum + obj?.review_count,
        0,
      ) / data?.reviews?.length
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(a)) {
      return 0
    }
    return parseFloat(a.toFixed(1))
  }, [data])

  const allRatesCount = useMemo(
    () =>
      data?.reviews?.reduce(
        (sum: number, obj: any) => sum + obj?.review_count,
        0,
      ),
    [data],
  )

  function addMissingRatesAndSort(arr: any): any {
    const rateMap = new Map(arr?.map((item: any) => [item.rate, item.count]))
    const result = []
    // eslint-disable-next-line no-plusplus
    for (let rate = 1; rate <= 5; rate++) {
      if (rateMap.has(rate)) {
        result.push({ rate, count: rateMap.get(rate) })
      } else {
        result.push({ rate, count: 0 })
      }
    }
    result.sort((a, b) => b.rate - a.rate)
    return result
  }

  const statisticsReview = useMemo(() => {
    const dataStatistics = data?.reviews?.map((item: any) => ({
      rate: item?.rating,
      count: item?.review_count,
    }))

    return addMissingRatesAndSort(dataStatistics) ?? []
  }, [data])

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Flex vertical className="mb-[35px]">
        <Typography.Title className="m-0 !text-[32px] !font-medium !text-[#1E1E1E] !mb-[8px] dark:!text-[#FFFFFF]">
          Аналитика ЖК «{building?.name}»
        </Typography.Title>
        <Typography.Text className="text-[#878787] font-normal text-base">
          Вот что происходит с вашей аналитикой сегодня.
        </Typography.Text>
      </Flex>

      <Flex className="parent">
        <AnalyticsStatistics
          statistics={statistics}
          data={data}
          isLoading={isLoading}
        />

        <AnalyticsChart
          data={data}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        <AnalyticsRate
          overAllRating={overAllRating}
          allRatesCount={allRatesCount}
          statisticsReview={statisticsReview}
          isLoading={isLoading}
        />
      </Flex>

      {/*       
      <Flex gap={18} className="grid grid-cols-4">
        <AnalyticsStatistics statistics={statistics} data={data} />
      </Flex> */}

      {/* <Flex gap={18} className="grid grid-cols-[3fr_1fr] pb-[70px]">
        <AnalyticsChart
          data={data}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />

        <AnalyticsRate
          overAllRating={overAllRating}
          allRatesCount={allRatesCount}
          statisticsReview={statisticsReview}
        />
      </Flex> */}

      {/* <Flex className="mt-[20px]">
        <Premium />
      </Flex> */}

      {/* <Flex
        gap={18}
        className="mt-[20px] grid grid-cols-[2.5fr_1.5fr] pb-[70px]"
      >
        <AnalyticsTopPlacements data={data?.top_apartments} />
        <AnalyticsLastActions />
      </Flex> */}
    </Flex>
  )
}

export default Analytics

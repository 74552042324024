import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11152_65274)">
      <path
        d="M7.03685 1.94273C7.40945 1.53904 7.59574 1.3372 7.79371 1.21946C8.27137 0.935379 8.85954 0.926545 9.34518 1.19616C9.54644 1.3079 9.73847 1.50406 10.1225 1.89638C10.5066 2.2887 10.6986 2.48486 10.808 2.69046C11.0719 3.18656 11.0633 3.7874 10.7852 4.27535C10.6699 4.47758 10.4723 4.66789 10.0772 5.0485L5.37531 9.57715C4.62644 10.2984 4.252 10.6591 3.78403 10.8419C3.31606 11.0246 2.8016 11.0112 1.77268 10.9843L1.63269 10.9806C1.31945 10.9724 1.16283 10.9683 1.07179 10.865C0.980751 10.7617 0.993181 10.6022 1.01804 10.2831L1.03154 10.1098C1.1015 9.21177 1.13649 8.76274 1.31185 8.35911C1.48722 7.95547 1.78972 7.62773 2.39471 6.97225L7.03685 1.94273Z"
        stroke="#878787"
        strokeLinejoin="round"
      />
      <path
        d="M6.14645 2.35355L9.64645 5.85355L10.3536 5.14645L6.85355 1.64645L6.14645 2.35355Z"
        fill="#878787"
      />
    </g>
    <defs>
      <clipPath id="clip0_11152_65274">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default function EditIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import HFInput from '@/components/FormElements/HFInput'
import HFPassword from '@/components/FormElements/HFPassword'
import FRow from '@/components/FormElements/HFRow'
import TelegramIcon from '@/components/icons/telegram-icon'
import { useAuthContext } from '@/contexts'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { useMutation } from '@tanstack/react-query'
import { Button, Checkbox, Flex, Image, Modal, Typography } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { login, updatePassword } from '../api'
import keyImage from '../assets/key.jpg'
import LogoImage from '../assets/logo.svg'

function Login(): JSX.Element {
  const { control, setValue, getValues, watch, setError } = useForm()
  const [openModalForgotPassword, setOpenModalForgotPassword] = useState(false)
  const [step, setStep] = useState(1)
  const { setIsAuth } = useAuthContext()
  const navigate = useNavigate()

  const onChange = (e: CheckboxChangeEvent): void => {
    setValue('accept', e.target.checked)
  }
  const showMessage = useMessage()

  const { mutate: mutateLogin, isLoading: isLoadingLogin } = useMutation({
    mutationFn: login,
    onSuccess: (res) => {
      localStorage.setItem('refresh_token', res.refresh)
      localStorage.setItem('access_token', res.access)
      if (res.is_first_time) {
        setStep(2)
      } else {
        setIsAuth(true)
        navigate('/')
        queryClient.refetchQueries()
      }
    },
    onError: async (error: Error) => {
      console.error(error)
      await showMessage(
        'error',
        'Ошибка при входе в систему. Пожалуйста, проверьте введенные данные.',
      )
    },
  })

  const { mutate: mutateUpdatePassword, isLoading: isLoadingUpdate } =
    useMutation({
      mutationFn: updatePassword,
      onSuccess: () => {
        setIsAuth(true)
        navigate('/')
      },
      onError: (error: Error) => {
        console.error(error)
      },
    })

  // on press enter key
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      if (step === 1) {
        mutateLogin({
          username: getValues('username'),
          password: getValues('password'),
        })
      } else {
        mutateUpdatePassword({
          password1: getValues('password1'),
          password2: getValues('password2'),
        })
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  }, [])

  return (
    <Flex className="bg-[#FCFCFD] h-[100vh] items-center justify-center">
      <Flex
        vertical
        className="bg-[#FFF] w-[500px] h-max border rounded-2xl border-[#E5E7EB] border-solid p-[40px]"
      >
        <Image height={40} preview={false} src={LogoImage} />

        <Flex vertical className="items-center mt-[24px]">
          <Typography.Title className="!text-2xl !font-medium m-0 mb-[10px] text-[#1E1E1E]">
            {step === 1 ? 'Добро пожаловать!' : 'Обновить пароль'}
          </Typography.Title>
          <Typography.Paragraph className="text-center text-base font-normal mb-[24px] text-[#878787]">
            {step === 1
              ? 'Войдите в свой аккаунт'
              : 'Придумайте надежный пароль, состоящий из букв, цифр и других символов'}
          </Typography.Paragraph>
        </Flex>

        {step === 1 ? (
          <Flex vertical gap={24}>
            <FRow label="Ваш логин">
              <HFInput
                control={control}
                name="username"
                placeholder="Введите ваш логин"
              />
            </FRow>

            <FRow label="Ваш пароль">
              <HFPassword
                control={control}
                name="password"
                placeholder="Введите ваш пароль"
              />
            </FRow>
          </Flex>
        ) : (
          <Flex vertical gap={24}>
            <FRow label="Ваш пароль">
              <HFPassword
                control={control}
                name="password1"
                placeholder="Введите ваш пароль"
              />
            </FRow>

            <FRow label="Потвердить пароль">
              <HFPassword
                control={control}
                name="password2"
                placeholder="Потвердите ваш пароль"
              />
            </FRow>
          </Flex>
        )}

        {step === 1 && (
          <Flex className="justify-end mt-[16px] mb-[24px]">
            <Button
              className="underline text-[#635BFF] font-normal text-base p-0"
              type="link"
              onClick={() => {
                setOpenModalForgotPassword(true)
              }}
            >
              Забыли пароль?
            </Button>
          </Flex>
        )}

        {step === 2 && (
          <Flex className="my-[24px]">
            <Checkbox onChange={onChange}>
              Я принимаю условия{' '}
              <Typography.Link className="text-[#635BFF]">
                пользовательского соглашения
              </Typography.Link>
            </Checkbox>
          </Flex>
        )}

        <Flex>
          {step === 1 ? (
            <Button
              loading={isLoadingLogin}
              onClick={() => {
                mutateLogin({
                  username: getValues('username'),
                  password: getValues('password'),
                })
              }}
              className="rounded-[8px] bg-[#635BFF] text-[#FFFFFF] font-medium text-base w-full h-auto py-[10px]"
            >
              Войти
            </Button>
          ) : (
            <Button
              loading={isLoadingUpdate}
              disabled={watch('accept') !== true}
              onClick={() => {
                mutateUpdatePassword({
                  password1: getValues('password1'),
                  password2: getValues('password2'),
                })
              }}
              className="rounded-[8px] bg-[#635BFF] text-[#FFFFFF] font-medium text-base w-full h-auto py-[10px]"
            >
              Войти
            </Button>
          )}
        </Flex>
      </Flex>

      <Modal
        width={400}
        footer={null}
        centered
        open={openModalForgotPassword}
        onOk={() => {
          setOpenModalForgotPassword(false)
        }}
        onCancel={() => {
          setOpenModalForgotPassword(false)
        }}
      >
        <Flex vertical gap={20} className="items-center">
          <Flex className="w-[64px] h-[64px] overflow-hidden">
            <Image preview={false} src={keyImage} />
          </Flex>

          <Flex vertical className="items-center">
            <Typography.Title className="!text-2xl !font-medium m-0 mb-[10px] text-[#1E1E1E]">
              Забыли пароль?
            </Typography.Title>
            <Typography.Paragraph className="text-base font-normal mb-[24px] text-[#878787] text-center">
              Если вы забыли пароль или логин, обратитесь в нашу службу
              технической поддержки по телефону{' '}
              <Typography.Link className="!text-[#635BFF]">
                +99890 271 17 10
              </Typography.Link>
              .
            </Typography.Paragraph>
            <Typography.Paragraph className="text-base font-normal mb-[24px] text-[#878787] text-center">
              или свяжитесь с нами через Telegram
            </Typography.Paragraph>
            <Flex className="items-center" gap={12}>
              <TelegramIcon />
              <Typography.Link
                href="https://t.me/urbo_support"
                className="!text-[#635BFF]"
              >
                @urbo_support
              </Typography.Link>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Login

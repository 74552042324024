import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.363 4.69683C16.6559 4.40394 16.6559 3.92907 16.363 3.63617C16.0701 3.34328 15.5952 3.34328 15.3024 3.63617L16.363 4.69683ZM3.63569 15.3028C3.34279 15.5957 3.34279 16.0706 3.63569 16.3635C3.92858 16.6564 4.40345 16.6564 4.69635 16.3635L3.63569 15.3028ZM15.3024 3.63617L3.63569 15.3028L4.69635 16.3635L16.363 4.69683L15.3024 3.63617Z"
      fill="#A9A9A9"
    />
  </svg>
)

export default function SlashIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

import BlurImage from '@/components/BlurImage'
import EditIcon from '@/components/icons/edit-icon'
import IpotekaIcon from '@/components/icons/ipoteka-icon'
import KubokIcon from '@/components/icons/kubok-icon'
import { Button, Flex, Typography } from 'antd'
import { useState } from 'react'
import MainImageModal from './AllModalsRCPage/MainImageModal'
import MainTagsModal from './AllModalsRCPage/MainTagsModal'
import TitleModal from './AllModalsRCPage/Title'
import formatAmount from '@/helpers/formatAmount'
import { format } from 'date-fns'

export default function MainInformationSection({
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const [titleModal, setTitleModal] = useState(false)
  const [mainImageModal, setMainImageModal] = useState(false)
  const [mainTagsModal, setMainTagsModal] = useState(false)

  return (
    <>
      <Flex className="w-full relative">
        <Flex className="w-full ">
          <BlurImage
            src={data?.image}
            preview={false}
            height={500}
            width="100%"
            className="object-cover rounded-[16px]"
          />
        </Flex>

        <Flex
          className="w-full absolute h-full p-[27px] justify-between items-center"
          vertical
        >
          <Flex className="items-center justify-end w-full">
            <Button
              onClick={() => {
                setMainImageModal(true)
              }}
              className="w-[38px] h-[38px] flex items-center justify-center p-0 rounded-full bg-[#FFFFFF] text-[#A9A9A9]"
            >
              <EditIcon />
            </Button>
          </Flex>

          <Flex vertical className="items-center justify-center w-full">
            <Flex className="items-center" gap={8}>
              {data?.ipoteka && (
                <Flex className="items-center bg-[#EEEEEE] rounded-[14px] p-[6px_10px] gap-[5px]">
                  <IpotekaIcon
                    style={{
                      color: '#FA8F54',
                    }}
                  />

                  <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
                    Ипотека
                  </Typography.Text>
                </Flex>
              )}

              {data?.installment && (
                <Flex className="items-center bg-[#FCE7DB] rounded-[14px] p-[6px_10px] gap-[5px]">
                  <KubokIcon />

                  <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
                    Рассрочка
                  </Typography.Text>
                </Flex>
              )}

              {data?.installment === null && data?.ipoteka === null && (
                <Flex className="items-center bg-[#FCE7DB] rounded-[14px] p-[6px_10px] gap-[5px]">
                  <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
                    Нет никаких тегов
                  </Typography.Text>
                </Flex>
              )}

              <Button
                onClick={() => {
                  setMainTagsModal(true)
                }}
                className="w-[38px] h-[38px] flex items-center justify-center p-0 rounded-full bg-[#FFFFFF] text-[#A9A9A9]"
              >
                <EditIcon />
              </Button>
            </Flex>

            <Flex className="items-center" gap={8}>
              <Typography.Text className="!text-[#FFF] !font-medium !text-5xl">
                {data?.name}
              </Typography.Text>

              <Button
                onClick={() => {
                  setTitleModal(true)
                }}
                className="w-[38px] h-[38px] flex items-center justify-center p-0 rounded-full bg-[#FFFFFF] text-[#A9A9A9]"
              >
                <EditIcon />
              </Button>
            </Flex>
          </Flex>

          <Flex
            className="bg-[#FFF] p-[16px_22px] rounded-[16px] w-max dark:bg-[#1A1D1F] dark:border-[#1A1D1F] dark:text-[#FFF]"
            gap={56}
          >
            <Flex vertical gap={12}>
              <Flex className="items-center" gap={6}>
                <Typography.Text className="!text-[#A9A9A9] !text-[14px] !font-normal">
                  Сдача комплекса
                </Typography.Text>
                {/* <Button
                      onClick={() => {
                        setDeadlineModal(true)
                      }}
                      className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
                    >
                      <EditIcon />
                    </Button> */}
              </Flex>

              <Typography.Text className="!text-[#1E1E1E] !font-medium !text-[16px] dark:!text-[#FFF]">
                {data?.deadline?.min &&
                  format(new Date(data?.deadline?.min), 'dd.MM.yyyy')}
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Flex className="items-center" gap={6}>
                <Typography.Text className="!text-[#A9A9A9] !text-[14px] !font-normal">
                  Площадь квартир
                </Typography.Text>
                {/* <Button
                      onClick={() => {
                        setDeadlineModal(true)
                      }}
                      className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
                    >
                      <EditIcon />
                    </Button> */}
              </Flex>

              <Typography.Text className="!text-[#1E1E1E] !font-medium !text-[16px] dark:!text-[#FFF]">
                от {formatAmount(data?.area?.min)} м2- до{' '}
                {formatAmount(data?.area?.max)} м2
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Flex className="items-center" gap={6}>
                <Typography.Text className="!text-[#A9A9A9] !text-[14px] !font-normal">
                  Количество домов
                </Typography.Text>
                {/* <Button
                      onClick={() => {
                        setAreaModal(true)
                      }}
                      className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
                    >
                      <EditIcon />
                    </Button> */}
              </Flex>

              <Typography.Text className="!text-[#1E1E1E] !font-medium !text-[16px] dark:!text-[#FFF]">
                Более {formatAmount(data?.block_count ?? 0)} штук
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Flex className="items-center" gap={6}>
                <Typography.Text className="!text-[#A9A9A9] !text-[14px] !font-normal">
                  Количество квартир
                </Typography.Text>
                {/* <Button
                      onClick={() => {
                        setQuantityModal(true)
                      }}
                      className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
                    >
                      <EditIcon />
                    </Button> */}
              </Flex>

              <Typography.Text className="!text-[#1E1E1E] !font-medium !text-[16px] dark:!text-[#FFF]">
                Более {formatAmount(data?.apartment_count ?? 0)} штук
              </Typography.Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <TitleModal
        setTitleModal={setTitleModal}
        titleModal={titleModal}
        data={data}
        refetch={refetch}
        showMessage={showMessage}
      />

      <MainImageModal
        setMainImageModal={setMainImageModal}
        mainImageModal={mainImageModal}
        data={data}
        refetch={refetch}
        showMessage={showMessage}
      />

      <MainTagsModal
        setMainTagsModal={setMainTagsModal}
        mainTagsModal={mainTagsModal}
        data={data}
        refetch={refetch}
        showMessage={showMessage}
      />
    </>
  )
}

function isEmpty(value: any): boolean {
    return (
      value === '' ||
      value === null ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' &&
        !Array.isArray(value) &&
        Object.keys(value).length === 0)
    )
  }
  
  export default function filterObject(obj: any): any {
    if (typeof obj !== 'object' || obj === null) {
      return obj
    }
  
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key]
  
      if (!isEmpty(value)) {
        acc[key] =
          typeof value === 'object' && !Array.isArray(value)
            ? filterObject(value)
            : value
      }
  
      return acc
    }, {})
  }
  
import SlashIcon from '@/components/icons/SlashIcon'
import AddCircleIcon from '@/components/icons/add-circle'
import CloseIcon from '@/components/icons/close'
import LogoDark from '@/components/icons/logo-dark'
import LogoLight from '@/components/icons/logo-light'
import MacCommandIcon from '@/components/icons/mac-command'
import PlusAddIcon from '@/components/icons/plus-icon'
import { SearchOutlined } from '@ant-design/icons'
import { Button, Flex, Input, Modal, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InstructionsModal from './InstructionsModal'
import NotificationsPopover from './NotificationsPopover'
import ProfilePopover from './ProfilePopover'
import './style.css'

function Header(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate()

  const toggleDarkMode = (): void => {
    setIsDarkMode(!document.body.classList.contains('dark'))
    document.body.classList.toggle('dark')
    localStorage.setItem(
      'theme',
      document.body.classList.contains('dark') ? 'dark' : 'light',
    )
  }

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [])

  document.addEventListener('keydown', (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === '/') {
      setIsModalOpen(!open)
    }
  })

  const hide = (): void => {
    setOpen(false)
  }

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  return (
    <Flex className="p-[16px_24px] z-10 bg-[#FFF] h-auto w-full items-center justify-between border-b border-x-0 border-t-0 border-solid border-[#EEEEEE] dark:bg-[#1A1D1F] dark:border-[#272B30]">
      <Flex
        className="items-center h-full w-max cursor-pointer"
        onClick={() => {
          navigate('/')
        }}
      >
        {!isDarkMode ? <LogoLight /> : <LogoDark />}
      </Flex>

      <Flex gap={16} className="items-center">
        <Flex>
          <Input
            placeholder="Поиск"
            onClick={() => {
              setIsModalOpen(true)
            }}
            className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#A9A9A9] text-sm font-normal min-w-[400px] dark:!bg-[#272B30] dark:border-[#272B30]"
            prefix={
              <Flex className="mr-[10px]">
                <SearchOutlined
                  style={{
                    color: '#A9A9A9',
                  }}
                />
              </Flex>
            }
            suffix={
              <Flex gap={4}>
                <MacCommandIcon />
                <PlusAddIcon
                  style={{
                    color: '#A9A9A9',
                  }}
                />
                <SlashIcon />
              </Flex>
            }
          />
        </Flex>

        <InstructionsModal />

        <NotificationsPopover isDarkMode={isDarkMode} />

        <ProfilePopover
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
          hide={hide}
          handleOpenChange={handleOpenChange}
          open={open}
        />
      </Flex>

      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        closeIcon={null}
        footer={null}
        className="search-modal"
      >
        <Flex vertical>
          {/* <Flex className="p-[16px] border border-x-0 border-t-0 border-solid border-[#E5E7EB]">
            <Input
              placeholder="Поиск"
              className="border-0 rounded-lg h-max w-full text-[#A9A9A9] text-sm font-normal min-w-[400px]"
              prefix={
                <Flex className="mr-[10px]">
                  <SearchOutlined
                    style={{
                      color: '#A9A9A9',
                    }}
                  />
                </Flex>
              }
            />
          </Flex> */}

          {/* <Flex
            className="p-[16px] border border-x-0 border-t-0 border-solid border-[#E5E7EB]"
            vertical
          >
            <Typography.Text className="text-sm font-normal text-[#A9A9A9] mb-[16px]">
              Последние запросы
            </Typography.Text>

            <Flex vertical>
              <Button
                type="link"
                className="text-[#1E1E1E] font-normal text-sm p-[8px] rounded-[4px] h-auto flex items-center justify-between w-full cursor-pointer"
              >
                Обработано
                <Button type="text">
                  <CloseIcon />
                </Button>
              </Button>

              <Button
                type="link"
                className="text-[#1E1E1E] font-normal text-sm p-[8px] rounded-[4px] h-auto flex items-center justify-between w-full cursor-pointer"
              >
                Обработано
                <Button type="text">
                  <CloseIcon />
                </Button>
              </Button>

              <Button
                type="link"
                className="text-[#1E1E1E] font-normal text-sm p-[8px] rounded-[4px] h-auto flex items-center justify-between w-full cursor-pointer"
              >
                Обработано
                <Button type="text">
                  <CloseIcon />
                </Button>
              </Button>
            </Flex>
          </Flex> */}

          <Flex vertical className="p-[16px]">
            <Button
              type="link"
              onClick={() => {
                navigate('/residential-complex/detail?addHome=true')
                setIsModalOpen(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[8px] rounded-[4px] h-auto flex items-start w-full cursor-pointer gap-4"
            >
              <AddCircleIcon
                style={{
                  height: '24px',
                  width: '24px',
                  color: '#A9A9A9',
                }}
              />
              <Flex vertical className="items-start" gap={8}>
                <Typography.Text className="text-[#1E1E1E] text-base font-normal">
                  Добавить дом
                </Typography.Text>
                <Typography.Text className="text-[#878787] font-normal text-sm">
                  Добавьте новые блоки
                </Typography.Text>
              </Flex>
            </Button>

            <Button
              type="link"
              onClick={() => {
                navigate('/residential-complex/flats?addLayout=true')
                setIsModalOpen(false)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[8px] rounded-[4px] h-auto flex items-start w-full cursor-pointer gap-4 hover:!text-[#635BFF]"
            >
              <AddCircleIcon
                style={{
                  height: '24px',
                  width: '24px',
                  color: '#A9A9A9',
                }}
              />
              <Flex vertical className="items-start" gap={8}>
                <Typography.Text className="text-[#1E1E1E] text-base font-normal">
                  Добавить помещение
                </Typography.Text>
                <Typography.Text className="text-[#878787] font-normal text-sm">
                  Добавьте новые квартиры
                </Typography.Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default Header

import { Flex } from 'antd'
import Header from './Header'
import SecondHeader from './SecondHeader'

function LayoutStructure({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <Flex className="w-full">
      <Flex vertical className="w-full dark:bg-[#111315]">
        <Header />
        <SecondHeader />
        {children}
      </Flex>
    </Flex>
  )
}

export default LayoutStructure

import ContactsIcon from '@/components/icons/contacts-icon'
import HouseIcon from '@/components/icons/house-icon'
import MenuIcon from '@/components/icons/menu-icon'
import StarSquaredIcon from '@/components/icons/star-squared'
import UserGroupIcon from '@/components/icons/user-group-icon'
import { Flex } from 'antd'
import MenuButton from '../components/MenuButton'
import MenuButtonWithChildren from '../components/MenuButtonWithChildren'

function SecondHeader(): JSX.Element {
  const sidebarElements = [
    {
      id: 'analytics',
      name: 'Аналитика',
      icon: <MenuIcon />,
      link: '/analytics',
      children: [],
    },
    {
      id: 'residential-complex',
      name: 'Жилой комплекс',
      icon: (
        <HouseIcon
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
      link: '/residential-complex',
      children: [
        {
          id: 'detail',
          name: 'Дома',
          link: '/residential-complex/detail',
        },
        {
          id: 'flats',
          name: 'Планировки',
          link: '/residential-complex/flats',
        },
        // {
        //   id: 'residential-complex-page',
        //   name: 'Страница ЖК',
        //   link: '/residential-complex/residential-complex-page',
        // },
      ],
    },
    {
      id: 'clients',
      name: 'Клиенты',
      icon: (
        <UserGroupIcon
          style={{
            width: '16px',
            height: '16px',
          }}
        />
      ),
      link: '/clients',
    },
    {
      id: 'reviews',
      name: 'Отзывы',
      icon: <StarSquaredIcon />,
      link: '/reviews',
    },
    // {
    //   id: 'banks',
    //   name: 'Банки',
    //   icon: <DiscountRoundedIcon />,
    //   link: '/banks',
    // },
    {
      id: 'contacts',
      name: 'Контакты',
      icon: <ContactsIcon />,
      link: '/contacts',
    },
  ]

  return (
    <Flex className="p-[22px_24px] z-10 bg-[#FFF] h-auto w-full items-center justify-between sticky top-0 border-b border-x-0 border-t-0 border-solid border-[#EEEEEE] dark:bg-[#1A1D1F] dark:border-[#272B30]">
      <Flex gap={10} className="items-center">
        {sidebarElements.map((element: any) =>
          element.children?.length > 0 ? (
            <MenuButtonWithChildren key={element.id} element={element} />
          ) : (
            <MenuButton key={element.id} element={element} />
          ),
        )}
      </Flex>
    </Flex>
  )
}

export default SecondHeader

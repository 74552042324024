/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import JKIcon from '@/components/icons/jk-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Flex, Skeleton } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import getListBlocks from '../api'
import HouseAddDrawer from '../components/HouseAddDrawer'
import HouseEmptyPage from '../components/HouseEmptyPage'
import HouseFilterDrawer from '../components/HouseFilterDrawer'
import HouseTablePage from '../components/HouseTablePage'

function DetailPage(): JSX.Element {
  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const { control, watch, reset, unregister } = useForm({
    filter: {
      status: 'active',
    },
  })
  const [page, setPage] = useState(1)

  const [searchParams, setSearchParams] = useSearchParams('addLayout')

  useEffect(() => {
    if (searchParams.get('addHome') === 'true') {
      setAddDrawerOpen(true)
    }
  }, [])

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['blocks', watch('filter'), page],
    queryFn: (): any => getListBlocks({ ...watch('filter'), page }),
  })

  const filterCount = useMemo(() => {
    let count = 0
    if (watch('filter.floor_count')) {
      count++
    }
    if (watch('filter.deadline')) {
      count++
    }
    if (watch('filter.status')) {
      count++
    }
    return count
  }, [
    watch('filter.status'),
    watch('filter.floor_count'),
    watch('filter.deadline'),
  ])

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <JKIcon />
                <span className="font-normal text-sm text-[#A9A9A9]">
                  Жилой комплекс
                </span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span className="font-normal text-sm text-[#1E1E1E] dark:text-[#FFFFFF]">
                  Дома
                </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px] h-full dark:bg-[#1A1D1F] dark:border-[#272B30]"
      >
        {isLoading ? (
          <Skeleton active />
        ) : data?.results?.length > 0 ? (
          <HouseTablePage
            setFilterDrawerOpen={setFilterDrawerOpen}
            setAddDrawerOpen={setAddDrawerOpen}
            data={data}
            refetch={refetch}
            setPage={setPage}
            filterCount={filterCount}
          />
        ) : (
          <HouseEmptyPage
            setAddDrawerOpen={setAddDrawerOpen}
            setFilterDrawerOpen={setFilterDrawerOpen}
            filterCount={filterCount}
          />
        )}
      </Flex>

      <HouseFilterDrawer
        setFilterDrawerOpen={setFilterDrawerOpen}
        filterDrawerOpen={filterDrawerOpen}
        control={control}
        reset={reset}
        unregister={unregister}
      />

      <HouseAddDrawer
        setAddDrawerOpen={setAddDrawerOpen}
        addDrawerOpen={addDrawerOpen}
        refetch={refetch}
      />
    </Flex>
  )
}

export default DetailPage

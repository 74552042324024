import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 9.66683C3.33398 6.68127 3.33398 5.18849 4.26148 4.26099C5.18897 3.3335 6.68176 3.3335 9.66732 3.3335C12.6529 3.3335 14.1457 3.3335 15.0732 4.26099C16.0007 5.18849 16.0007 6.68127 16.0007 9.66683C16.0007 12.6524 16.0007 14.1452 15.0732 15.0727C14.1457 16.0002 12.6529 16.0002 9.66732 16.0002C6.68176 16.0002 5.18897 16.0002 4.26148 15.0727C3.33398 14.1452 3.33398 12.6524 3.33398 9.66683Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M10.2432 6.81489L10.8298 7.99779C10.9098 8.16245 11.1231 8.3204 11.3031 8.35064L12.3663 8.52875C13.0462 8.64301 13.2062 9.14036 12.7163 9.63099L11.8897 10.4644C11.7497 10.6055 11.673 10.8777 11.7164 11.0727L11.953 12.1043C12.1397 12.9209 11.7097 13.2368 10.9931 12.81L9.99656 12.2152C9.81658 12.1077 9.51994 12.1077 9.33663 12.2152L8.34007 12.81C7.62682 13.2368 7.19353 12.9176 7.38018 12.1043L7.61682 11.0727C7.66015 10.8777 7.58349 10.6055 7.44351 10.4644L6.61693 9.63099C6.13032 9.14036 6.28697 8.64301 6.96689 8.52875L8.03011 8.35064C8.20675 8.3204 8.42006 8.16245 8.50006 7.99779L9.08666 6.81489C9.40662 6.17303 9.92656 6.17303 10.2432 6.81489Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function StarSquaredIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Flex, Typography } from 'antd'
import RatingHeader from '../components/RatingHeader'
import RatingTable from '../components/RatingTable'

function Reviews(): JSX.Element {
  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <HomeOutlined />
                <span className="font-normal text-sm text-[#A9A9A9]">
                  Главная
                </span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span className="font-normal text-sm text-[#1E1E1E] dark:text-[#FFFFFF]">
                  Отзывы
                </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px] dark:bg-[#1A1D1F] dark:border-[#272B30]"
      >
        <Flex className="items-center justify-between w-full border-b-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] pb-[24px] dark:border-[#272B30]">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 dark:!text-[#FFF]">
            Отзывы
          </Typography.Title>
        </Flex>

        <Flex className="w-full pt-[24px]">
          <RatingHeader />
        </Flex>

        <Flex className="w-full pt-[24px]">
          <RatingTable />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Reviews

import TableEmpty from '@/components/table-empty'
import { Checkbox, Flex, Pagination, Skeleton, Typography } from 'antd'
import NodataIcon from '../assets/nodata.svg'
import TableRow from './TableRow'

function TableClients({
  clientsList,
  refetchList,
  isLoading,
  setPage,
  selectedRowIds,
  setSelectedRowIds,
}: any): JSX.Element {
  return clientsList?.count === 0 ? (
    <TableEmpty
      title="Клиенты"
      description="Список клиентов пуст"
      icon={NodataIcon}
      addButtonEvent={null}
    />
  ) : (
    <Flex vertical className="w-full mt-[16px]">
      <Flex className="w-full grid grid-cols-[0.5fr_1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
        <Flex>
          <Checkbox
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRowIds(
                  clientsList?.results.map((client: any) => client.id),
                )
              } else {
                setSelectedRowIds([])
              }
            }}
          />
        </Flex>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Клиент ID
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Имя
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Телефон номер
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Статус
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Дата заявки
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Планировка
        </Typography.Text>

        <div />
      </Flex>

      {isLoading ? (
        <Skeleton active />
      ) : (
        clientsList?.results.map((client: any) => (
          <TableRow
            key={client.id}
            client={client}
            refetchList={refetchList}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
          />
        ))
      )}

      <Flex className="mt-[24px]">
        <Pagination
          defaultCurrent={1}
          onChange={(pageCount) => {
            setPage(pageCount)
          }}
          total={clientsList?.count}
          showSizeChanger={false}
        />
      </Flex>
    </Flex>
  )
}

export default TableClients

import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11152_65421)">
      <path
        d="M2.51907 7.96173C1.88705 6.8597 1.58188 5.95982 1.39788 5.04764C1.12573 3.69855 1.74852 2.3807 2.78024 1.53981C3.21628 1.18442 3.71614 1.30584 3.97399 1.76843L4.55611 2.81277C5.01751 3.64054 5.24821 4.05443 5.20245 4.49322C5.15669 4.93202 4.84556 5.2894 4.2233 6.00417L2.51907 7.96173ZM2.51907 7.96173C3.79832 10.1923 5.80588 12.201 8.03908 13.4817M8.03908 13.4817C9.14111 14.1138 10.041 14.4189 10.9532 14.6029C12.3023 14.8751 13.6201 14.2523 14.461 13.2206C14.8164 12.7845 14.695 12.2847 14.2324 12.0268L13.188 11.4447C12.3603 10.9833 11.9464 10.7526 11.5076 10.7984C11.0688 10.8441 10.7114 11.1553 9.99665 11.7775L8.03908 13.4817Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11152_65421">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default function CallIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-return-await */
/* eslint-disable @typescript-eslint/no-floating-promises */
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import DeleteModal from '@/components/delete-modal'
import {
  getListInstallment,
  getListIpoteka,
  updateRCPage,
} from '@/features/residentialComplex/api'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Checkbox, Flex, Modal, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

export default function MainTagsModal({
  setMainTagsModal,
  mainTagsModal,
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const [deleteApproveModal, setDeleteApproveModal] = useState(false)

  const { data: ipotekaList, isLoading: ipotekaLoader } = useQuery({
    queryKey: ['getListIpoteka'],
    queryFn: async () => await getListIpoteka(),
  })

  const { data: installmentList, isLoading: installmentLodaer } = useQuery({
    queryKey: ['getListInstallment'],
    queryFn: async () => await getListInstallment(),
  })

  const { control, handleSubmit, setValue, getValues, watch } = useForm({
    defaultValues: {
      ipoteka: data?.ipoteka?.id,
      installment: data?.installment?.id,
      ipoteka_boolean: data?.ipoteka !== null,
      installment_boolean: data?.installment !== null,
    },
  })

  useEffect(() => {
    setValue(`ipoteka`, data?.ipoteka?.id)
    setValue(`installment`, data?.installment?.id)
    setValue(`ipoteka_boolean`, data?.ipoteka !== null)
    setValue(`installment_boolean`, data?.installment !== null)
  }, [data])

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: async () =>
      await updateRCPage(
        {
          ipoteka: getValues('ipoteka') || '',
          installment: getValues('installment') || '',
        },
        data?.id,
      ),
    onSuccess: () => {
      setMainTagsModal(false)
      showMessage('success', 'Теги успешно обновлено')
      refetch()
    },
  })

  const updateTags = (): void => {
    mutateUpdate()
  }

  return (
    <Modal
      open={mainTagsModal}
      onOk={() => setMainTagsModal(false)}
      onCancel={() => setMainTagsModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc"
      width="70%"
    >
      <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Теги
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setMainTagsModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex className="p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid">
        <FRow label="Выберите теги">
          <Flex className="grid grid-cols-2" gap={24}>
            <Flex vertical gap={12}>
              <Checkbox
                checked={watch('installment_boolean')}
                onChange={(e) => {
                  setValue(`installment_boolean`, e.target.checked)
                  if (!e.target.checked) {
                    setValue(`installment`, null)
                  }
                }}
              >
                Рассрочка
              </Checkbox>

              <FRow label="Максимальное колво месяцев">
                <HFSelect
                  control={control}
                  loading={installmentLodaer}
                  disabled={!watch('installment_boolean')}
                  name="installment"
                  options={installmentList?.map((item: any) => ({
                    label: `${item?.month} месяцев`,
                    value: item?.id,
                  }))}
                />
              </FRow>
            </Flex>

            <Flex vertical gap={12}>
              <Checkbox
                checked={watch('ipoteka_boolean')}
                onChange={(e) => {
                  setValue(`ipoteka_boolean`, e.target.checked)
                  if (!e.target.checked) {
                    setValue(`ipoteka`, null)
                  }
                }}
              >
                Ипотека
              </Checkbox>

              <FRow label="Процент ипотеки">
                <HFSelect
                  loading={ipotekaLoader}
                  control={control}
                  disabled={!watch('ipoteka_boolean')}
                  name="ipoteka"
                  options={ipotekaList?.map((item: any) => ({
                    label: `${item?.percent} %`,
                    value: item?.id,
                  }))}
                />
              </FRow>
            </Flex>
          </Flex>
        </FRow>
      </Flex>

      <Flex className="p-[16px] items-center justify-end" gap={16}>
        <Button
          onClick={() => {
            setMainTagsModal(false)
          }}
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Отменить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(updateTags)()
          }}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>

      <DeleteModal
        deleteApproveModal={deleteApproveModal}
        setDeleteApproveModal={setDeleteApproveModal}
        onOk={() => {
          setValue(`installment_boolean`, false)
          setValue(`installment`, null)
          setValue(`ipoteka_boolean`, false)
          setValue(`ipoteka`, null)
          setDeleteApproveModal(false)
        }}
      />
    </Modal>
  )
}

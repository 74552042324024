import FiltersIcon from '@/components/icons/filters'
import PlusAddIcon from '@/components/icons/plus-icon'
import { Button, Checkbox, Flex, Pagination, Typography } from 'antd'
import { useEffect, useState } from 'react'
import HouseTableRow from './HouseTableRow'

function HouseTablePage({
  setFilterDrawerOpen,
  setAddDrawerOpen,
  data,
  refetch,
  setPage,
  filterCount,
}: any): JSX.Element {
  const [isDarkMode, setIsDarkMode] = useState(false)
  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [localStorage.getItem('theme')])

  return (
    <Flex vertical>
      <Flex className="items-center justify-between">
        <Typography.Title className="m-0 !font-medium !text-2xl flex items-center gap-[12px] dark:!text-[#FFFFFF]">
        Дома комплекса ({data?.count ?? 0})
        </Typography.Title>

        <Flex gap={16}>
          <Button
            onClick={() => {
              setAddDrawerOpen(true)
            }}
            className="border-[#635BFF] h-[48px] dark:text-[#FFF] rounded-[8px] flex gap-[10px] items-center text-[#635BFF] h-auto !font-medium !text-base dark:bg-[#1A1D1F] dark:hover:!bg-[#1A1D1F]"
          >
            <PlusAddIcon />
            Добавить новый дом
          </Button>

          <Button
            className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:hover:!bg-[#1A1D1F] dark:text-[#FFFFFF] dark:border-[#272B30]"
            onClick={() => {
              setFilterDrawerOpen(true)
            }}
          >
            <FiltersIcon
              style={{
                color: isDarkMode ? '#FFF' : '#1E1E1E',
                height: '14px',
                width: '14px',
              }}
            />
            Фильтр
            {filterCount > 0 && (
              <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                {filterCount}
              </Typography.Text>
            )}
          </Button>

          {/* <Button className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:border-[#272B30] dark:hover:!bg-[#1A1D1F] dark:text-[#FFFFFF]">
            <MoreOutlined style={{ color: '#A9A9A9' }} />
          </Button> */}
        </Flex>
      </Flex>

      <Flex vertical className="h-full items-center justify-center" gap={24}>
        <Flex vertical className="w-full mt-[16px]">
          <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Номер дома
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Количество этажей
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Срок сдачи
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Статус
            </Typography.Text>

            <div />
          </Flex>

          {data?.results?.map((house: any) => (
            <HouseTableRow key={house.id} house={house} refetch={refetch} />
          ))}

          <Flex className="mt-[24px]">
            <Pagination
              defaultCurrent={1}
              total={data?.count}
              showSizeChanger={false}
              onChange={(page) => {
                setPage(page)
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HouseTablePage

import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 0C9.3174 0 6.26516 1.26428 4.01472 3.51472C1.76428 5.76516 0.5 8.8174 0.5 12C0.5 15.1826 1.76428 18.2348 4.01472 20.4853C6.26516 22.7357 9.3174 24 12.5 24C15.6826 24 18.7348 22.7357 20.9853 20.4853C23.2357 18.2348 24.5 15.1826 24.5 12C24.5 8.8174 23.2357 5.76516 20.9853 3.51472C18.7348 1.26428 15.6826 0 12.5 0Z"
      fill="#29B6F6"
    />
    <path
      d="M18.4696 6.59951L16.222 18.0751C16.222 18.0751 16.1254 18.5995 15.475 18.5995C15.1294 18.5995 14.9512 18.4351 14.9512 18.4351L10.0828 14.3953L7.70081 13.1947L4.64381 12.3817C4.64381 12.3817 4.09961 12.2245 4.09961 11.7745C4.09961 11.3995 4.65941 11.2207 4.65941 11.2207L17.449 6.13991C17.449 6.13991 17.8396 5.99891 18.1246 5.99951C18.2998 5.99951 18.4996 6.07451 18.4996 6.29951C18.4996 6.44951 18.4696 6.59951 18.4696 6.59951Z"
      fill="white"
    />
    <path
      d="M11.8997 15.9029L9.84411 17.9273C9.84411 17.9273 9.75471 17.9963 9.63531 17.9993C9.59391 18.0005 9.54951 17.9939 9.50391 17.9735L10.0823 14.3945L11.8997 15.9029Z"
      fill="#B0BEC5"
    />
    <path
      d="M16.0374 8.51744C15.936 8.38544 15.7488 8.36144 15.6168 8.46164L7.69922 13.1998C7.69922 13.1998 8.96282 16.735 9.15542 17.347C9.34862 17.9596 9.50342 17.974 9.50342 17.974L10.0818 14.395L15.981 8.93744C16.113 8.83724 16.1376 8.64944 16.0374 8.51744Z"
      fill="#CFD8DC"
    />
  </svg>
)

export default function TelegramColoredIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

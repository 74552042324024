import LayoutStructure from '@/components/LayoutStructure'
import type { CustomRoute } from '@/types'

interface Props {
  children: React.ReactElement
  sidebarRoutes: CustomRoute[]
}

export default function DefaultLayout(props: Props): React.ReactElement {
  const { children, sidebarRoutes } = props

  return <LayoutStructure>{children}</LayoutStructure>
}

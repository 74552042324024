/* eslint-disable @typescript-eslint/restrict-template-expressions */
import request from "@/utils/axios";

export default async function getListClients(params: any): Promise<any> {
    const res: any = await request({
        url: "/building/clients",
        method: "get",
        params
    });

    return res;
}

export async function updateClient(data: any): Promise<any> {
    const res: any = await request({
        url: `/building/client/${data.id}`,
        method: "put",
        data
    });

    return res;
}

export async function patchClient(data: any): Promise<any> {
    const res: any = await request({
        url: `/building/client/${data.id}`,
        method: "patch",
        data
    });

    return res;
}

export async function getApartmentTypes(): Promise<any> {
    const res: any = await request({
        url: "/building/room-count",
        method: "get"
    });

    return res;
}

export async function createComment(data: any): Promise<any> {
    const res: any = await request({
        url: `/building/client-comment`,
        method: "post",
        data
    });

    return res;
}
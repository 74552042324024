import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12418_12421)">
      <path
        d="M1.66797 18.3335H18.3346"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.0013 7.5H11.668C9.59964 7.5 9.16797 7.93167 9.16797 10V18.3333H17.5013V10C17.5013 7.93167 17.0696 7.5 15.0013 7.5Z"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 18.3337H2.5V4.16699C2.5 2.09866 2.93167 1.66699 5 1.66699H10C12.0683 1.66699 12.5 2.09866 12.5 4.16699V7.50033"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 5H5M2.5 8.33333H5M2.5 11.6667H5"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12.5 10.8335H14.1667M12.5 13.3335H14.1667"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.3359 18.3335L13.3359 15.8335"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12418_12421">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default function BuildingIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

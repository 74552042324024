import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6641 6.66602L12.097 7.09589C13.0124 7.37053 13.4702 7.50785 13.7338 7.86215C13.9974 8.21645 13.9974 8.69433 13.9974 9.6501L13.9974 14.666"
      stroke="#A9A9A9"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M5.33203 6L7.33203 6M5.33203 8.66667L7.33203 8.66667"
      stroke="#A9A9A9"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.9974 14.6654L7.9974 12.6654C7.9974 12.0368 7.9974 11.7226 7.80213 11.5273C7.60687 11.332 7.2926 11.332 6.66406 11.332H5.9974C5.36886 11.332 5.05459 11.332 4.85932 11.5273C4.66406 11.7226 4.66406 12.0368 4.66406 12.6654L4.66406 14.6654"
      stroke="#A9A9A9"
      strokeWidth="1.25"
      strokeLinejoin="round"
    />
    <path
      d="M1.33203 14.666H14.6654"
      stroke="#A9A9A9"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M2 14.6654L2 4.47686C2 2.80302 2 1.96611 2.52746 1.55086C3.05491 1.13561 3.83161 1.36106 5.38501 1.81196L8.71835 2.77951C9.65576 3.05161 10.1245 3.18766 10.3956 3.55847C10.6667 3.92928 10.6667 4.43432 10.6667 5.44441L10.6667 14.6654"
      stroke="#A9A9A9"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function JKIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

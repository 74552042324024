import useMessage from '@/hooks/useShowMessage'
import { Flex, Typography } from 'antd'
import { useEffect, useState } from 'react'
import AdvantagesSection from './AdvantagesSection'
import ConvenienceSection from './ConvenienceSection'
import GallerySection from './GallerySection'
import InformationsSection from './InformationsSection'
import LocationSection from './LocationSection'
import MainInformationSection from './MainInformationSection'

function RCPageEdit({ data, refetch }: any): JSX.Element {
  const [isDarkMode, setIsDarkMode] = useState(false)
  const showMessage = useMessage()

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [])

  return (
    <Flex vertical gap={24} className="pb-[30px]">
      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#1A1D1F] dark:border-[#1A1D1F]"
      >
        <Flex className="items-center pb-[24px]">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !m-0 !text-[24px] dark:!text-[#FFF]">
            Иформация о ЖК
          </Typography.Title>
        </Flex>

        <Flex
          vertical
          gap={20}
          className="bg-[#FCFCFD] border-[#E5E7EB] border border-solid rounded-[12px] p-[50px] dark:bg-[#1A1D1F] dark:border-[#1A1D1F]"
        >
          <MainInformationSection
            data={data}
            refetch={refetch}
            showMessage={showMessage}
          />

          <InformationsSection
            isDarkMode={isDarkMode}
            data={data}
            refetch={refetch}
            showMessage={showMessage}
          />

          <GallerySection
            data={data}
            refetch={refetch}
            showMessage={showMessage}
          />

          <LocationSection
            data={data}
            refetch={refetch}
            showMessage={showMessage}
          />

          <ConvenienceSection
            data={data}
            refetch={refetch}
            showMessage={showMessage}
          />

          <AdvantagesSection
            data={data}
            refetch={refetch}
            showMessage={showMessage}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RCPageEdit

/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
import BlurImage from '@/components/BlurImage'
import KubokIcon from '@/components/icons/kubok-icon'
import formatAmount from '@/helpers/formatAmount'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Checkbox, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import { deleteFlat } from '../api'
import FlatsAddDrawer from './FlatsAddDrawer'

function FlatsTableRow({
  flat,
  setSelectedRowIds,
  selectedRowIds,
  refetch,
  watch,
}: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [settingsPriceModal, setSettingsPriceModal] = useState(false)
  const [addDrawerOpen, setAddDrawerOpen] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateDeleteFlat, isLoading: isLoadingDeletingFlat } =
    useMutation({
      mutationFn: deleteFlat,
      onSuccess: () => {
        showMessage('success', 'Кредит успешно удален.')
        refetch()
        setOpen(false)
      },
      onError: () => {
        showMessage('error', 'Ошибка при удалении контакта.')
      },
    })

  return (
    <Flex
      gap={10}
      className="w-full items-center grid grid-cols-[0.5fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]"
    >
      <Flex>
        <Checkbox
          checked={selectedRowIds?.includes(flat?.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRowIds([...selectedRowIds, flat?.id])
            } else {
              setSelectedRowIds(
                selectedRowIds?.filter((item: any) => item !== flat?.id),
              )
            }
          }}
        />
      </Flex>

      {watch('filters.type') === '1' && (
        <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
          {flat?.apartment_type}
        </Typography.Text>
      )}

      <Flex className="cursor-pointer">
        <BlurImage
          src={flat?.image}
          width={68}
          height={68}
          // onClick={() => {
          //   setIsModalOpen(true)
          // }}
        />
      </Flex>

      <Typography.Text className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#E5E7EB] h-max w-max flex items-center dark:bg-[#272B30] dark:text-[#FFF]">
        {flat?.total_space}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#E5E7EB] h-max w-max flex items-center dark:bg-[#272B30] dark:text-[#FFF]">
        {flat?.kitchen_area ?? 0}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
        {formatAmount(flat?.price)} uzs
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
        {flat?.block}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
        {flat?.count} шт.
      </Typography.Text>

      {flat?.special_offer > 0 ? (
        <Flex className="items-center bg-[#FCE7DB] rounded-[14px] p-[6px_10px] gap-[5px] overflow-hidden">
          <KubokIcon />

          <Typography.Text className="font-medium text-sm text-[#1E1E1E] h-max w-max  dark:bg-[#233332]">
            Спецпредложения
          </Typography.Text>
        </Flex>
      ) : (
        <div />
      )}

      <FlatsAddDrawer
        setAddDrawerOpen={setAddDrawerOpen}
        addDrawerOpen={addDrawerOpen}
        refetch={refetch}
        flat={flat}
      />

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            <Button
              type="link"
              onClick={() => {
                setOpen(false)
                setAddDrawerOpen(true)
              }}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-between"
            >
              Изменить
            </Button>
            <Button
              type="link"
              onClick={() => {
                mutateDeleteFlat(flat?.id)
              }}
              loading={isLoadingDeletingFlat}
              className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
            >
              Удалить
            </Button>
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={(val) => {
          setOpen(val)
        }}
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:bg-[#272B30] dark:border-[#272B30]">
          <MoreOutlined />
        </Button>
      </Popover>

      {/* <Modal
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false)
        }}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        footer={null}
        width={500}
      >
        <Flex className="w-full">
          <Image src={flat?.image} preview={false} width="100%" />
        </Flex>
      </Modal> */}
    </Flex>
  )
}

export default FlatsTableRow

import { useQuery } from '@tanstack/react-query'
import { Checkbox, Flex, Pagination, Typography } from 'antd'
import { getListCredits } from '../api'
import TableRow from './TableRow'

function TableBanks({ bankList, watch }: any): JSX.Element {
  const { data: creditList } = useQuery({
    queryKey: ['getListCredits', watch('filter')],
    queryFn: (): any => getListCredits({ ...watch('filter') }),
  })

  return (
    <Flex vertical className="w-full mt-[16px]">
      <Flex className="w-full grid grid-cols-[0.5fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
        <Flex>
          <Checkbox />
        </Flex>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Банки
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Ставка
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Сумма
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Срок
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Ежемесяный платёж
        </Typography.Text>

        <div />
      </Flex>

      {creditList?.results?.map((item: any) => (
        <TableRow key={item.id} item={item} bankList={bankList} />
      ))}

      <Flex className="mt-[24px]">
        <Pagination defaultCurrent={1} total={creditList?.count} />
      </Flex>
    </Flex>
  )
}

export default TableBanks

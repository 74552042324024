import { useAuthContext } from '@/contexts/auth-context';
import { Navigate } from 'react-router-dom';

function AuthRoute({ element: Component, ...rest }: any): JSX.Element {
  const { isAuth } = useAuthContext();

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  return <Component {...rest} />;
}

export default AuthRoute;

import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.168 9.00371C14.168 8.7157 14.168 8.57169 14.2113 8.44331C14.3373 8.07037 14.6695 7.92563 15.0022 7.77407C15.3763 7.60368 15.5633 7.51849 15.7487 7.5035C15.959 7.48649 16.1698 7.53181 16.3496 7.63274C16.588 7.76654 16.7543 8.02078 16.9245 8.22751C17.7107 9.18237 18.1038 9.6598 18.2476 10.1863C18.3637 10.6112 18.3637 11.0555 18.2476 11.4804C18.0378 12.2483 17.375 12.892 16.8845 13.4878C16.6335 13.7926 16.508 13.945 16.3496 14.0339C16.1698 14.1349 15.959 14.1802 15.7487 14.1632C15.5633 14.1482 15.3763 14.063 15.0022 13.8926C14.6695 13.741 14.3373 13.5963 14.2113 13.2234C14.168 13.095 14.168 12.951 14.168 12.663V9.00371Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M5.83594 9.00385C5.83594 8.64114 5.82575 8.31517 5.53253 8.06017C5.42588 7.96741 5.28448 7.90301 5.00169 7.77421C4.62761 7.60382 4.44057 7.51863 4.25524 7.50364C3.6992 7.45867 3.40004 7.83817 3.07938 8.22765C2.29323 9.18251 1.90016 9.65994 1.75632 10.1864C1.64025 10.6113 1.64025 11.0556 1.75632 11.4805C1.96611 12.2484 2.62887 12.8921 3.11945 13.488C3.42868 13.8636 3.72408 14.2063 4.25524 14.1633C4.44057 14.1483 4.62761 14.0631 5.00169 13.8927C5.28448 13.7639 5.42588 13.6995 5.53253 13.6068C5.82575 13.3518 5.83594 13.0258 5.83594 12.6631V9.00385Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M4.16797 7.5C4.16797 4.73858 6.77964 2.5 10.0013 2.5C13.223 2.5 15.8346 4.73858 15.8346 7.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M15.8359 14.1665V14.8332C15.8359 16.3059 14.3436 17.4998 12.5026 17.4998H10.8359"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default function HeadphonesIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12407_18274)">
      <path
        d="M1 8C1 6.83056 1 6.24583 1.26823 5.81894C1.40811 5.59634 1.59634 5.40811 1.81894 5.26823C2.24583 5 2.83056 5 4 5H8C9.16944 5 9.75417 5 10.1811 5.26823C10.4037 5.40811 10.5919 5.59634 10.7318 5.81894C11 6.24583 11 6.83056 11 8C11 9.16944 11 9.75417 10.7318 10.1811C10.5919 10.4037 10.4037 10.5919 10.1811 10.7318C9.75417 11 9.16944 11 8 11H4C2.83056 11 2.24583 11 1.81894 10.7318C1.59634 10.5919 1.40811 10.4037 1.26823 10.1811C1 9.75417 1 9.16944 1 8Z"
        stroke="#A9A9A9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5C10 4.29993 10 3.9499 9.86376 3.68251C9.74392 3.44731 9.55269 3.25608 9.31749 3.13624C9.0501 3 8.70007 3 8 3H4C3.29993 3 2.9499 3 2.68251 3.13624C2.44731 3.25608 2.25608 3.44731 2.13624 3.68251C2 3.9499 2 4.29993 2 5"
        stroke="#A9A9A9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3C9 2.05719 9 1.58579 8.70711 1.29289C8.41421 1 7.94281 1 7 1H5C4.05719 1 3.58579 1 3.29289 1.29289C3 1.58579 3 2.05719 3 3"
        stroke="#A9A9A9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7C7.5 7.55228 7.05228 8 6.5 8H5.5C4.94772 8 4.5 7.55228 4.5 7"
        stroke="#A9A9A9"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_12407_18274">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default function ArchiveIcon2(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} className="w-4 h-4" {...props} />
}

import FiltersIcon from '@/components/icons/filters'
import PlusAddIcon from '@/components/icons/plus-icon'
import { MoreOutlined } from '@ant-design/icons'
import { Button, Flex, Image, Typography } from 'antd'
import { useEffect, useState } from 'react'
import EmptyIllustrator from '../assets/empty.svg'

function HouseEmptyPage({
  setAddDrawerOpen,
  setFilterDrawerOpen,
  filterCount,
}: any): JSX.Element {
  const [isDarkMode, setIsDarkMode] = useState(false)
  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [localStorage.getItem('theme')])

  return (
    <Flex vertical className="h-full">
      <Flex className="items-center justify-between">
        <Typography.Title className="m-0 !font-medium !text-2xl flex items-center gap-[12px] dark:!text-[#FFFFFF]">
          Дома комплекса (0)
        </Typography.Title>

        <Flex gap={16}>
          <Button
            onClick={() => {
              setAddDrawerOpen(true)
            }}
            className="border-[#635BFF] h-[48px] dark:!text-[#FFF] rounded-[8px] flex gap-[10px] items-center text-[#635BFF] h-auto !font-medium !text-base dark:bg-[#1A1D1F] dark:hover:!bg-[#1A1D1F]"
          >
            <PlusAddIcon
              style={{
                color: '#635BFF',
              }}
            />
            Добавить новый дом
          </Button>

          <Button
            className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:hover:!bg-[#1A1D1F] dark:text-[#FFFFFF] dark:border-[#272B30]"
            onClick={() => {
              setFilterDrawerOpen(true)
            }}
          >
            <FiltersIcon
              style={{
                color: isDarkMode ? '#FFF' : '#1E1E1E',
                height: '14px',
                width: '14px',
              }}
            />
            Фильтр
            {filterCount > 0 && (
              <Typography.Text className="!text-[#FFF] !bg-[#1E1E1E] !font-medium !text-[10px] h-[18px] w-[18px] rounded-full !flex items-center justify-center">
                {filterCount}
              </Typography.Text>
            )}
          </Button>

          <Button className="border-[#E5E7EB] rounded-[8px] h-[48px] flex items-center gap-[10px] dark:bg-[#272B30] dark:border-[#272B30] dark:hover:!bg-[#1A1D1F] dark:text-[#FFFFFF]">
            <MoreOutlined style={{ color: '#A9A9A9' }} />
          </Button>
        </Flex>
      </Flex>

      <Flex vertical className="h-full items-center justify-center" gap={24}>
        <Image width={120} src={EmptyIllustrator} preview={false} />

        <Flex vertical className="items-center justify-center">
          <Typography.Title className="!font-medium mb-[16px] m-0 !text-[32px] w-[400px] text-center">
            На данный момент нет блоков комплекса
          </Typography.Title>
          <Typography.Text className="text-[#878787] !font-normal !text-base">
            Добавьте новые блоки в свой жилой комплекс
          </Typography.Text>
        </Flex>

        <Button
          onClick={() => {
            setAddDrawerOpen(true)
          }}
          className="border-[#635BFF] border border-solid rounded-[8px] p-[14px_16px] flex gap-[10px] items-center text-[#635BFF] h-auto !font-medium !text-base"
        >
          <PlusAddIcon
            style={{
              color: '#635BFF',
            }}
          />
          Добавить новый дом
        </Button>
      </Flex>
    </Flex>
  )
}

export default HouseEmptyPage

/* eslint-disable react-hooks/rules-of-hooks */
import settings from '@/config/settings'
import { refreshToken } from '@/features/auth/api'
import type { AxiosError } from 'axios'
import axios from 'axios'

const requestV1 = axios.create({
  baseURL: settings.baseURLV1,
  timeout: settings.requestTimeout,
})

requestV1.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')

  if (token !== null) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, errorHandler)

requestV1.interceptors.response.use((response) => response.data, errorHandler)

export async function errorHandler(error: AxiosError): Promise<void> {
  if (error.response !== null) {
    if (error.response?.status === 401) {
      const rToken = localStorage.getItem('refresh_token')
      if (rToken !== null) {
        try {
          const res = await refreshToken({ refresh: rToken })
          const { refresh, access } = res.data
          localStorage.setItem('refresh_token', refresh)
          localStorage.setItem('access_token', access)
        } catch (err) {
          localStorage.removeItem('refresh_token')
          localStorage.removeItem('access_token')
        } finally {
          window.location.reload()
        }
      } else {
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('access_token')
      }
    }

    await Promise.reject(error.response)
  }
  if (error.request !== null) {
    // no response received from server
    await Promise.reject(error.request)
  }

  // something happened in setting up the request
  console.error(error.message)

  console.log('Error config object:', error.config)

  // Using toJSON you get an object with more information about the HTTP error
  console.log('\nError object as json:', error.toJSON())

  await Promise.reject(error)
}

export default requestV1

import Icon from '@ant-design/icons/lib/components/Icon';
import type { ReactElement } from 'react';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

interface StarFilledIconProps extends Partial<CustomIconComponentProps> {
  width?: string;
  height?: string;
}

const defaultIcon = (width = "20", height = "20"): React.ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11017_58299)">
      <path
        d="M11.439 2.86999L12.9056 5.82723C13.1055 6.2389 13.6388 6.63375 14.0888 6.70937L16.7468 7.15463C18.4466 7.44028 18.8466 8.68366 17.6217 9.91025L15.5553 11.9938C15.2053 12.3466 15.0136 13.0271 15.122 13.5144L15.7136 16.0936C16.1802 18.1351 15.1053 18.9248 13.3138 17.8579L10.8224 16.3708C10.3725 16.102 9.63091 16.102 9.17263 16.3708L6.68124 17.8579C4.8981 18.9248 3.81489 18.1267 4.2815 16.0936L4.87311 13.5144C4.98143 13.0271 4.78978 12.3466 4.43982 11.9938L2.37338 9.91025C1.15685 8.68366 1.54847 7.44028 3.24828 7.15463L5.90632 6.70937C6.34794 6.63375 6.88122 6.2389 7.08119 5.82723L8.5477 2.86999C9.34761 1.26534 10.6475 1.26534 11.439 2.86999Z"
        fill="currentColor"
      />
      <path
        d="M11.439 2.86999L12.9056 5.82723C13.1055 6.2389 13.6388 6.63375 14.0888 6.70937L16.7468 7.15463C18.4466 7.44028 18.8466 8.68366 17.6217 9.91025L15.5553 11.9938C15.2053 12.3466 15.0137 13.0271 15.122 13.5144L15.7136 16.0936C16.1802 18.1351 15.1053 18.9248 13.3138 17.8579L10.8224 16.3708C10.3725 16.102 9.63091 16.102 9.17263 16.3708L6.68124 17.8579C4.8981 18.9248 3.81489 18.1267 4.2815 16.0936L4.87311 13.5144C4.98143 13.0271 4.78978 12.3466 4.43982 11.9938L2.37338 9.91025C1.15685 8.68366 1.54847 7.44028 3.24828 7.15463L5.90632 6.70937C6.34794 6.63375 6.88122 6.2389 7.08119 5.82723L8.5477 2.86999C9.34761 1.26534 10.6475 1.26534 11.439 2.86999Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11017_58299">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default function StarFilledIcon({
  width,
  height,
  ...props
}: StarFilledIconProps): ReactElement {
  return <Icon component={() => defaultIcon(width, height)} {...props} />;
}

import AnalyticsDownIcon from '@/components/icons/analytics-down'
import AnalyticsUpIcon from '@/components/icons/analytics-up'
import HouseIcon from '@/components/icons/house-icon'
import formatAmount from '@/helpers/formatAmount'
import { StarFilled } from '@ant-design/icons'
import { Flex, Skeleton, Typography } from 'antd'

function AnalyticsStatistics({
  statistics,
  data,
  isLoading,
}: {
  statistics: any
  data: any
  isLoading: boolean
}): JSX.Element {
  return (
    <>
      {statistics.map((item: any, index: number) => (
        <Flex
          key={item.title}
          vertical
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          className={`p-[24px] rounded-[12px] border border-[#E5E7EB] border-solid bg-[#FFF] overflow-hidden dark:bg-[#1A1D1F] dark:border-[#272B30] div${item.id}`}
        >
          {isLoading ? (
            <Skeleton active />
          ) : (
            <>
              <Flex className="w-[32px] h-[32px] items-center justify-center">
                {item.icon}
              </Flex>

              <Flex vertical className="mt-[24px] mb-[16px] overflow-hidden">
                <Typography.Text className="text-[#878787] font-normal text-sm mb-[8px] text-nowrap ">
                  {item.title}
                </Typography.Text>

                <Typography.Text className="text-[#1E1E1E] font-medium text-[32px] dark:text-[#FFFFFF] leading-9">
                  {item.value}
                </Typography.Text>
              </Flex>

              {/* <Flex
                gap={8}
                className="no-wrap items-center statistic-row overflow-hidden"
              >
                <Typography.Text className="text-[#1E1E1E] font-medium text-sm text-nowrap dark:text-[#FFFFFF]">
                  {formatAmount(item.lastMonthValue)}
                </Typography.Text>

                <Typography.Text className="text-[#878787] font-normal text-sm text-nowrap">
                  чем в прошлом месяце
                </Typography.Text>

                {item.value > item.lastMonthValue ? (
                  <AnalyticsUpIcon />
                ) : (
                  <AnalyticsDownIcon />
                )}

                <Typography.Text
                  className={`text-sm font-medium text-nowrap ${
                    item.value > item.lastMonthValue
                      ? 'text-[#58C27D]'
                      : 'text-[#CE5A67]'
                  }`}
                >
                  {item.percent}%
                </Typography.Text>
              </Flex> */}
            </>
          )}
        </Flex>
      ))}

      <Flex
        vertical
        className="p-[24px] rounded-[12px] border border-[#E5E7EB] border-solid bg-[#FFF] overflow-hidden dark:bg-[#1A1D1F] dark:border-[#272B30] div4"
      >
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <HouseIcon
              style={{
                color: '#A9A9A9',
                fontSize: '22px',
                width: '22px',
                height: '22px',
              }}
            />

            <Flex vertical className="mt-[24px] mb-[16px]">
              <Typography.Text className="text-[#878787] font-normal text-sm mb-[8px] overflow-hidden">
                Средний рейтинг комплекса
              </Typography.Text>

              <Typography.Text className="text-[#1E1E1E] font-medium text-[32px] dark:text-[#FFF]">
                {data?.counts?.rating}
              </Typography.Text>
            </Flex>

            {/* <Flex gap={8} className="no-wrap items-center">
              <StarFilled style={{ color: '#FFC107' }} />

              <Typography.Text className="text-[#878787] font-normal text-sm text-nowrap overflow-hidden">
                На основе более чем 700 отзывов
              </Typography.Text>
            </Flex> */}
          </>
        )}
      </Flex>
    </>
  )
}

export default AnalyticsStatistics

import { Input, Typography } from 'antd';
import { Controller } from 'react-hook-form';

// Helper function for basic transliteration
const transliterateToLatin = (text: string) => {
  const cyrillicToLatinMap: { [key: string]: string } = {
    А: 'A', Б: 'B', В: 'V', Г: 'G', Д: 'D', Е: 'E', Ё: 'Yo', Ж: 'Zh',
    З: 'Z', И: 'I', Й: 'Y', К: 'K', Л: 'L', М: 'M', Н: 'N', О: 'O',
    П: 'P', Р: 'R', С: 'S', Т: 'T', У: 'U', Ф: 'F', Х: 'Kh', Ц: 'Ts',
    Ч: 'Ch', Ш: 'Sh', Щ: 'Sch', Ъ: '', Ы: 'Y', Ь: '', Э: 'E', Ю: 'Yu', Я: 'Ya',
    а: 'a', б: 'b', в: 'v', г: 'g', д: 'd', е: 'e', ё: 'yo', ж: 'zh',
    з: 'z', и: 'i', й: 'y', к: 'k', л: 'l', м: 'm', н: 'n', о: 'o',
    п: 'p', р: 'r', с: 's', т: 't', у: 'u', ф: 'f', х: 'kh', ц: 'ts',
    ч: 'ch', ш: 'sh', щ: 'sch', ъ: '', ы: 'y', ь: '', э: 'e', ю: 'yu', я: 'ya'
  };

  return text.split('').map(char => cyrillicToLatinMap[char] || char).join('');
};

function HFInput({
  control,
  name = '',
  disabledHelperText = false,
  required = false,
  rules = {},
  allowSpace = true,  // Controls allowing spaces
  onlyLatin = false,  // Controls transliteration to Latin
  ...props
}: any): JSX.Element {
  const handleInputChange =
    (onChange: (value: string) => void) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      
      // Remove spaces if allowSpace is false
      if (!allowSpace) {
        value = value.replace(/\s+/g, '');
      }
      
      // Convert to Latin if onlyLatin is true
      if (onlyLatin) {
        value = transliterateToLatin(value);
      }

      onChange(value);
    };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: required ? 'Это объязательная поля!' : false,
        ...rules,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Input
            value={value}
            onChange={handleInputChange(onChange)}
            status={error ? 'error' : 'success'}
            className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#1E1E1E] text-sm font-normal dark:bg-[#272B30] dark:border-[#272B30] hover:dark:bg-[#272B30] focus:dark:bg-[#272B30]"
            {...props}
          />
          {error && !disabledHelperText && (
            <Typography.Text type="danger">{error.message}</Typography.Text>
          )}
        </>
      )}
    />
  );
}

export default HFInput;

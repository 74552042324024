import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import { CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Drawer, Flex, Typography } from 'antd'
import { getListDeadlines, getListFloor } from '../api'

function HouseFilterDrawer({
  setFilterDrawerOpen,
  filterDrawerOpen,
  control,
  unregister,
  reset,
}: any): JSX.Element {
  const { data: floorOptions } = useQuery({
    queryKey: ['floorOptions'],
    queryFn: getListFloor,
  })

  const { data: deadlineOptions } = useQuery({
    queryKey: ['deadlineOptions'],
    queryFn: getListDeadlines,
  })

  return (
    <Drawer
      onClose={() => {
        setFilterDrawerOpen(false)
      }}
      open={filterDrawerOpen}
      headerStyle={{
        display: 'none',
      }}
    >
      <Flex vertical className="w-full h-full justify-between">
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] dark:border-[#272B30] border-x-0 border-t-0 w-full items-center justify-between p-[16px]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0 dark:!text-[#FFF]">
              Фильтр
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setFilterDrawerOpen(false)
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Количество этажей">
              <HFSelect
                control={control}
                name="filter.floor_count"
                allowClear
                options={floorOptions?.map((item: any) => ({
                  label: item.floor_count,
                  value: item.floor_count,
                }))}
              />
            </FRow>

            <FRow label="Срок сдачи">
              <HFSelect
                control={control}
                allowClear
                name="filter.deadline"
                options={deadlineOptions?.map((item: any) => ({
                  label: item,
                  value: item,
                }))}
              />
            </FRow>

            <FRow label="Статус">
              <HFSelect
                control={control}
                allowClear
                name="filter.status"
                options={[
                  { label: 'Активный', value: 'active' },
                  { label: 'Неактивный', value: 'inactive' },
                  { label: 'Ожидание', value: 'waiting' },
                ]}
              />
            </FRow>
          </Flex>
        </Flex>

        <Flex className="items-center justify-end p-[24px]" gap={14}>
          <Button
            onClick={() => {
              setFilterDrawerOpen(false)
              reset()
              unregister('filter')
            }}
            className="w-full bg-[#FFFFFF] p-[10px_24px] text-[#1E1E1E] dark:text-[#777E90] dark:bg-[#272B30] dark:border-[#272B30] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
          >
            Сбросить
          </Button>

          <Button
            onClick={() => {
              setFilterDrawerOpen(false)
            }}
            className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default HouseFilterDrawer

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-floating-promises */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import useMessage from '@/hooks/useShowMessage'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import type { TabsProps } from 'antd'
import { Button, Flex, Modal, Tabs, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { updatePrice } from '../api'
import FlatsEmptyPage from './FlatsEmptyPage'
import FlatsSettingsPriceModalTableRow from './FlatsSettingsPriceModalTableRow'

export default function FlatsSettingsPriceModal({
  settingsPriceModal,
  setSettingsPriceModal,
  data,
  refetch,
}: any): JSX.Element {
  const [activeTab, setActiveTab] = useState('1')
  const { control, reset, watch, setValue } = useForm()

  useEffect(() => {
    reset(data)
  }, [data])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Процентное повышение',
      children: (
        <FirstTab
          data={data}
          activeTab={activeTab}
          setSettingsPriceModal={setSettingsPriceModal}
          control={control}
          watch={watch}
          setValue={setValue}
          refetch={refetch}
        />
      ),
    },
    {
      key: '2',
      label: 'Фиксированное повышение',
      children: (
        <SecondTab
          data={data}
          activeTab={activeTab}
          setSettingsPriceModal={setSettingsPriceModal}
          control={control}
          watch={watch}
          setValue={setValue}
          refetch={refetch}
        />
      ),
    },
  ]

  return (
    <Modal
      open={settingsPriceModal}
      onOk={() => {
        setSettingsPriceModal(false)
      }}
      onCancel={() => {
        setSettingsPriceModal(false)
      }}
      footer={null}
      width="70%"
      title={
        <Typography.Title className="text-[#1E1E1E] m-0 !font-medium !text-[32px] dark:!text-[#FFFFFF]">
          Настройка цен
        </Typography.Title>
      }
    >
      <Flex className="w-full">
        <Tabs
          defaultActiveKey="1"
          onChange={(key) => {
            setActiveTab(key)
          }}
          items={items}
          style={{
            width: '100%',
          }}
        />
      </Flex>
    </Modal>
  )
}

function FirstTab({
  data,
  activeTab,
  setSettingsPriceModal,
  control: mainControl,
  watch: mainWatch,
  setValue: mainSetValue,
  refetch,
}: any): JSX.Element {
  const { control, watch, setValue, reset } = useForm()

  return (
    <Flex vertical>
      <Flex className="p-[24px_0px_16px_0px] w-[40%]">
        <FRow label="Процентное повышение">
          <HFInput
            control={control}
            name="percent"
            suffix="%"
            onChange={(e: any): any => {
              // Regex to allow numbers, decimals, and optional minus sign
              const cleanValue = e.target.value.replace(/[^-?\d.]/g, '')

              // Update the percent field with the cleaned value
              setValue('percent', cleanValue)

              // Convert the cleaned value to a number for calculations
              const percentValue = parseFloat(cleanValue)

              if (!isNaN(percentValue) && data?.results) {
                // Recalculate prices based on the percentage
                const prices = data.results.map((item: any) => ({
                  id: item?.id,
                  price: Math.round(
                    item?.price + (item?.price * percentValue) / 100,
                  ),
                }))

                // Update the apartments with the new prices
                mainSetValue('apartments', prices)
              }
            }}
          />
        </FRow>
      </Flex>

      <TableWithNewPrice
        data={data}
        activeTab={activeTab}
        difference={watch('percent')}
        setSettingsPriceModal={setSettingsPriceModal}
        mainControl={mainControl}
        mainWatch={mainWatch}
        mainSetValue={mainSetValue}
        refetch={refetch}
        resetInput={reset}
      />
    </Flex>
  )
}

function SecondTab({
  data,
  activeTab,
  setSettingsPriceModal,
  control: mainControl,
  watch: mainWatch,
  setValue: mainSetValue,
  refetch,
}: any): JSX.Element {
  const { control, watch, setValue, reset } = useForm()

  const formatNumberWithSpaces = (
    value: string | number | null | undefined,
  ): string => {
    return String(value || '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  const removeSpaces = (value: string | null | undefined): string => {
    return String(value || '').replace(/\s/g, '')
  }

  return (
    <Flex vertical>
      <Flex className="p-[24px_0px_16px_0px] w-[40%]">
        <FRow label="Фиксированное повышение">
          <HFInput
            control={control}
            name="summ"
            suffix="UZS"
            value={formatNumberWithSpaces(watch('summ'))}
            onChange={(e: any): any => {
              const { value: inputValue } = e.target
              const cleanValue = removeSpaces(inputValue)
              const reg = /^-?\d*$/ // Regex to allow only numbers (with optional leading minus sign)

              if (
                reg.test(cleanValue) ||
                cleanValue === '' ||
                cleanValue === '-'
              ) {
                setValue('summ', cleanValue)
              }

              const prices = data?.results?.map((item: any) => ({
                id: item?.id,
                price: item?.price + parseFloat(cleanValue),
              }))
              mainSetValue('apartments', prices)
            }}
          />
        </FRow>
      </Flex>

      <TableWithNewPrice
        data={data}
        activeTab={activeTab}
        watch={watch('summ')}
        setSettingsPriceModal={setSettingsPriceModal}
        mainControl={mainControl}
        mainWatch={mainWatch}
        mainSetValue={mainSetValue}
        refetch={refetch}
        resetInput={reset}
      />
    </Flex>
  )
}

function TableWithNewPrice({
  data,
  activeTab,
  difference,
  setSettingsPriceModal,
  mainWatch,
  mainSetValue,
  refetch,
  resetInput,
}: any): JSX.Element {
  const [visible, setVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate, isLoading } = useMutation({
    mutationFn: updatePrice,
    onSuccess: () => {
      showMessage('success', 'Цены успешно обновлены.')
      setSettingsPriceModal(false)
      refetch()
      resetInput()
    },
    onError: () => {
      showMessage('error', 'Ошибка при обновлении цен.')
    },
  })

  const handleSave = (): void => {
    if (mainWatch('apartments')?.length > 0) {
      mutate({
        apartments: mainWatch('apartments').filter(
          (item: any) =>
            item.price !== null &&
            item.price !== '' &&
            item.price !== 0 &&
            item.price !== '0' &&
            !isNaN(item.price),
        ),
      })
    } else {
      showMessage('info', 'Нет данных для сохранения.')
      setSettingsPriceModal(false)
      resetInput()
    }
  }

  return (
    <Flex className="w-full" vertical>
      <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Тип планировки
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Общая пложадь
        </Typography.Text>

        <Typography.Text className="text-[#A9A9A9] font-normal text-base">
          Текущая Цена за м2
        </Typography.Text>

        <Flex className="items-center">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Новая Цена за м2
          </Typography.Text>

          {/* <Button
            type="link"
            onClick={() => {
              setVisible(!visible)
            }}
          >
            {visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </Button> */}
        </Flex>

        <div />
      </Flex>

      {data?.results?.length > 0 ? (
        data?.results?.map((item: any) => (
          <FlatsSettingsPriceModalTableRow
            item={item}
            key={item?.id}
            defaultVisible={visible}
            activeTab={activeTab}
            difference={difference}
            mainWatch={mainWatch}
            mainSetValue={mainSetValue}
          />
        ))
      ) : (
        <FlatsEmptyPage setAddDrawerOpen={null} />
      )}

      <Flex gap={22} className="w-full items-center justify-end p-[24px] pb-0">
        <Button
          onClick={() => {
            setSettingsPriceModal(false)
            resetInput()
          }}
          className="w-max bg-[#FFFFFF] dark:text-[#777E90] dark:bg-[#272B30] dark:border-[#272B30] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
        >
          Отменить
        </Button>
        <Button
          onClick={() => {
            handleSave()
          }}
          loading={isLoading}
          className="w-max bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
        >
          Сохранить
        </Button>
      </Flex>
    </Flex>
  )
}

/* eslint-disable @tanstack/query/no-deprecated-options */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import BlurImage from '@/components/BlurImage'
import HFInput from '@/components/FormElements/HFInput'
import HFNumberInput from '@/components/FormElements/HFNumericInput'
import HFRadio from '@/components/FormElements/HFRadio'
import FRow from '@/components/FormElements/HFRow'
import HFSelect from '@/components/FormElements/HFSelect'
import { getApartmentTypes } from '@/features/clients/api'
import queryClient from '@/utils/query-client'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Checkbox, Drawer, Flex, Spin, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import getListBlocks, { createFlat, flatGetById, updateFlat } from '../api'
import GalleryImage from '../assets/gallery.svg'

function FlatsAddDrawer({
  setAddDrawerOpen,
  addDrawerOpen,
  refetch,
  flat = null,
  initialType = '1',
}: any): JSX.Element {
  const { control, reset, setValue, watch, getValues, handleSubmit } = useForm({
    defaultValues: {
      type: initialType,
      on_full_payment: true,
    },
  })
  const [currenctCurrency, setCurrentCurrency] = useState()
  const [additionalLodaer, setAdditionalLoader] = useState(false)

  useEffect(() => {
    if (!watch('special_offer_visible')) {
      setValue('special_offer', null)
    }
  }, [watch('special_offer_visible')])

  useEffect(() => {
    if (watch('special_offer') > 0) {
      setValue('special_offer_visible', true)
    }
  }, [watch('special_offer')])

  useEffect(() => {
    if (addDrawerOpen) {
      fetch('https://cbu.uz/ru/arkhiv-kursov-valyut/json/').then((res) =>
        res.json().then((data) => {
          setCurrentCurrency(
            data?.find((item: any) => item.Ccy === 'USD')?.Rate,
          )
        }),
      )
    }
  }, [addDrawerOpen])

  const {
    data,
    isLoading,
    refetch: refetchById,
  } = useQuery({
    queryKey: ['getByIdFlat', flat?.id, addDrawerOpen],
    queryFn: () => flatGetById(flat?.id),
    enabled: !!flat?.id && addDrawerOpen,
    onSuccess: (data) => {
      if (data) {
        reset({
          ...data,
          id: flat?.id,
        })
      }
    },
  })

  useEffect(() => {
    if (addDrawerOpen && flat?.id) {
      refetchById()
    }
  }, [flat?.id, addDrawerOpen])

  const inputClick = (): void => {
    ;(document.querySelector('input[type="file"]') as HTMLInputElement)?.click()

    document
      .querySelector('input[type="file"]')
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]
        if (file) {
          setValue('image', file)
        }
      })
  }

  const watchingImage = useWatch({
    control,
    name: 'image',
  })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = useMemo(() => {
    if (watchingImage) {
      return isValidURL(watchingImage)
        ? watchingImage
        : URL.createObjectURL(watchingImage)
    }
    return false
  }, [watchingImage])

  const { data: apartmenTypeList } = useQuery({
    queryKey: ['getApartmentTypes'],
    queryFn: getApartmentTypes,
  })

  const { data: blockList } = useQuery({
    queryKey: ['blocks', watch('filter')],
    queryFn: (): any => getListBlocks({}),
  })

  const { mutate: mutateCreateFlat, isLoading: isLoadingCreatingFlat } =
    useMutation({
      mutationFn: createFlat,
      onSuccess: () => {
        refetch()
        setAddDrawerOpen(false)
        reset({})
      },
      onSettled: () => {
        setAdditionalLoader(false)
      },
    })

  const { mutate: mutateUpdateFlat, isLoading: isLoadingUpdateFlat } =
    useMutation({
      mutationFn: updateFlat,
      onSuccess: () => {
        refetch()
        setAddDrawerOpen(false)
        reset({})
        queryClient.invalidateQueries(['getByIdFlat', flat?.id])
        queryClient.invalidateQueries(['apartments'])
      },
      onSettled: () => {
        setAdditionalLoader(false)
      },
    })

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url)
    const blob = await response.blob()
    const urlParts = url.split('/')
    const filename = urlParts[urlParts.length - 1]
    const mimeType =
      response.headers.get('content-type') ?? 'application/octet-stream'
    const file = new File([blob], filename, { type: mimeType })
    return file
  }

  const handleSave = async () => {
    setAdditionalLoader(true)
    if (flat) {
      let imageFile = watchingImage
      if (isValidURL(watchingImage)) {
        imageFile = await urlToFile(watchingImage)
      }

      mutateUpdateFlat({
        ...getValues(),
        image: imageFile,
      })
    } else {
      let imageFile = watchingImage
      if (isValidURL(watchingImage)) {
        imageFile = await urlToFile(watchingImage)
      }

      mutateCreateFlat({
        ...getValues(),
        apartment_type: watch('type') === '1' ? watch('apartment_type') : null,
        image: imageFile,
      })
    }
  }

  return (
    <Drawer
      onClose={() => {
        setAddDrawerOpen(false)
      }}
      open={addDrawerOpen}
      headerStyle={{
        display: 'none',
      }}
      width={470}
    >
      <Flex vertical className="w-full h-full justify-between relative">
        {flat?.id && isLoading && (
          <Flex className="w-full h-full absolute items-center justify-center bg-[#FFFFFFB4] z-10">
            <Spin size="large" spinning={isLoading} />
          </Flex>
        )}
        <Flex vertical className="w-full">
          <Flex className="border-b-1 border-solid border-[#E5E7EB] border-x-0 border-t-0 w-full items-center justify-between p-[16px] dark:border-[#272B30]">
            <Typography.Title className="!text-[#1E1E1E] !text-[24px] !font-medium !m-0 dark:!text-[#FFF]">
              {flat ? 'Редактировать планировку' : 'Добавить помещение'}
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setAddDrawerOpen(false)
                reset({})
              }}
              className="text-[#1E1E1E] w-[48px] h-[48px] rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] dark:bg-[#272B30] dark:text-[#A9A9A9]"
            >
              <CloseOutlined />
            </Button>
          </Flex>

          <Flex vertical gap={24} className="w-full p-[24px]">
            <FRow label="Тип помещения">
              <HFSelect
                control={control}
                required
                name="type"
                options={[
                  {
                    label: 'Жилое',
                    value: '1',
                  },
                  {
                    label: 'Коммерческое',
                    value: '2',
                  },
                ]}
              />
            </FRow>

            <input type="file" hidden />

            <FRow label="Добавить фотографи планировки">
              <Flex className="border-[#E5E7EB] border-1 border-dashed rounded-[12px] dark:border-[#2F3336] h-[250px] overflow-hidden">
                {imageUrlGenerator ? (
                  <Flex className="items-center justify-center w-full h-full relative">
                    <BlurImage
                      src={imageUrlGenerator}
                      preview={false}
                      className="object-cover h-full w-full"
                    />

                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        setValue('image', null)
                      }}
                      className="text-[#1E1E1E] w-[35px] h-[35px] flex items-center justify-center rounded-full bg-[#F1F2F4] hover:!bg-[#F1F2F4] absolute top-[12px] right-[12px]"
                    >
                      <CloseOutlined />
                    </Button>
                  </Flex>
                ) : (
                  <Flex
                    vertical
                    className="items-center justify-center min-h-[200px] w-full cursor-pointer"
                    onClick={inputClick}
                  >
                    <BlurImage width={70} src={GalleryImage} preview={false} />
                    <Typography.Text className="!text-[#1E1E1E] !font-normal text-center mt-[20px] mb-[4px] !text-base dark:!text-[#878787]">
                      Выберите или перетащите фотографию планировки
                    </Typography.Text>
                    <Typography.Text className="!text-[#878787] !font-normal !text-sm">
                      Максимальный размер: 2 МБ
                    </Typography.Text>
                  </Flex>
                )}
              </Flex>
            </FRow>

            {watch('type') === '1' && (
              <>
                <FRow label="Тип планировки">
                  <HFSelect
                    control={control}
                    required={watch('type') === '1'}
                    name="apartment_type"
                    options={[
                      {
                        label: 'Квартира',
                        value: 1,
                      },
                      {
                        label: 'Студия',
                        value: 2,
                      },
                    ]}
                  />
                </FRow>

                <FRow label="Количество комнат">
                  <HFSelect
                    control={control}
                    required={watch('type') === '1'}
                    name="room_count"
                    options={apartmenTypeList?.map((item: any) => ({
                      label: item.title,
                      value: item.id,
                    }))}
                  />
                </FRow>
              </>
            )}

            <FRow label="Номер дома">
              <HFSelect
                required
                control={control}
                name="block"
                options={blockList?.results?.map((item: any) => ({
                  label: item.title,
                  value: item.id,
                }))}
              />
            </FRow>

            <FRow label="Общая площадь">
              <HFNumberInput
                control={control}
                name="total_space"
                suffix="м2"
                required
              />
            </FRow>

            {watch('type') === '1' ? (
              <FRow label="Площадь кухни">
                <HFNumberInput
                  control={control}
                  name="kitchen_area"
                  suffix="м2"
                  required
                />
              </FRow>
            ) : (
              <FRow label="Полезная площадь">
                <HFNumberInput
                  control={control}
                  name="effective_area"
                  suffix="м2"
                  required
                />
              </FRow>
            )}

            <FRow
              label={
                watch('type') === '1'
                  ? 'Количество квартир'
                  : 'Количество помещений'
              }
            >
              <HFInput control={control} name="count" required />
            </FRow>

            <FRow label="Цена за м2">
              <HFNumberInput
                control={control}
                name="price"
                suffix="UZS"
                required
              />
            </FRow>

            <Flex>
              <Typography.Text className="text-[#1E1E1E] font-normal text-base">
                $ ≈{' '}
                {isNaN(watch('price'))
                  ? 0
                  : parseFloat(
                      (
                        parseInt(watch('price')) / parseFloat(currenctCurrency)
                      ).toFixed(1),
                    ) || 0}
              </Typography.Text>
            </Flex>

            <FRow label="Выберите теги">
              <Checkbox
                checked={!!watch('special_offer_visible')}
                onChange={(e) => {
                  setValue('special_offer_visible', e.target.checked)
                }}
              >
                Спецпредложения
              </Checkbox>
            </FRow>

            {watch('special_offer_visible') && (
              <>
                <FRow label="Скидка специального предложения">
                  <HFNumberInput
                    control={control}
                    name="special_offer"
                    suffix="%"
                  />
                </FRow>

                <FRow label="Тип специального предложения">
                  <HFRadio
                    control={control}
                    name="on_full_payment"
                    label="При полной оплате"
                    checked
                  />
                </FRow>
              </>
            )}
          </Flex>
        </Flex>

        <Flex gap={22} className="w-full p-[24px]">
          <Button
            onClick={() => {
              setAddDrawerOpen(false)
              reset({})
            }}
            className="w-full bg-[#FFFFFF] dark:text-[#777E90] dark:bg-[#272B30] dark:border-[#272B30] p-[10px_24px] text-[#CE5A67] font-normal text-base border-[#E5E7EB] rounded-[8px] h-auto flex items-center justify-center"
          >
            Отменить
          </Button>
          <Button
            onClick={() => {
              handleSubmit(handleSave)()
            }}
            loading={
              isLoadingCreatingFlat || isLoadingUpdateFlat || additionalLodaer
            }
            className="w-full bg-[#635BFF] p-[10px_24px] text-[#FFFFFF] font-normal text-base rounded-[8px] h-auto flex items-center justify-center"
          >
            Сохранить
          </Button>
        </Flex>
      </Flex>
    </Drawer>
  )
}

export default FlatsAddDrawer

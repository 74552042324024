import request from "@/utils/axios";

export default async function getListContacts(): Promise<any> {
    const res: any = await request({
        url: "/building/contact",
        method: "get"
    });

    return res;
}

export async function createContact(data: any): Promise<any> {
    const res: any = await request({
        url: "/building/contact",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });

    return res;
}

export async function updateContact(data: any): Promise<any> {
    const res: any = await request({
        url: `/building/contact/${data.id}`,
        method: "put",
        data,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });

    return res;
}

export async function deleteContact(id: number): Promise<any> {
    const res: any = await request({
        url: `/building/contact/${id}`,
        method: "delete",
    });

    return res;
}

export async function getContact(id: number): Promise<any> {
    const res: any = await request({
        url: `/building/contact/${id}`,
        method: "get"
    });

    return res;
}

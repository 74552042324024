import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFTextarea from '@/components/FormElements/HFTextarea'
import GalleryIcon from '@/components/icons/gallery-icon'
import { HomeOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Divider, Flex, Modal, Typography } from 'antd'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps'

function Account(): JSX.Element {
  const { control, watch, setValue } = useForm()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Flex
      vertical
      className="bg-[#FCFCFD] h-full w-full p-[24px] dark:bg-[#111315]"
    >
      <Breadcrumb
        items={[
          {
            href: '',
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <HomeOutlined />
                <span className="font-normal text-sm text-[#A9A9A9]">
                  Главная
                </span>
              </Flex>
            ),
          },
          {
            title: (
              <Flex gap={10} className="items-center dark:text-[#A9A9A9]">
                <span className="font-normal text-sm text-[#1E1E1E] dark:text-[#FFFFFF]">
                  Аккаунт
                </span>
              </Flex>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] mt-[32px] dark:bg-[#1A1D1F] dark:border-[#272B30]"
      >
        <Flex className="items-center justify-between w-full pb-[24px]">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0 dark:!text-[#FFF]">
            Информация о застройщике
          </Typography.Title>
        </Flex>

        <Flex className="w-full border-t-[1px] border-x-0 border-b-0 border-solid border-[#E5E7EB] py-[24px]">
          {/* <Flex className="w-full">
            <FRow label="Вы среди действующих строителей?">
              <HFSelect
                name="builder"
                control={control}
                options={[
                  {
                    label: 'Да',
                    value: 'yes',
                  },
                  {
                    label: 'Нет',
                    value: 'no',
                  },
                ]}
              />
            </FRow>
          </Flex> */}

          <Flex vertical gap={24} className="w-full">
            <FRow label="Добавить логотип">
              <Flex gap={20} className="items-center">
                <Flex className="rounded-full border-[1.5px] border-[#E5E7EB] border-dashed w-[100px] h-[100px] items-center justify-center">
                  <GalleryIcon />
                </Flex>

                <Flex vertical gap={11}>
                  <Flex gap={22}>
                    <Button className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto">
                      Добавить
                    </Button>

                    <Button className="border-[#E5E7EB] bg-[#FFFFFF] text-[#CE5A67] text-base font-medium p-[10px_24px] rounded-[8px] h-auto">
                      Удалить
                    </Button>
                  </Flex>

                  <Typography.Text className="text-[#878787] text-base">
                    Ваше изображение должно быть квадратным, размером не менее
                    100x100 пикселей и в формате JPG или PNG.
                  </Typography.Text>
                </Flex>
              </Flex>
            </FRow>

            <FRow label="Название">
              <HFInput
                name="builder"
                control={control}
                placeholder="Например: Golden House"
              />
            </FRow>

            <Flex gap={24}>
              <FRow label="Год основания">
                <HFInput
                  name="builder"
                  control={control}
                  placeholder="Например: 2018"
                />
              </FRow>
              <FRow label="Сданные дома">
                <HFInput
                  name="builder"
                  control={control}
                  placeholder="Например: 10"
                />
              </FRow>
              <FRow label="Строящиеся дома">
                <HFInput
                  name="builder"
                  control={control}
                  placeholder="Например: 10"
                />
              </FRow>
            </Flex>

            <Divider className="m-0" />

            <FRow label="О застройщике (UZ)">
              <HFTextarea
                name="builder"
                control={control}
                placeholder="Напишите о себе"
                rows="5"
              />
            </FRow>

            <FRow label="О застройщике (RU)">
              <HFTextarea
                name="builder"
                control={control}
                placeholder="Напишите о себе"
                rows="5"
              />
            </FRow>

            <Divider className="m-0" />

            <Typography.Text className="text-[#1E1E1E] !font-medium !text-xl">
              Адрес
            </Typography.Text>

            <FRow label="Расположение">
              <HFInput
                control={control}
                name="address"
                placeholder="Введите адрес"
                suffix={
                  <Button
                    type="link"
                    onClick={() => {
                      setIsModalOpen(true)
                    }}
                    className="text-[#635BFF] !font-medium !text-base p-0"
                  >
                    На карте
                  </Button>
                }
              />
            </FRow>

            <Modal
              open={isModalOpen}
              onCancel={() => {
                setIsModalOpen(false)
              }}
              closeIcon={null}
              footer={null}
              className="search-modal"
            >
              <Flex className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid">
                <YMaps>
                  <Map
                    onClick={(e: any) => {
                      const coords = e.get('coords')

                      setValue(`latitude`, coords[0])
                      setValue(`longitude`, coords[1])
                    }}
                    width="100%"
                    height="500px"
                    defaultState={{ center: [41.311081, 69.240562], zoom: 12 }}
                  >
                    <ZoomControl />
                    <Placemark
                      geometry={[watch(`latitude`), watch(`longitude`)]}
                    />
                  </Map>
                </YMaps>
              </Flex>
            </Modal>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Account

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from '@/utils/axios'
import requestV1 from '@/utils/axiosForV1'

export default async function getInformationSupport(): Promise<any> {
  const res: any = await requestV1({
    url: '/preferences/preferences/',
    method: 'get',
  })

  return res
}

export async function getListInstructions(): Promise<any> {
  const res: any = await request({
    url: '/base/video-infos',
    method: 'get',
  })

  return res
}

/* eslint-disable @typescript-eslint/no-floating-promises */
import BlurImage from '@/components/BlurImage'
import AlertIcon from '@/components/icons/alert-icon'
import ArchiveIcon2 from '@/components/icons/archive-icon1'
import FlashIcon from '@/components/icons/flash-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import WaitingIcon from '@/components/icons/waiting-icon'
import useMessage from '@/hooks/useShowMessage'
import queryClient from '@/utils/query-client'
import { CloseOutlined, MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  Popover,
  Typography,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { createComment, updateClient } from '../api'

function TableRow({
  client,
  refetchList,
  selectedRowIds,
  setSelectedRowIds,
}: any): JSX.Element {
  const [open, setOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false)
  const [isOpenCreateCommentModal, setIsOpenCreateCommentModal] =
    useState(false)
  const [value, setValue] = useState('')
  const showMessage = useMessage()

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen)
  }

  const statusGenerator = useMemo((): {
    color: string
    bg: string
    icon: any
    text: string
  } => {
    if (client?.status === 1) {
      return {
        color: '#58C27D',
        bg: '#DEF3E5',
        icon: FlashIcon,
        text: 'Новый',
      }
    }
    if (client?.status === 2) {
      return {
        color: '#FA8F54',
        bg: '#FCE7DB',
        icon: WaitingIcon,
        text: 'Обработано',
      }
    }
    if (client?.status === 3) {
      return {
        color: '#CE5A67',
        bg: '#F3DCDF',
        icon: AlertIcon,
        text: 'Не обработано',
      }
    }
    if (client?.status === 4) {
      return {
        color: '#A9A9A9',
        bg: '#F5F5F5',
        icon: ArchiveIcon2,
        text: 'Архивировано',
      }
    }
    return {
      color: '#A9A9A9',
      bg: '#F5F5F5',
      icon: FlashIcon,
      text: 'Undefined',
    }
  }, [client])

  const { mutate: mutateUpdateClient1, isLoading: isLoadingUpdate1 } =
    useMutation({
      mutationFn: updateClient,
      onSuccess: () => {
        showMessage('success', 'Клиент успешно обновлен.')
        handleOpenChange(false)
        queryClient.invalidateQueries(['getListClients'])
        refetchList()
      },
      onError: () => {
        showMessage('error', 'Ошибка при обновлении клиента.')
      },
    })

  const { mutate: mutateUpdateClient2, isLoading: isLoadingUpdate2 } =
    useMutation({
      mutationFn: updateClient,
      onSuccess: () => {
        showMessage('success', 'Клиент успешно обновлен.')
        handleOpenChange(false)
        queryClient.invalidateQueries(['getListClients'])
        refetchList()
      },
      onError: () => {
        showMessage('error', 'Ошибка при обновлении клиента.')
      },
    })

  const { mutate: mutateCreateComment, isLoading: isLoadingCreateComment } =
    useMutation({
      mutationFn: createComment,
      onSuccess: () => {
        showMessage('success', 'Комментарий успешно добавлен.')
        queryClient.invalidateQueries(['getListClients'])
        refetchList()
        setValue('')
        setIsOpenCreateCommentModal(false)
      },
      onError: () => {
        showMessage('error', 'Ошибка при добавлении комментария.')
      },
    })

  return (
    <Flex className="w-full items-center grid grid-cols-[0.5fr_1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
      <Flex>
        <Checkbox
          checked={selectedRowIds?.includes(client?.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRowIds([...selectedRowIds, client?.id])
            } else {
              setSelectedRowIds(
                selectedRowIds?.filter((id: any) => id !== client?.id),
              )
            }
          }}
        />
      </Flex>

      <Typography.Text
        onClick={() => {
          setIsOpenHistoryModal(true)
        }}
        className="text-[#635BFF] font-normal text-base dark:text-[#fff] cursor-pointer"
      >
        #{client?.id}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#fff]">
        {client?.full_name}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#fff]">
        {client?.phone_number}
      </Typography.Text>

      <Typography.Text
        style={{
          color: statusGenerator?.color,
          backgroundColor: statusGenerator?.bg,
        }}
        className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[5px]"
      >
        <statusGenerator.icon />
        {statusGenerator?.text}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#fff]">
        {format(new Date(client?.created_at), 'dd.MM.yyyy HH:mm')}
      </Typography.Text>

      <Flex>
        <BlurImage
          src={client?.image}
          preview={false}
          width={68}
          height={68}
          onClick={() => {
            setIsModalOpen(true)
          }}
        />
      </Flex>

      <Popover
        content={
          <Flex vertical className="min-w-[170px]">
            {client?.status !== 2 && (
              <Button
                type="link"
                loading={isLoadingUpdate1}
                onClick={() => {
                  mutateUpdateClient1({
                    ...client,
                    id: client?.id,
                    status: 2,
                  })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Обработано
              </Button>
            )}
            {client?.status !== 4 && (
              <Button
                type="link"
                loading={isLoadingUpdate2}
                onClick={() => {
                  mutateUpdateClient2({
                    ...client,
                    id: client?.id,
                    status: 4,
                  })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Архивировать
              </Button>
            )}
          </Flex>
        }
        placement="bottomRight"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center dark:border-[#272B30] dark:!bg-[#272B30]">
          <MoreOutlined />
        </Button>
      </Popover>

      <Modal
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false)
        }}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        footer={null}
        width={800}
      >
        <Flex className="w-full">
          <BlurImage src={client?.image} preview={false} width="100%" />
        </Flex>
      </Modal>

      <Modal
        open={isOpenHistoryModal}
        onOk={() => {
          setIsOpenHistoryModal(false)
        }}
        onCancel={() => {
          setIsOpenHistoryModal(false)
        }}
        footer={null}
        centered
        closeIcon={null}
        className="custom-modal-rc"
        width="750px"
      >
        <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]">
          <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0 dark:!text-[#FFF]">
            История заявки
          </Typography.Title>

          <Button
            type="link"
            onClick={() => {
              setIsOpenHistoryModal(false)
            }}
          >
            <CloseOutlined className="text-[#1E1E1E] dark:text-[#FFF]" />
          </Button>
        </Flex>

        <Flex
          vertical
          className="p-[22px_24px] z-10 bg-[#FFF] h-auto w-full sticky top-0 border-b border-x-0 border-t-0 border-solid border-[#EEEEEE] dark:bg-[#1A1D1F] dark:border-[#272B30]"
        >
          <Flex gap={24} className="items-center">
            <Flex className="w-[70px] h-[70px] overflow-hidden items-center justify-center">
              <BlurImage
                src={client?.image}
                preview={false}
                className="w-full h-full object-cover"
              />
            </Flex>

            {/* <Flex vertical gap={12}>
              <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#FFF]">
                1 комнатная (2)
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-normal text-xs dark:text-[#FFF]">
                67,19 м2
              </Typography.Text>
            </Flex> */}
          </Flex>

          <Divider />

          <Flex gap={90}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Имя
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.full_name}
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Телефон номер
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.phone_number}
              </Typography.Text>
            </Flex>
          </Flex>

          <Divider />

          <Flex vertical>
            <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF] mb-[24px]">
              История
            </Typography.Text>

            <Flex
              vertical
              gap={24}
              className="max-h-[350px] overflow-auto mb-[24px]"
            >
              {client?.comments?.length > 0 ? (
                client?.comments?.map((comment: any) => (
                  <Flex
                    key={comment?.id}
                    vertical
                    className="bg-[#F1F2F4] p-[16px] w-full rounded-[16px]"
                  >
                    <Typography.Text className="text-[#878787] font-normal text-sm dark:text-[#FFF]">
                      {comment?.description}
                    </Typography.Text>

                    <Flex className="items-center justify-end mt-[8px]">
                      <Typography.Text className="text-[#878787] font-normal text-sm dark:text-[#FFF]">
                        {format(
                          new Date(comment?.created_at),
                          'dd.MM.yyyy HH:mm',
                        )}
                      </Typography.Text>
                    </Flex>
                  </Flex>
                ))
              ) : (
                <Typography.Text className="text-[#878787] font-normal text-sm dark:text-[#FFF]">
                  Нет комментариев
                </Typography.Text>
              )}
            </Flex>

            <Button
              onClick={() => {
                setIsOpenCreateCommentModal(true)
                setIsOpenHistoryModal(false)
              }}
              className="text-[#635BFF] font-medium text-base flex items-center justify-center w-full p-[16px] border border-solid border-[#E0DEFF] rounded-[14px] h-auto"
            >
              <PlusAddIcon />
              Добавить новый комментарий
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={isOpenCreateCommentModal}
        onOk={() => {
          setIsOpenCreateCommentModal(false)
        }}
        onCancel={() => {
          setIsOpenCreateCommentModal(false)
        }}
        footer={null}
        centered
        closeIcon={null}
        className="custom-modal-rc"
        width="750px"
      >
        <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]">
          <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0 dark:!text-[#FFF]">
            Обработка заявки
          </Typography.Title>

          <Button
            type="link"
            onClick={() => {
              setIsOpenCreateCommentModal(false)
            }}
          >
            <CloseOutlined className="text-[#1E1E1E] dark:text-[#FFF]" />
          </Button>
        </Flex>

        <Flex
          vertical
          className="p-[22px_24px] z-10 bg-[#FFF] h-auto w-full sticky top-0 border-b border-x-0 border-t-0 border-solid border-[#EEEEEE] dark:bg-[#1A1D1F] dark:border-[#272B30]"
        >
          <Flex gap={90}>
            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Имя
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.full_name}
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Телефон номер
              </Typography.Text>
              <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF]">
                {client?.phone_number}
              </Typography.Text>
            </Flex>

            <Flex vertical gap={12}>
              <Typography.Text className="text-[#A9A9A9] font-normal text-base dark:text-[#FFF]">
                Планировка квартиры
              </Typography.Text>
              <Flex className="w-[70px] h-[70px] overflow-hidden items-center justify-center">
                <BlurImage
                  src={client?.image}
                  preview={false}
                  className="w-full h-full object-cover"
                />
              </Flex>
            </Flex>
          </Flex>

          <Divider />

          <Flex vertical>
            <Typography.Text className="text-[#1E1E1E] font-medium text-xl dark:text-[#FFF] mb-[24px]">
              Комментарии
            </Typography.Text>

            <Flex
              vertical
              gap={24}
              className="max-h-[350px] overflow-auto mb-[24px]"
            >
              <TextArea
                value={value}
                onChange={(e) => {
                  setValue(e.target.value)
                }}
                placeholder="Напишите комментарии..."
                className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#1E1E1E] text-sm font-normal dark:bg-[#272B30] dark:border-[#272B30] hover:dark:bg-[#272B30] focus:dark:bg-[#272B30]"
              />
            </Flex>
          </Flex>

          <Flex gap={24} className="justify-end">
            <Button
              onClick={() => {
                setIsOpenCreateCommentModal(false)
                setValue('')
              }}
              className="p-[16px_22px] border border-solid border-[#E5E7EB] text-base font-medium rounded-[14px] h-auto"
            >
              Отменить
            </Button>
            <Button
              onClick={() => {
                mutateCreateComment({
                  request_call: client?.id,
                  description: value,
                })
              }}
              className="p-[16px_22px] border border-solid bg-[#635BFF] border-[#635BFF] text-[#FFF] text-base font-medium rounded-[14px] h-auto"
            >
              Сохранить
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default TableRow

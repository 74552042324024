import request from "@/utils/axios";

export async function getListBanks(): Promise<any> {
    const res: any = await request({
        url: "/bank/banks",
        method: "get"
    });

    return res;
}

export async function getListCredits(params: any): Promise<any> {
    const res: any = await request({
        url: "/bank/credits",
        method: "get",
        params
    });

    return res;
}

export async function createCredit(data: any): Promise<any> {
    const res: any = await request({
        url: "/bank/credits",
        method: "post",
        data
    });

    return res;
}

export async function deleteCredit(id: number): Promise<any> {
    const res: any = await request({
        url: `/bank/credit/${id}`,
        method: "delete"
    });

    return res;
}

export async function updateCredit(data: any): Promise<any> {
    const res: any = await request({
        url: `/bank/credit/${data?.id}`,
        method: "put",
        data
    });

    return res;
}
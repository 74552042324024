import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_14802_2291)">
      <path
        d="M10.0007 0.833252C10.2345 0.833252 10.437 0.995282 10.4884 1.22337L10.8476 2.81776C11.1098 3.98177 12.0188 4.89078 13.1828 5.15299L14.7772 5.51214C15.0053 5.56352 15.1673 5.76611 15.1673 5.99992C15.1673 6.23373 15.0053 6.43632 14.7772 6.4877L13.1828 6.84685C12.0188 7.10906 11.1098 8.01806 10.8476 9.18208L10.4884 10.7765C10.437 11.0046 10.2345 11.1666 10.0007 11.1666C9.76684 11.1666 9.56425 11.0046 9.51287 10.7765L9.15372 9.18208C8.89151 8.01806 7.98251 7.10906 6.81849 6.84685L5.22411 6.4877C4.99601 6.43632 4.83398 6.23373 4.83398 5.99992C4.83398 5.76611 4.99601 5.56352 5.22411 5.51214L6.81849 5.15299C7.98251 4.89078 8.89151 3.98177 9.15372 2.81776L9.51287 1.22337C9.56425 0.995282 9.76684 0.833252 10.0007 0.833252Z"
        fill="#635BFF"
      />
      <path
        d="M4.66732 7.5C4.90112 7.5 5.10371 7.66203 5.1551 7.89012L5.41163 9.02897C5.5868 9.80659 6.19406 10.4138 6.97168 10.589L8.11053 10.8456C8.33862 10.8969 8.50065 11.0995 8.50065 11.3333C8.50065 11.5671 8.33862 11.7697 8.11053 11.8211L6.97168 12.0776C6.19406 12.2528 5.5868 12.8601 5.41163 13.6377L5.1551 14.7765C5.10371 15.0046 4.90112 15.1667 4.66732 15.1667C4.43351 15.1667 4.23092 15.0046 4.17954 14.7765L3.923 13.6377C3.74783 12.8601 3.14057 12.2528 2.36295 12.0776L1.22411 11.8211C0.996015 11.7697 0.833984 11.5671 0.833984 11.3333C0.833984 11.0995 0.996015 10.8969 1.22411 10.8456L2.36295 10.589C3.14057 10.4138 3.74783 9.80659 3.923 9.02897L4.17954 7.89012C4.23092 7.66203 4.43351 7.5 4.66732 7.5Z"
        fill="#635BFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_14802_2291">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default function StarsIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

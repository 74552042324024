import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11593_26430)">
      <path
        d="M18.3346 9.99984C18.3346 14.6022 14.6037 18.3332 10.0013 18.3332C5.39893 18.3332 1.66797 14.6022 1.66797 9.99984C1.66797 5.39746 5.39893 1.6665 10.0013 1.6665C14.6037 1.6665 18.3346 5.39746 18.3346 9.99984Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M10.0026 10.8333C10.9231 10.8333 11.6693 10.0871 11.6693 9.16667C11.6693 8.24619 10.9231 7.5 10.0026 7.5C9.08213 7.5 8.33594 8.24619 8.33594 9.16667C8.33594 10.0871 9.08213 10.8333 10.0026 10.8333ZM10.0026 10.8333L10.0026 13.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11593_26430">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default function LockKeyIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

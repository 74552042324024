import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11440_19718)">
      <circle
        cx="19.0007"
        cy="19.0002"
        r="6.66667"
        stroke="#A9A9A9"
        strokeWidth="1.5"
      />
      <path
        d="M20.1234 17.7209L20.7541 18.1267L20.1234 17.7209ZM16.918 17.0003C16.918 17.4145 17.2538 17.7503 17.668 17.7503C18.0822 17.7503 18.418 17.4145 18.418 17.0003H16.918ZM18.2513 20.0003C18.2513 20.4145 18.5871 20.7503 19.0013 20.7503C19.4155 20.7503 19.7513 20.4145 19.7513 20.0003H18.2513ZM19.5846 17.0003C19.5846 17.1176 19.5508 17.2246 19.4927 17.315L20.7541 18.1267C20.9633 17.8015 21.0846 17.4139 21.0846 17.0003H19.5846ZM18.418 17.0003C18.418 16.6782 18.6791 16.417 19.0013 16.417V14.917C17.8507 14.917 16.918 15.8497 16.918 17.0003H18.418ZM19.0013 16.417C19.3235 16.417 19.5846 16.6782 19.5846 17.0003H21.0846C21.0846 15.8497 20.1519 14.917 19.0013 14.917V16.417ZM18.2513 19.667V20.0003H19.7513V19.667H18.2513ZM19.4927 17.315C19.4166 17.4333 19.3189 17.5593 19.1956 17.7125C19.0802 17.8559 18.9357 18.0311 18.8065 18.2087C18.5533 18.5567 18.2513 19.0518 18.2513 19.667H19.7513C19.7513 19.5458 19.8111 19.3774 20.0194 19.0913C20.1208 18.9518 20.2319 18.8173 20.3643 18.6528C20.4888 18.4981 20.631 18.318 20.7541 18.1267L19.4927 17.315Z"
        fill="#A9A9A9"
      />
      <path
        d="M18.996 22.3335H19.002"
        stroke="#A9A9A9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11440_19718">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(11 11)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default function QuestionCircleIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-floating-promises */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import { updateRCPage } from '@/features/residentialComplex/api'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Modal, Typography } from 'antd'
import { useForm } from 'react-hook-form'

function TitleModal({
  setTitleModal,
  titleModal,
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      title: data?.name,
    },
  })

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: () =>
      updateRCPage(
        {
          name: getValues('title'),
        },
        data?.id,
      ),
    onSuccess: () => {
      setTitleModal(false)
      refetch()
      showMessage('success', 'Название успешно изменено')
    },
  })

  const updateTitle = (): void => {
    mutateUpdate()
  }

  return (
    <Modal
      open={titleModal}
      onOk={() => setTitleModal(false)}
      onCancel={() => setTitleModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc"
    >
      <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Название жилого комплекса
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setTitleModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex className="p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid">
        <FRow label="Название">
          <HFInput name="title" control={control} required />
        </FRow>
      </Flex>

      <Flex className="p-[16px] items-center justify-end" gap={16}>
        <Button
          onClick={() => {
            setTitleModal(false)
          }}
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Отменить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(updateTitle)()
          }}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>
    </Modal>
  )
}

export default TitleModal

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HFInput from '@/components/FormElements/HFInput'
import FRow from '@/components/FormElements/HFRow'
import HFTextarea from '@/components/FormElements/HFTextarea'
import DeleteModal from '@/components/delete-modal'
import MinusIcon from '@/components/icons/minus-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import { Button, Divider, Flex, Image, Typography } from 'antd'
import { useState } from 'react'
import GalleryImage from '../../assets/gallery.svg'

function AdvantagesRow({
  index,
  control,
  watch,
  setValue,
}: {
  index: number
  control: any
  watch: any
  setValue: any
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)
  const [deleteApproveModal, setDeleteApproveModal] = useState(false)
  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const inputClickAdvantagesPhotoContainer = (indexNumber: number): void => {
    ;(
      document.querySelector(
        `input[id="advantages${index}"]`,
      ) as HTMLInputElement
    )?.click()

    document
      .querySelector(`input[id="advantages${index}"]`)
      ?.addEventListener('change', (e) => {
        const target = e.target as HTMLInputElement
        const file = target.files?.[0]

        if (file) {
          setValue(`advantages.${indexNumber}.image`, file)
        }
      })
  }

  const imageUrlGenerator = (image: any): any => {
    if (isValidURL(image)) {
      return image
    }
    if (image instanceof File) {
      return URL.createObjectURL(image)
    }
    return ''
  }

  return (
    <Flex
      vertical
      className="border-[1px] border-solid border-[#E5E7EB] mt-[24px] p-[24px] rounded-[8px] w-full"
    >
      <input hidden id={`advantages${index}`} type="file" accept="image/*" />
      <Flex className="w-full justify-between items-center">
        <Typography.Title className="!text-[#1E1E1E] !text-2xl !font-medium !m-0 dark:!text-[#FFF]">
          Преимущество {index + 1}
        </Typography.Title>

        <Button
          type="link"
          className="w-[24px] h-[24px]"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {isOpen ? (
            <MinusIcon />
          ) : (
            <PlusAddIcon
              style={{
                color: '#1E1E1E',
              }}
            />
          )}
        </Button>
      </Flex>

      {isOpen && (
        <>
          <Divider className="mt-[24px] mb-[24px]" />

          <Flex className="grid grid-cols-2 mb-[24px]" gap={24}>
            <Flex vertical gap={10}>
              <Flex className="w-full items-center justify-between">
                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  Загаловок
                </Typography.Text>

                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  RU
                </Typography.Text>
              </Flex>

              <HFInput
                control={control}
                required
                name={`advantages.${index}.name_ru`}
                placeholder="Например: Архитектура"
              />
            </Flex>

            <Flex vertical gap={10}>
              <Flex className="w-full items-center justify-between">
                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  Загаловок
                </Typography.Text>

                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  UZ
                </Typography.Text>
              </Flex>

              <HFInput
                control={control}
                required
                name={`advantages.${index}.name_uz`}
                placeholder="Например: Архитектура"
              />
            </Flex>

            <Flex vertical gap={10}>
              <Flex className="w-full items-center justify-between">
                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  О преимуществах
                </Typography.Text>

                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  RU
                </Typography.Text>
              </Flex>

              <HFTextarea
                control={control}
                required
                name={`advantages.${index}.description_ru`}
                placeholder="Например: Архитектура"
                rows={6}
                maxLength={500}
              />
            </Flex>

            <Flex vertical gap={10}>
              <Flex className="w-full items-center justify-between">
                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  О преимуществах
                </Typography.Text>

                <Typography.Text className="!text-[#1E1E1E] !font-medium !text-sm dark:!text-[#FFF]">
                  UZ
                </Typography.Text>
              </Flex>

              <HFTextarea
                control={control}
                required
                name={`advantages.${index}.description_uz`}
                placeholder="Например: Архитектура"
                rows={6}
                maxLength={500}
              />
            </Flex>
          </Flex>

          <FRow label="Добавить фотографи ЖК">
            <Flex className="border-[#E5E7EB] border-1 border-dashed rounded-[12px] overflow-hidden dark:border-[#2F3336]">
              {watch(`advantages.${index}.image`) ? (
                <Flex className="relative items-center justify-center min-h-[200px] h-[200px] w-full">
                  <Image
                    width="100%"
                    height="100%"
                    className="object-cover"
                    src={imageUrlGenerator(watch(`advantages.${index}.image`))}
                    preview
                  />

                  <Flex
                    className="absolute right-1 top-1 items-center"
                    gap={12}
                  >
                    <Button
                      onClick={() => {
                        inputClickAdvantagesPhotoContainer(index)
                      }}
                      className="!bg-[#635BFF] !text-white !font-medium rounded-[8px] !text-base"
                    >
                      Изменить
                    </Button>
                    <Button
                      onClick={() => {
                        setDeleteApproveModal(true)
                      }}
                      className="!bg-[#FF4D4F] !text-white !font-medium rounded-[8px] !text-base"
                    >
                      Удалить
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  vertical
                  onClick={() => {
                    inputClickAdvantagesPhotoContainer(index)
                  }}
                  className="items-center justify-center min-h-[200px] w-full"
                >
                  <Image width={70} src={GalleryImage} preview={false} />
                  <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px] dark:!text-[#FFF]">
                    Выберите или перетащите минимум 1 фото
                  </Typography.Text>
                  <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                    Максимальный размер: 5 МБ.
                  </Typography.Text>
                </Flex>
              )}
            </Flex>
          </FRow>
        </>
      )}

      <DeleteModal
        deleteApproveModal={deleteApproveModal}
        setDeleteApproveModal={setDeleteApproveModal}
        onOk={() => {
          setValue(`advantages.${index}.image`, null)
          setDeleteApproveModal(false)
        }}
      />
    </Flex>
  )
}

export default AdvantagesRow

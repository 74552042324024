/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */
import CloseIcon from '@/components/icons/close'
import useMessage from '@/hooks/useShowMessage'
import { CloseCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Image, Modal, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { createRCPage } from '../api'
import BackspaceIcon from '../assets/backspace.svg'
import AdvantagesSection from './RCPageForm/AdvantagesSection'
import CharacteristicsSection from './RCPageForm/CharacteristicsSection'
import ConvenienceSection from './RCPageForm/ConvenienceSection'
import GallerySection from './RCPageForm/GallerySection'
import LocationSection from './RCPageForm/LocationSection'
import MainInformationSection from './RCPageForm/MainInformationSection'

function RCPageForm({ data }: any): JSX.Element {
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    unregister,
    handleSubmit,
  } = useForm({
    defaultValues: {
      nearby_metros: [
        {
          type: 1,
          time: 5,
        },
      ],
    },
  })
  const showMessage = useMessage()
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (data?.id) {
      reset(data)
    }
  }, [data])

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url)
    const blob = await response.blob()

    const urlParts = url.split('/')
    const filename = urlParts[urlParts.length - 1]

    const mimeType =
      response.headers.get('content-type') ?? 'application/octet-stream'

    const file = new File([blob], filename, { type: mimeType })
    return file
  }

  const { mutate: mutateCreateBuilding, isLoading: isLoadingCreating } =
    useMutation({
      mutationFn: createRCPage,
      onSuccess: () => {
        showMessage('success', 'Успешно создано!')
      },
      onError: () => {
        showMessage(
          'error',
          'Ошибка при создании. Пожалуйста, проверьте введенные данные.',
        )
      },
    })

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const handleSave = async () => {
    let imageFile = watch('image')
    if (isValidURL(watch('image'))) {
      imageFile = await urlToFile(watch('image'))
    }

    mutateCreateBuilding({
      ...getValues(),
      image: imageFile,
    })
  }

  const onError = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    showMessage(
      'error',
      'Ошибка при создании. Пожалуйста, проверьте введенные данные.',
    )
  }

  return (
    <Flex vertical gap={24} className="pb-[30px]">
      <MainInformationSection
        control={control}
        watch={watch}
        setValue={setValue}
      />

      <LocationSection control={control} setValue={setValue} watch={watch} />

      <CharacteristicsSection control={control} />

      <ConvenienceSection setValue={setValue} watch={watch} />

      <GallerySection watch={watch} setValue={setValue} />

      <AdvantagesSection control={control} watch={watch} setValue={setValue} />

      <Flex className="items-center justify-end" gap={14}>
        <Button
          onClick={() => {
            setIsModalOpen(true)
          }}
          className="h-auto p-[14px_54px] border-[#E5E7EB] text-[#878787] font-medium text-base dark:!bg-[#2F3336] dark:border-[#2F3336]"
        >
          <CloseIcon />
          Очистить
        </Button>

        <Button
          onClick={() => {
            handleSubmit(handleSave, onError)()
          }}
          loading={isLoadingCreating}
          className="h-auto p-[14px_54px] bg-[#635BFF] hover:!bg-[#635BFF] text-[#FFFFFF] hover:!text-[#FFFFFF] font-medium text-base"
        >
          Сохранить
        </Button>
      </Flex>

      <Modal
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        closeIcon={null}
        footer={null}
        className="search-modal"
      >
        <Flex
          vertical
          gap={24}
          className="rounded-[12px] overflow-hidden border-[#EEEEEE] border-[1.5px] border-solid p-[24px]"
        >
          <Flex vertical className="items-center relative">
            <Image src={BackspaceIcon} preview={false} />

            <Typography.Title className="!text-2xl !font-medium m-0 ml-[16px] text-[#1E1E1E] text-center">
              Вы уверены, что хотите очистить все данные?
            </Typography.Title>

            <Button
              type="link"
              onClick={() => {
                setIsModalOpen(false)
              }}
              className="absolute right-1 top-1 w-[24px] h-[24px] p-0"
            >
              <CloseCircleOutlined
                style={{
                  color: '#1E1E1E',
                  fontSize: 24,
                }}
              />
            </Button>
          </Flex>

          <Flex className="w-full items-center justify-center" gap={24}>
            <Button
              onClick={() => {
                setIsModalOpen(false)
              }}
              className="w-[130px] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#A9A9A9] font-medium text-base"
            >
              Отменить
            </Button>

            <Button
              onClick={() => {
                reset()
                unregister()
                setIsModalOpen(false)
              }}
              className="w-[130px] bg-[#635BFF] rounded-[14px] border-[1.5px] border-solid border-[#E5E7EB] p-[16px_22px] h-auto text-[#FFFFFF] font-medium text-base"
            >
              Очистить
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  )
}

export default RCPageForm

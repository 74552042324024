/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/consistent-type-imports */
import QuestionCircleIcon from '@/components/icons/question-icon'
import { CloseOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Flex, Modal, Typography } from 'antd'
import { useEffect, useRef, useState } from 'react'
import YouTube, { YouTubeProps } from 'react-youtube'
import { getListInstructions } from '../api'
import InstructionButtonOne from './InstructionButtonOne'

function InstructionsModal(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState('analytics')
  const playerRef = useRef<any>(null)

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    playerRef.current = event.target
    event.target.pauseVideo()
  }

  const handleCloseModal = (): void => {
    if (playerRef.current) {
      playerRef.current.stopVideo()
    }
    setIsModalOpen(false)
  }

  const { data } = useQuery({
    queryKey: ['instructions'],
    queryFn: () => getListInstructions(),
  })

  useEffect(() => {
    setActiveTab(data?.[0]?.id)
  }, [data])

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '700',
    playerVars: {
      autoplay: 0,
    },
  }

  return (
    <>
      <Button
        type="link"
        className="p-0 w-[38px] h-[38px]"
        onClick={() => {
          setIsModalOpen(true)
        }}
      >
        <QuestionCircleIcon />
      </Button>

      <Modal
        open={isModalOpen}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        footer={null}
        centered
        closeIcon={null}
        className="custom-modal-rc"
        width="750px"
      >
        <Flex className="items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px] dark:bg-[#1A1D1F] dark:border-[#272B30]">
          <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0 dark:!text-[#FFF]">
            Руководство
          </Typography.Title>

          <Button type="link" onClick={handleCloseModal}>
            <CloseOutlined className="text-[#1E1E1E] dark:text-[#FFF]" />
          </Button>
        </Flex>

        <Flex className="p-[22px_24px] z-10 bg-[#FFF] h-auto w-full items-center justify-between sticky top-0 border-b border-x-0 border-t-0 border-solid border-[#EEEEEE] dark:bg-[#1A1D1F] dark:border-[#272B30]">
          <Flex gap={10} className="items-center">
            {data?.map((element: any) => (
              <InstructionButtonOne
                key={element.id}
                element={element}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            ))}
          </Flex>
        </Flex>

        {data
          ?.find((element: any) => element.id === activeTab)
          ?.video_infos?.map((item: any) => (
            <Flex
              key={item?.id}
              vertical
              gap={24}
              className="p-[24px] dark:bg-[#1A1D1F]"
            >
              <YouTube
                videoId={item?.video}
                opts={opts}
                onReady={onPlayerReady}
              />
              <Typography.Text className="text-[#1E1E1E] font-normal text-base dark:text-[#FFF]">
                {item?.description}
              </Typography.Text>
            </Flex>
          ))}
      </Modal>
    </>
  )
}

export default InstructionsModal

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { Checkbox, Flex, Pagination, Skeleton, Typography } from 'antd'
import FlatsTableRow from './FlatsTableRow'
import FlatsEmptyPage from './FlatsEmptyPage'

function FlatsTablePage({
  data,
  setPage,
  page,
  selectedRowIds,
  setSelectedRowIds,
  watch,
  isLoading,
  setAddDrawerOpen,
  setFilterDrawerOpen,
  refetch,
}: any): JSX.Element {
  return isLoading === true ? (
    <Skeleton active />
  ) : data?.results?.length > 0 ? (
    <Flex vertical>
      <Flex vertical className="h-full items-center justify-center" gap={24}>
        <Flex vertical className="w-full mt-[16px]">
          <Flex
            gap={10}
            className="w-full grid grid-cols-[0.5fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]"
          >
            <Flex>
              <Checkbox
                checked={selectedRowIds.length === data?.results?.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRowIds(
                      data?.results?.map((item: any) => item.id),
                    )
                  } else {
                    setSelectedRowIds([])
                  }
                }}
              />
            </Flex>

            {watch('filters.type') === '1' && (
              <Typography.Text className="text-[#A9A9A9] font-normal text-base">
                Тип квартиры
              </Typography.Text>
            )}

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Планировка
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Общая площадь
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              {watch('filters.type') === '1'
                ? 'Площадь кухни'
                : 'Полезная площадь'}
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Цена за м2
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Номер дома
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Доступно
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Теги
            </Typography.Text>

            <div />
          </Flex>

          {data?.results?.map((flat: any) => (
            <FlatsTableRow
              flat={flat}
              key={flat?.id}
              setSelectedRowIds={setSelectedRowIds}
              selectedRowIds={selectedRowIds}
              refetch={refetch}
              watch={watch}
            />
          ))}

          <Flex className="mt-[24px]">
            <Pagination
              defaultCurrent={page}
              pageSize={20}
              total={data?.count}
              onChange={(pageEvent) => {
                setPage(pageEvent)
              }}
              showSizeChanger={false}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <FlatsEmptyPage
      setAddDrawerOpen={setAddDrawerOpen}
      setFilterDrawerOpen={setFilterDrawerOpen}
    />
  )
}

export default FlatsTablePage

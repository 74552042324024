import BlurImage from '@/components/BlurImage'
import EditIcon from '@/components/icons/edit-icon'
import { Button, Flex, Typography } from 'antd'
import { useState } from 'react'
import GalleryModal from './AllModalsRCPage/GalleryModal'

export default function GallerySection({
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const [galleryModal, setGalleryModal] = useState(false)
  return (
    <>
      <Flex
        gap={24}
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#272B30] dark:border-[#272B30]"
      >
        <Flex gap={8} className="items-center">
          <Typography.Text className="text-[#1E1E1E] font-medium text-[24px] dark:text-[#FFF]">
            Галерея
          </Typography.Text>
          <Button
            onClick={() => {
              setGalleryModal(true)
            }}
            className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
          >
            <EditIcon />
          </Button>
        </Flex>

        <Flex className="rounded-[16px] overflow-hidden">
          <Flex
            className="grid grid-cols-[1fr_0.8fr] h-[400px] relative"
            gap={10}
          >
            <BlurImage
              src={data?.images?.[0]?.image}
              height="100%"
              width="100%"
              className="object-cover"
            />
            <Flex className="grid grid-cols-2" gap={10}>
              {data?.images?.slice(1, 3).map((image: any) => (
                <BlurImage
                  key={image.id}
                  src={image.image}
                  height="100%"
                  width="100%"
                  className="object-cover"
                />
              ))}
            </Flex>

            <Flex
              className="absolute w-full h-full p-[16px] items-end"
              gap={16}
            >
              <Button className="p-[17px_16px] bg-[#1E1E1E33] hover:!bg-[#1E1E1E33] text-[#FFF] hover:!text-[#FFF] font-medium text-base h-max rounded-[100px]">
                Планировка
              </Button>
              <Button className="p-[17px_16px] bg-[#1E1E1E33] hover:!bg-[#1E1E1E33] text-[#FFF] hover:!text-[#FFF] font-medium text-base h-max rounded-[100px]">
                {data?.images?.length} фото
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <GalleryModal
        setGalleryModal={setGalleryModal}
        galleryModal={galleryModal}
        refetch={refetch}
        data={data}
        showMessage={showMessage}
      />
    </>
  )
}

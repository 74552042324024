import BlurImage from '@/components/BlurImage'
import DeleteModal from '@/components/delete-modal'
import CloseIcon from '@/components/icons/close'
import { Button, Flex } from 'antd'
import { useState } from 'react'

export default function GalleryImageItem({fileObj, watch, setValue, index}: any): JSX.Element {
  const [deleteApproveModal, setDeleteApproveModal] = useState(false)

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const imageUrlGenerator = (image: any): any => {
    if (isValidURL(image)) {
      return image
    }
    if (image instanceof File) {
      return URL.createObjectURL(image)
    }
    return ''
  }

  return (
    <>
      <Flex
        className="relative rounded-[8px] overflow-hidden w-[250px] min-w-[250px] h-[250px]"
      >
        <BlurImage
          width="100%"
          height="100%"
          src={imageUrlGenerator(fileObj.image)}
          preview
          className="object-cover"
        />
        <Button
          onClick={(e) => {
            e.stopPropagation()
            setDeleteApproveModal(true)
          }}
          className="border-none p-[12px] absolute bg-[#E0DEFF] right-1 top-1 text-[#1E1E1E] rounded-[12px] text-base font-normal h-auto shadow-none text-start hover:!bg-[#F1F2F4] dark:bg-[#272B30] hover:dark:!bg-[#272B30] dark:text-[#FFF] dark:hover:!text-[#FFF]"
        >
          <CloseIcon />
        </Button>
      </Flex>

      <DeleteModal
        deleteApproveModal={deleteApproveModal}
        setDeleteApproveModal={setDeleteApproveModal}
        onOk={() => {
          const newImages = watch('images')?.filter(
            (img: any, i: number) => i !== index,
          )
          setValue('images', newImages)
          setDeleteApproveModal(false)
        }}
      />
    </>
  )
}

import PlusAddIcon from '@/components/icons/plus-icon'
import { Button, Flex, Image, Typography } from 'antd'
import EmptyIllustrator from '../assets/empty.svg'

function FlatsEmptyPage({ setAddDrawerOpen }: any): JSX.Element {
  return (
    <Flex vertical className="h-full items-center justify-center" gap={24}>
      <Image width={120} src={EmptyIllustrator} preview={false} />

      <Flex vertical className="items-center justify-center">
        <Typography.Title className="!font-medium mb-[16px] m-0 !text-[32px] w-[400px] text-center">
          На данный момент нет помещений
        </Typography.Title>
        <Typography.Text className="text-[#878787] !font-normal !text-base">
          Добавьте новые помещение в свой объект
        </Typography.Text>
      </Flex>

      {setAddDrawerOpen !== null && (
        <Button
          onClick={() => {
            setAddDrawerOpen(true)
          }}
          className="border-[#635BFF] border border-solid rounded-[8px] p-[14px_16px] flex gap-[10px] items-center text-[#635BFF] h-auto !font-medium !text-base"
        >
          <PlusAddIcon
            style={{
              color: '#635BFF',
            }}
          />
          Добавить помещение
        </Button>
      )}
    </Flex>
  )
}

export default FlatsEmptyPage

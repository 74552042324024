import EditIcon from '@/components/icons/edit-icon'
import { Button, Flex, Image, Typography } from 'antd'
import { useState } from 'react'
import AdvantageModal from './AllModalsRCPage/AdvantageModal'

export default function AdvantagesSection({
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const [advantageModal, setAdvantageModal] = useState(false)
  return (
    <>
      <Flex
        gap={24}
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#272B30] dark:border-[#272B30]"
      >
        <Flex gap={8} className="items-center">
          <Typography.Text className="text-[#1E1E1E] font-medium text-[24px] dark:text-[#FFF]">
            Преимущества ЖК
          </Typography.Text>
          <Button
            onClick={() => {
              setAdvantageModal(true)
            }}
            className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
          >
            <EditIcon />
          </Button>
        </Flex>

        <Flex className="w-full">
          <Flex className="grid grid-cols-4" gap={16}>
            {data?.advantages?.map((advantage: any) => (
              <Flex
                key={advantage?.id}
                className="rounded-[13px] overflow-hidden relative"
              >
                <Image src={advantage?.image} />

                <Flex className="absolute left-4 bottom-4">
                  <Typography.Text className="text-[#FFF] font-medium text-base">
                    {advantage?.name}
                  </Typography.Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <AdvantageModal
        setAdvantageModal={setAdvantageModal}
        advantageModal={advantageModal}
        refetch={refetch}
        data={data}
        showMessage={showMessage}
      />
    </>
  )
}

import request from "@/utils/axios";

export default async function getListReviews(params: any): Promise<any> {
    const res: any = await request({
        url: "/review/reviews",
        method: "get",
        params,
    });

    return res;
}
export async function changeStatusReview(id: string, status: string): Promise<any> {
    const res: any = await request({
        url: `/review/reviews/${id}`,
        method: "patch",
        data: {
            status,
        },
    });

    return res;
}
// ROUTE IMPORTS
import Account from '@/features/account/view'
import Analytics from '@/features/analytics/views'
import Login from '@/features/auth/views/login'
import Banks from '@/features/banks/views'
import Clients from '@/features/clients/views'
import Contacts from '@/features/contacts/views'
import DetailPage from '@/features/residentialComplex/views/Detail'
import FlatsPage from '@/features/residentialComplex/views/Flats'
import RCPage from '@/features/residentialComplex/views/RCPage'
import Reviews from '@/features/reviews/views'
import type { CustomRoute } from '@/types'
import Error from '@/views/error'
import NotFound from '@/views/not-found'
import Root from '@/views/root'
import { Navigate } from 'react-router-dom'
import AuthRoute from './AuthRoute'

const routes: CustomRoute[] = [
  {
    id: 'global-not-found',
    title: 'Not found',
    path: '*',
    element: <NotFound />,
  },
  {
    id: 'login',
    title: 'Login',
    path: 'login',
    element: <Login />,
  },
  {
    id: 'root',
    title: 'URBO BUSINESS',
    path: '/',
    element: <Root getRoutes={() => routes} />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Navigate to="/analytics" />,
      },
      {
        id: 'analytics',
        title: 'Аналитика',
        path: 'analytics',
        element: <AuthRoute element={Analytics} />,
      },
      {
        id: 'residential-complex',
        title: 'Жилой комплекс',
        path: 'residential-complex',
        children: [
          {
            id: 'detail',
            path: 'detail',
            element: <AuthRoute element={DetailPage} />,
          },
          {
            id: 'flats',
            path: 'flats',
            element: <AuthRoute element={FlatsPage} />,
          },
          {
            id: 'residential-complex-page',
            path: 'residential-complex-page',
            element: <AuthRoute element={RCPage} />,
          },
        ],
      },
      {
        id: 'clients',
        title: 'Клиенты',
        path: 'clients',
        element: <AuthRoute element={Clients} />,
      },
      {
        id: 'reviews',
        title: 'Отзывы',
        path: 'reviews',
        element: <AuthRoute element={Reviews} />,
      },
      {
        id: 'banks',
        title: 'Банки',
        path: 'banks',
        element: <AuthRoute element={Banks} />,
      },
      {
        id: 'contacts',
        title: 'Контакты',
        path: 'contacts',
        element: <AuthRoute element={Contacts} />,
      },
      {
        id: 'account',
        title: 'Аккаунт',
        path: 'account',
        element: <AuthRoute element={Account} />,
      },
    ],
  },
]

export default routes

/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import TableEmpty from '@/components/table-empty'
import { useQuery } from '@tanstack/react-query'
import { Button, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import getListReviews from '../api'
import NodataIcon from '../assets/nodata.svg'
import RatingTableRow from './RatingTableRow'

function RatingTable(): JSX.Element {
  const [activeTab, setActiveTab] = useState('active')

  const { data, refetch, isLoading } = useQuery({
    queryKey: ['getListReviews', activeTab],
    queryFn: (): any => getListReviews({ status: activeTab }),
  })

  return (
    <Flex vertical className="w-full" gap={24}>
      <Flex className="w-full border-[2px] border-solid border-x-0 border-t-0 border-[#E5E7EB] dark:border-[#272B30]">
        <Button
          className="text-[#A9A9A9] text-base font-medium rounded-none"
          style={{
            color: activeTab === 'active' ? '#635BFF' : '#A9A9A9',
            borderBottom: activeTab === 'active' ? '2px solid #635BFF' : 'none',
          }}
          type="link"
          onClick={() => {
            setActiveTab('active')
          }}
        >
          Активные
        </Button>
        <Button
          className="text-[#A9A9A9] text-base font-medium rounded-none"
          style={{
            color: activeTab === 'inactive' ? '#635BFF' : '#A9A9A9',
            borderBottom:
              activeTab === 'inactive' ? '2px solid #635BFF' : 'none',
          }}
          type="link"
          onClick={() => {
            setActiveTab('inactive')
          }}
        >
          Архивированные
        </Button>
      </Flex>
      {isLoading ? (
        <Skeleton active />
      ) : data?.length > 0 ? (
        <Flex vertical className="w-full mt-[16px]">
          <Flex className="w-full grid grid-cols-[1.5fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
            <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Имя
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Оценка
            </Typography.Text>

            {/* <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Тема
            </Typography.Text> */}

            {/* <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Плюсы
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Минусы
            </Typography.Text> */}

            <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Комментарий
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Дата публикации
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base !flex items-center">
              Статус
            </Typography.Text>

            <div />
          </Flex>

          {data?.map((item: any, index: number) => (
            <RatingTableRow key={item.id} item={item} refetch={refetch} />
          ))}
        </Flex>
      ) : (
        <TableEmpty
          title="На данный момент у вас нет отзывы"
          description=""
          icon={NodataIcon}
          addButtonEvent={null}
        />
      )}
    </Flex>
  )
}

export default RatingTable

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import formatAmount from '@/helpers/formatAmount'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Button, Flex, Input, Typography } from 'antd'
import { useState } from 'react'

export default function FlatsSettingsPriceModalTableRow({
  item,
  defaultVisible = false,
  mainWatch,
  mainSetValue,
}: any): JSX.Element {
  const [visible, setVisible] = useState(defaultVisible)

  const handleChangeInput = (e: any): any => {
    if (mainWatch('apartments')?.length > 0) {
      mainSetValue('apartments', [
        ...mainWatch('apartments').map((appart: any) =>
          appart.id === item.id ? { ...appart, price: e.target.value } : appart,
        ),
      ])
    } else {
      mainSetValue('apartments', [{ id: item.id, price: e.target.value }])
    }
  }

  return (
    <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
      <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
        {item?.apartment_type}
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#E5E7EB] h-max w-max flex items-center dark:bg-[#272B30] dark:text-[#FFF]">
        {item?.total_space} м2
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
        {formatAmount(item?.price)} uzs
      </Typography.Text>

      <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
        {formatAmount(
          mainWatch('apartments')?.find((appart: any) => appart.id === item.id)
            ?.price || item?.price,
        )}{' '}
        uzs
      </Typography.Text>

      {/* <Flex>
        <Input
          value={
            mainWatch('apartments')?.find(
              (appart: any) => appart.id === item.id,
            )?.price || ''
          }
          onChange={(e) => {
            handleChangeInput(e)
          }}
          type={visible || defaultVisible ? 'text' : 'password'}
          suffix={
            <Button
              type="link"
              className="p-0"
              onClick={() => {
                setVisible(!visible)
              }}
            >
              {visible || defaultVisible ? (
                <EyeInvisibleOutlined />
              ) : (
                <EyeOutlined />
              )}
            </Button>
          }
          className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#1E1E1E] !bg-white text-sm font-normal dark:bg-[#272B30] dark:border-[#272B30] hover:dark:bg-[#272B30] focus:dark:bg-[#272B30]"
        />
      </Flex> */}
    </Flex>
  )
}

/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-return-await */
/* eslint-disable react-hooks/exhaustive-deps */
import BlurImage from '@/components/BlurImage'
import Camera2Icon from '@/components/icons/camera-icon2'
import { updateRCPage } from '@/features/residentialComplex/api'
import { CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Modal, Typography } from 'antd'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GalleryImage from '../../../assets/gallery.svg'
import GalleryImageItem from './GalleryImageItem'

function GalleryModal({
  setGalleryModal,
  galleryModal,
  refetch,
  data,
  showMessage,
}: any): JSX.Element {
  const { setValue, watch, getValues, unregister } = useForm()

  useEffect(() => {
    if (data?.images?.length > 0) {
      setValue('images', data.images)
    }
  }, [data?.images, setValue, galleryModal])

  const isValidURL = (str: string): boolean => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(str)
  }

  const handleFileChange = (e: Event): void => {
    const target = e.target as HTMLInputElement
    const { files } = target

    if (files != null) {
      const filesArray = Array.from(files).map((file) => ({ image: file }))
      setValue('images', [...filesArray, ...(watch('images') ?? [])])
    }
  }

  const inputClick = (): void => {
    const inputElement = document.querySelector(
      'input[id="galleryModal"]',
    ) as HTMLInputElement
    inputElement?.click()
  }

  useEffect(() => {
    const inputElement = document.querySelector(
      'input[id="galleryModal"]',
    ) as HTMLInputElement
    if (inputElement) {
      inputElement.addEventListener('change', handleFileChange)
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('change', handleFileChange)
      }
    }
  }, [handleFileChange])

  async function urlToFile(url: string): Promise<File> {
    const response = await fetch(url)
    const blob = await response.blob()

    const urlParts = url.split('/')
    const filename = urlParts[urlParts.length - 1]

    const mimeType =
      response.headers.get('content-type') ?? 'application/octet-stream'

    const file = new File([blob], filename, { type: mimeType })
    return file
  }

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: async () =>
      await updateRCPage(
        {
          images: getValues('images'),
        },
        data?.id,
      ),
    onSuccess: () => {
      setGalleryModal(false)
      unregister('images')
      showMessage('success', 'Галерея успешно изменена')
      refetch()
    },
    onError: (error: Error) => {
      console.error(error)
    },
  })

  const updateTitle = async (): Promise<any> => {
    let imagesFiles = watch('images')

    imagesFiles = await Promise.all(
      imagesFiles.map(async (image: any) => {
        if (isValidURL(image.image)) {
          return {
            image: await urlToFile(image.image),
          }
        }
        return image
      }),
    )

    setValue('images', imagesFiles)

    mutateUpdate()
  }

  return (
    <Modal
      open={galleryModal}
      onOk={() => setGalleryModal(false)}
      onCancel={() => setGalleryModal(false)}
      footer={null}
      centered
      closeIcon={null}
      className="custom-modal-rc"
      width="max-content"
    >
      <Flex className="flex items-center justify-between border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid p-[16px_24px]">
        <Typography.Title className="text-[#1E1E1E] !text-2xl !font-medium !m-0 p-0">
          Галерея
        </Typography.Title>

        <Button
          type="link"
          onClick={() => {
            setGalleryModal(false)
          }}
        >
          <CloseOutlined className="text-[#1E1E1E]" />
        </Button>
      </Flex>

      <Flex className="flex p-[24px] border-b-1 border-[#E5E7EB] border-x-0 border-t-0 border-solid">
        <input type="file" hidden multiple id="galleryModal" />
        <Flex className="border-[#E5E7EB] border-1 border-dashed rounded-[12px] dark:border-[#2F3336]">
          {watch('images')?.length > 0 ? (
            <Flex
              className="grid grid-cols-4 items-center justify-start min-h-[200px] w-full p-5 flex-wrap"
              gap={16}
            >
              {watch('images')?.map((fileObj: any, index: number) => (
                <GalleryImageItem
                  key={fileObj?.image}
                  fileObj={fileObj}
                  watch={watch}
                  setValue={setValue}
                  index={index}
                />
              ))}
              <Flex
                vertical
                onClick={inputClick}
                className="rounded-[8px] cursor-pointer overflow-hidden w-[250px] min-w-[250px] h-[250px] border-[1.5px] border-[#635BFF] border-dashed items-center justify-center"
                gap={17}
              >
                <Camera2Icon />
                Добавить ещё
              </Flex>
            </Flex>
          ) : (
            <Flex
              onClick={inputClick}
              vertical
              className="flex items-center justify-center min-h-[200px] w-full"
            >
              <BlurImage width={70} src={GalleryImage} preview={false} />
              <Typography.Text className="!text-[#1E1E1E] !font-medium mt-[20px] mb-[4px] !text-base !ml-[16px] dark:!text-[#878787]">
                Выберите или перетащите минимум 3 фото
              </Typography.Text>
              <Typography.Text className="!text-[#878787] !font-normal !text-sm !ml-[16px]">
                Максимальный размер: 5 МБ.
              </Typography.Text>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex className="flex p-[16px] items-center justify-end" gap={16}>
        <Button
          className="border-[#E5E7EB] bg-[#FFFFFF] text-[#878787] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
          onClick={() => {
            setGalleryModal(false)
            unregister('images')
          }}
        >
          Отменить
        </Button>

        <Button
          onClick={updateTitle}
          loading={isLoadingUpdate}
          className="border-[#E5E7EB] bg-[#635BFF] text-[#F1F2F4] text-base font-medium p-[10px_24px] rounded-[8px] h-auto"
        >
          Сохранить
        </Button>
      </Flex>
    </Modal>
  )
}

export default GalleryModal

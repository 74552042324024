import { Button, DatePicker, Flex, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from 'react-apexcharts'

function AnalyticsChart({
  data,
  selectedDate,
  setSelectedDate,
}: any): JSX.Element {
  const [selectedTab, setSelectedTab] = useState('cycles')
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    if (theme === 'dark') {
      document.body.classList.add('dark')
      setIsDarkMode(true)
    }
  }, [])

  const dataGenerator = (type: any): any => {
    const dataArr: any = []
    for (let i = 1; i < 13; i += 1) {
      // eslint-disable-next-line array-callback-return
      data?.[type]?.map((item: any) => {
        if (item.created_at__month === i) {
          dataArr.push(item.count)
        } else {
          dataArr.push(0)
        }
      })
    }
    return dataArr
  }

  const dataSeries = useMemo(() => {
    if (selectedTab === 'cycles') {
      return [
        {
          name: 'Количество кликов',
          data: dataGenerator('phone_chart'),
        },
      ]
    }
    return [
      {
        name: 'Просмотры',
        data: dataGenerator('view_chart'),
      },
    ]
  }, [data, selectedTab])

  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    grid: {
      borderColor: isDarkMode ? '#272B30' : '#E5E7EB',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Янв',
        'Фев',
        'Мар',
        'Апр',
        'Май',
        'Июн',
        'Июл',
        'Авг',
        'Сен',
        'Окт',
        'Ноя',
        'Дек',
      ],
    },
    fill: {
      opacity: 1,
      colors: ['#635BFF'],
    },
  }

  return (
    <Flex
      vertical
      className="bg-[#FFF] rounded-[12px] border border-[#E5E7EB] h-full border-solid dark:bg-[#1A1D1F] dark:border-[#272B30] div5"
    >
      <Flex className="p-[16px_24px] items-center justify-between w-full border-b border-solid border-x-0 border-t-0 border-[#E5E7EB] dark:border-[#272B30]">
        <Typography.Title
          level={5}
          className="m-0 !text-[#1E1E1E] !text-2xl !font-medium !m-0 dark:!text-[#FFFFFF]"
        >
          Аналитика
        </Typography.Title>

        <DatePicker.RangePicker
          placeholder={['От', 'До']}
          onChange={(_date, dateString) => {
            setSelectedDate(dateString)
          }}
          style={{
            border: `1px solid ${isDarkMode ? '#272B30' : '#E5E7EB'}`,
          }}
          className="rounded-[8px] h-[48px] dark:bg-[#272B30] dark:hover:!bg-[#272B30]"
        />
      </Flex>

      <Flex vertical className="p-[24px]">
        <Flex className="p-[8px]" gap={10}>
          <Button
            type="link"
            className={`hover:!bg-[#F1F2F4] flex items-center justify-center p-[8px_12px] rounded-[8px] text-[#1E1E1E] dark:bg-inherit dark:text-[#FFF] dark:hover:!bg-[#272B30] ${
              selectedTab === 'cycles' ? 'bg-[#F1F2F4] dark:!bg-[#272B30]' : ''
            }`}
            onClick={() => {
              setSelectedTab('cycles')
            }}
          >
            Количество кликов
          </Button>

          <Button
            type="link"
            className={`hover:!bg-[#F1F2F4] flex items-center justify-center p-[8px_12px] rounded-[8px] text-[#1E1E1E] dark:bg-inherit dark:text-[#FFF] dark:hover:!bg-[#272B30] ${
              selectedTab === 'views' ? 'bg-[#F1F2F4] dark:!bg-[#272B30]' : ''
            }`}
            onClick={() => {
              setSelectedTab('views')
            }}
          >
            Просмотры
          </Button>
        </Flex>

        <Flex className="w-full">
          <Chart
            options={options}
            series={dataSeries}
            type="bar"
            height={350}
            width="100%"
            style={{ width: '100%' }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AnalyticsChart

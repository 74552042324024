import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66536 6.00016C9.66536 6.92064 8.91917 7.66683 7.9987 7.66683C7.07822 7.66683 6.33203 6.92064 6.33203 6.00016C6.33203 5.07969 7.07822 4.3335 7.9987 4.3335C8.91917 4.3335 9.66536 5.07969 9.66536 6.00016Z"
      stroke="currentColor"
    />
    <path
      d="M8.83697 11.6625C8.61211 11.8791 8.31157 12.0002 7.9988 12.0002C7.68602 12.0002 7.38549 11.8791 7.16063 11.6625C5.10157 9.66735 2.34216 7.43852 3.68784 4.20265C4.41543 2.45305 6.16199 1.3335 7.9988 1.3335C9.83561 1.3335 11.5822 2.45305 12.3098 4.20265C13.6537 7.43444 10.9011 9.67423 8.83697 11.6625Z"
      stroke="currentColor"
    />
    <path
      d="M12 13.3335C12 14.0699 10.2091 14.6668 8 14.6668C5.79086 14.6668 4 14.0699 4 13.3335"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)

export default function LocationIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

import { Button } from 'antd'

function InstructionButtonOne({
  element,
  activeTab,
  setActiveTab,
}: any): JSX.Element {
  return (
    <Button
      onClick={() => {
        setActiveTab(element.id)
      }}
      className={`${
        activeTab === element.id
          ? '!text-[#635BFF] !bg-[#F1F2F4] dark:!bg-[#272B30] dark:!text-[#FFF]'
          : ''
      } p-[9px] rounded-[12px] flex items-center h-auto text-base font-normal shadow-none text-[#1E1E1E] cursor-pointer w-full hover:!bg-[#F1F2F4] hover:text-[#635BFF] border-0 text-left dark:bg-inherit dark:text-[#FFF] dark:hover:!bg-[#272B30]`}
    >
      {element.icon}
      {element.name}
    </Button>
  )
}

export default InstructionButtonOne

import BlurImage from '@/components/BlurImage'
import EditIcon from '@/components/icons/edit-icon'
import { Button, Divider, Flex, Typography } from 'antd'
import { useState } from 'react'
import ConvenienceModal from './AllModalsRCPage/ConvenienceModal'

export default function ConvenienceSection({
  data,
  refetch,
  showMessage,
}: any): JSX.Element {
  const [convenienceModal, setConvenienceModal] = useState(false)

  return (
    <>
      <Flex
        gap={24}
        vertical
        className="p-[24px] border-[#E5E7EB] border border-solid rounded-[16px] bg-[#FFF] h-max dark:bg-[#272B30] dark:border-[#272B30]"
      >
        <Flex gap={8} className="items-center">
          <Typography.Text className="text-[#1E1E1E] font-medium text-[24px] dark:text-[#FFF]">
            Удобства ЖК
          </Typography.Text>
          <Button
            onClick={() => {
              setConvenienceModal(true)
            }}
            className="w-[20px] h-[20px] flex items-center justify-center p-0 rounded-full bg-[#F1F2F4] text-[#878787]"
          >
            <EditIcon />
          </Button>
        </Flex>

        <Divider className="m-0" />

        <Flex vertical>
          <Typography.Text className="text-[#1E1E1E] font-medium text-xl mb-[24px]">
            Особенности интерьера
          </Typography.Text>

          <Flex gap={24} className="flex-wrap">
            {data?.interior_features?.map((item: any) => (
              <Flex
                key={item?.id}
                className="items-center p-[4px] border-[1px] border-[#EEEEEE] border-solid rounded-full pr-[40px]"
                gap={10}
              >
                <Flex className="w-[48px] h-[48px] rounded-full bg-[#F1F2F4] items-center justify-center overflow-hidden">
                  <BlurImage src={item?.icon} preview={false} />
                </Flex>

                <Typography.Text className="text-[#1E1E1E] font-medium text-base">
                  {item?.title}
                </Typography.Text>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Divider className="m-0" />

        <Flex vertical>
          <Typography.Text className="text-[#1E1E1E] font-medium text-xl mb-[24px]">
            Система безопасности
          </Typography.Text>

          <Flex gap={24} className="flex-wrap">
            {data?.safety_systems?.map((item: any) => (
              <Flex
                key={item?.id}
                className="items-center p-[4px] border-[1px] border-[#EEEEEE] border-solid rounded-full pr-[40px]"
                gap={10}
              >
                <Flex className="w-[48px] h-[48px] rounded-full bg-[#F1F2F4] items-center justify-center overflow-hidden">
                  <BlurImage src={item?.icon} preview={false} />
                </Flex>

                <Typography.Text className="text-[#1E1E1E] font-medium text-base">
                  {item?.title}
                </Typography.Text>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Divider className="m-0" />

        <Flex vertical>
          <Typography.Text className="text-[#1E1E1E] font-medium text-xl mb-[24px]">
            Особенности экстерьера
          </Typography.Text>

          <Flex gap={24} className="flex-wrap">
            {data?.exterior_features?.map((item: any) => (
              <Flex
                key={item?.id}
                className="items-center p-[4px] border-[1px] border-[#EEEEEE] border-solid rounded-full pr-[40px]"
                gap={10}
              >
                <Flex className="w-[48px] h-[48px] rounded-full bg-[#F1F2F4] items-center justify-center overflow-hidden">
                  <BlurImage src={item?.icon} preview={false} />
                </Flex>

                <Typography.Text className="text-[#1E1E1E] font-medium text-base">
                  {item?.title}
                </Typography.Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <ConvenienceModal
        setConvenienceModal={setConvenienceModal}
        convenienceModal={convenienceModal}
        refetch={refetch}
        data={data}
        showMessage={showMessage}
      />
    </>
  )
}

/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { Button, Flex, Image, Typography } from 'antd'
import PlusAddIcon from './icons/plus-icon'

interface Props {
  title: string
  description: string
  icon: any
  addButtonEvent: any
}

export default function TableEmpty(props: Props): React.ReactElement {
  const { title, description, icon, addButtonEvent } = props

  return (
    <Flex vertical className="w-full items-center justify-center min-h-[70vh]">
      <Flex className="w-[200px] h-[200px]">
        <Image
          src={icon}
          preview={false}
          width="100%"
          height="100%"
          className="w-full h-full object-contain"
        />
      </Flex>

      <Flex vertical className="items-center justify-center">
        <Typography.Title className="!m-0 !text-[#1E1E1E] !font-bold !text-2xl">
          {title}
        </Typography.Title>
        <Typography.Text className="!mt-[8px] !mb-[24px] !text-[#777E90] !font-normal !text-base">
          {description}
        </Typography.Text>
        {addButtonEvent !== null && (
          <Button
            className="p-[14px_24px] bg-[#635BFF] rounded-[8px] text-[#FFF] h-auto flex items-center justify-center"
            onClick={addButtonEvent}
          >
            <PlusAddIcon
              style={{
                color: '#FFF',
              }}
            />
            Добавить
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

import Icon from '@ant-design/icons/lib/components/Icon'

import type { ReactElement } from 'react'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

const defaultIcon = (): React.ReactElement => (
  <svg
    width=""
    height=""
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8494 12C14.3489 12 14.7462 11.6856 15.103 11.2461C15.8333 10.3463 14.6342 9.62723 14.1769 9.27507C13.712 8.91708 13.1929 8.71428 12.6667 8.66667M12 7.33333C12.9205 7.33333 13.6667 6.58714 13.6667 5.66667C13.6667 4.74619 12.9205 4 12 4"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M2.14999 12C1.65045 12 1.25313 11.6856 0.896379 11.2461C0.166075 10.3463 1.36513 9.62723 1.82245 9.27507C2.28733 8.91708 2.8064 8.71428 3.33268 8.66667M3.66602 7.33333C2.74554 7.33333 1.99935 6.58714 1.99935 5.66667C1.99935 4.74619 2.74554 4 3.66602 4"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
    <path
      d="M5.38855 10.0743C4.70737 10.4955 2.92134 11.3556 4.00915 12.4318C4.54054 12.9575 5.13236 13.3335 5.87643 13.3335H10.1223C10.8663 13.3335 11.4582 12.9575 11.9895 12.4318C13.0774 11.3556 11.2913 10.4955 10.6101 10.0743C9.01278 9.08657 6.98592 9.08657 5.38855 10.0743Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3327 5.00008C10.3327 6.28875 9.28801 7.33341 7.99935 7.33341C6.71068 7.33341 5.66602 6.28875 5.66602 5.00008C5.66602 3.71142 6.71068 2.66675 7.99935 2.66675C9.28801 2.66675 10.3327 3.71142 10.3327 5.00008Z"
      stroke="currentColor"
      strokeWidth="1.25"
    />
  </svg>
)

export default function UserGroupIcon(
  props: Partial<CustomIconComponentProps>,
): ReactElement {
  return <Icon component={defaultIcon} {...props} />
}

import { Input, Typography } from 'antd'
import { Controller } from 'react-hook-form'

function HFNumberInput({
  control,
  name = '',
  disabledHelperText = false,
  required = false,
  rules = {},
  ...props
}: any): JSX.Element {
  // Function to format number with spaces
  const formatNumberWithSpaces = (
    value: string | number | null | undefined,
  ): string => {
    return String(value || '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  // Function to remove spaces from the formatted number
  const removeSpaces = (value: string | null | undefined): string => {
    return String(value || '').replace(/\s/g, '')
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: required ? 'Это объязательная поля!' : false,
        ...rules,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Input
            value={formatNumberWithSpaces(value)}
            onChange={(e) => {
              const { value: inputValue } = e.target
              const cleanValue = removeSpaces(inputValue)
              const reg = /^-?\d*(\.\d*)?$/ // Regex to allow negative numbers and decimals

              if (
                reg.test(cleanValue) ||
                cleanValue === '' ||
                cleanValue === '-'
              ) {
                onChange(cleanValue)
              }
            }}
            status={error ? 'error' : 'success'}
            className="border border-[#E5E7EB] rounded-lg h-[40px] w-full px-[10px] text-[#1E1E1E] text-sm font-normal dark:bg-[#272B30] dark:border-[#272B30] hover:dark:bg-[#272B30] focus:dark:bg-[#272B30]"
            {...props}
          />
          {error && !disabledHelperText && (
            <Typography.Text type="danger">{error.message}</Typography.Text>
          )}
        </>
      )}
    />
  )
}

export default HFNumberInput
